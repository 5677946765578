import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
import "./spacings.css"
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import {ButtonList, ExpandableDiversList, ColoredLine} from "../components/Lists";
import 'react-phone-number-input/style.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDiverContext, useAppContext } from "../lib/contextLib";
import LoadingOverlay from 'react-loading-overlay';
import NavHeader, {MenueHeader} from "../components/Header"
import { dateTimeView} from "../components/Helpers"

export default function DiverEvents() {

  const history = useHistory();
  const {setExpanded} = useAppContext();
  const [currentPage, setPage] = useState(0);
  //const [isLoading, setIsLoading] = useState(false);
  const {diverProfile, shopList} = useDiverContext();
  const [eventsList, setEventsList] = useState([]);
  const [eventForView, setEventForView] = useState("-1");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const today_0 = new Date().setHours(0,0,0);

  const headers_template = [
    {label: "Active & upcoming dives", key: "futureevents", checked: true },
    {label: "Past dives", key: "pastevents", checked: false }
  ]
  const [headers, setHeaders] = useState(headers_template)
  const [menueId, setMenueId] = useState(0);

    useEffect(() => {
        function getEventsForList(eventslist) {
            const this_list = [];
            eventslist.forEach( (item, index) => {
                this_list.push( {...item,
                    list_id: index,
                    key: item.eventId,
                    label: `${item.eventName} at ${item.eventDiveSite}`,
                    sublabel: dateTimeView(item.eventDate, true),
                    divesublabel: shopList.filter(this_item => this_item.centerId === item.centerId)[0]["DCName"],
                    marked: false} )

            }
            )
            return this_list;
        }

        function loadEventsInfo() {
            return API.get("events", "/divers/events");
        }
        async function onLoad() {
            setExpanded(false)
            try {
                const loaded_events = await loadEventsInfo();
                if ("status" in loaded_events) {
                    if (loaded_events["status"] === "notfound") {
                        return {};
                    }
                }
                const tmpEventsList = getEventsForList(loaded_events)
                setEventsList(tmpEventsList);
            } catch (e) {
                onError(e);
            }
            setIsPageLoading(false);
        }
        onLoad();
    }, [shopList, setExpanded]);




  function getTodaysFutureEvents(eventslist){

    return eventslist.filter(item => new Date(item.eventDate).setHours(23,59,59) >= today_0)
    .sort((a, b) => (new Date(a.eventDate) > new Date(b.eventDate)) ? 1 : -1 )
  }

  function getPastEvents(eventslist){
    return eventslist.filter(item => new Date(item.eventDate).setHours(23,59,59) < today_0).sort((a, b) => (new Date(a.eventDate) > new Date(b.eventDate)) ? -1 : 1 )
  }

  async function navigateButton(next_or_previous) {
    var newpage = currentPage;
    if (next_or_previous === "next") {
        newpage += 1;
        setPage(newpage)
    }
    else if (next_or_previous === "previous") {
        if (eventForView === "-1"){
            if (currentPage === 0) {
                history.push("/diver/main")
            } else {
                newpage -= 1;
            }
            setPage(newpage)
        } else {
            setEventForView("-1")
        }
    }

  }



    function handleHeader(id){
        const newList = headers.map((item, index) => {
            if (index === id) {
                const updatedItem = {...item, checked: true, };
                return updatedItem;
            } else {
                const updatedItem = {...item, checked: false, };
                return updatedItem;
            }
        })
        setHeaders(newList)
        setMenueId(id)
    }

    function handleShowEvent(key){

        setEventForView(eventsList.find(item => item.key === key))
    }


    function EventView({eventDict, ...props}) {
        function getDiverList(){
            const diverList = []
            Object.entries(eventDict.eventDivers).forEach(([key, item]) => {
                diverList.push({
                    key: key,
                    label: item.firstName + " " + item.lastName,
                    sublabel: item.exposedBrevetLabel,
                    eventGroup: item.eventGroup
                    }
                )
            })
            return(diverList)
        }

        const thisUser_eventGroup = eventDict.eventDivers[diverProfile.userId]["eventGroup"]

        return (
            <div>
                <Container fluid className="pl-0">
                    <Row className="pl-3 pr-3">
                        <Col className="pl-3 pr-3">
                            <b>{eventDict.eventDiveSite}</b>
                        </Col>
                    </Row>
                    <Row className="pl-3 pr-3">
                        <Col className="pl-3 pr-3">
                            {eventDict.eventName}
                        </Col>
                    </Row>
                    <Row className="pl-3">
                        <Col className="pl-3 pr-3">
                            {dateTimeView(eventDict.eventDate, true)}
                        </Col>
                    </Row>
                </Container>
                <ColoredLine className="scbline"/>
                <ExpandableDiversList
                    mylist={getDiverList().filter(item => (item.eventGroup === thisUser_eventGroup))}
                    active={false}
                />
            </div>
        )
    }


    return (
        <div className="DiverEvents">
            <LoadingOverlay
                active={isPageLoading}
                spinner
                text='Loading Logbook...'
            >
            <NavHeader prev={true}
                next={false}
                label="My Logbook"
                handler={navigateButton}/>


                {eventForView === "-1" ?
                    <>
                        <MenueHeader
                            list={headers}
                            changehandler={handleHeader}
                        />
                        {/*active / future visits*/}
                        { (menueId === 0) ? <>
                            { (getTodaysFutureEvents(eventsList).length === 0) ? <>
                              <p className="text-center">You currently have no events scheduled</p>
                            </>:<>
                              <ButtonList mylist={getTodaysFutureEvents(eventsList)} changehandler={handleShowEvent}/>
                             </>} {/*END if events list === 0*/}
                        </>:<></> } {/* END active / future events*/}


                        {/*past visits*/}
                        { (menueId === 1) ? <>
                            { (getPastEvents(eventsList).length === 0) ? <>
                              <p className="text-center">No past events yet</p>
                            </>:<>
                              <ButtonList mylist={getPastEvents(eventsList)} changehandler={handleShowEvent}/>
                             </>} {/*END if visits list === 0*/}
                            </>:<></> } {/* END past visits*/}
                    </> :
                    <EventView eventDict={eventForView}/>
                }
            </LoadingOverlay>
        </div>
    );
}
