export const dummydivers = [
        {
        "userId": "fpac2100",
        "firstName": "Tobias",
        "lastName": "Blabla",
        "exposedBrevet": 10200,
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "scubaDetails": {
            "mainBrevets": {
            10200: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             },
             10300: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900100: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 },
                 900200: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 250,
                "dateOfLastDive": "2021-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },
        {
        "userId": "fa2dasc2100",
        "firstName": "Markus",
        "lastName": "BleuBleu",
        "exposedBrevet": 20100,
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "scubaDetails": {
            "mainBrevets": {
            20100: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900400: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 },
                 900500: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 2000,
                "dateOfLastDive": "2021-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },
        {
        "userId": "a20dasd922",
        "firstName": "Timo",
        "lastName": "Blaufisch",
        "exposedBrevet": 20200,
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "scubaDetails": {
            "mainBrevets": {
            20200: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900100: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 20,
                "dateOfLastDive": "2018-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },

        {
        "userId": "res31250",
        "firstName": "Hans",
        "lastName": "Wurst",
        "exposedBrevet": 20800,
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "scubaDetails": {
            "mainBrevets": {
            10200: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             },
             10300: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900100: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 },
                 900200: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 250,
                "dateOfLastDive": "2021-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },
        {
        "userId": "dfsa3143214",
        "firstName": "Din",
        "lastName": "Iso",
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "exposedBrevet": 10600,
        "scubaDetails": {
            "mainBrevets": {
            10600: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900400: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 },
                 900500: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 2000,
                "dateOfLastDive": "2021-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },
        {
        "userId": "dag324efds",
        "firstName": "Hirn",
        "lastName": "Prinz",
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "exposedBrevet": 10900,
        "scubaDetails": {
            "mainBrevets": {
            10900: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900100: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 20,
                "dateOfLastDive": "2018-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        },
        {
        "userId": "1234fddxcsd",
        "firstName": "Chuck",
        "lastName": "Norris",
        "exposedBrevet": 30900,
        "personalDetails": {
            "dateOfBirth": "1988-12-12",
            "address": {
                "addressLine": "myblabla",
                "ZIPCode": 98231,
                "city": "dummycity",
                "country": "MyKingdom"
            },
            "email": "dummy@dummy.com",
            "phoneNumber": "01234",
        },
        "scubaDetails": {
            "mainBrevets": {
            30900: {
                "fileID": "some_file_id",
                "dateEntered": "2019-10-01"
             }
            },
            "specialties": {
                900100: {
                    "fileID": "some_file_id",
                    "dateEntered": "2019-10-01"
                 }
            },
            "diveRecord": {
                "numberOfDives": 20,
                "dateOfLastDive": "2018-12-28"
             },
             "insuranceData": {},
             "medicalData": {}
        }
        }
    ]

export const dummystaff = []

export function getDiversCompactData(this_list) {
    const this_divers = [];
    for (let this_entry of this_list) {
        this_divers.push({"firstName": this_entry.firstName, "lastName": this_entry.lastName, "mainBrevet": this_entry.exposedBrevet, "global_id": this_entry.id});
    }
    return(this_divers)
}


export function get_NADAA(shopName) {
return(

<p>
I  understand  and  agree that  PADI  Members  ("Members"),  including {shopName} and/or any  individual  PADI Instructors and
Divemasters associated with the program in which I am participating, are licensed tousevarious PADI Trademarks and to conduct PADI training,
but are not agents, employees or franchisees of PADI EMEA Ltd., PADI Americas, Inc., or it sparent,
subsidiary  and affiliated corporations  ("PADI").  I  further understand that  Member  business activities are independent,  and  are
neither owned nor operated by PADI, and that while PADI establishes the standards for PADI diver training programs, it is not
responsible for, nor does it have the right to control, the operation of the Members' business activities and the day-to-day conduct
of PADI programs and supervision of divers by the Members or the ira ssociated staff.
</p>

)
}

export function get_SRL() {
return(
    <>
    <p>This is a statement in which you, the certified diver, are informed of the risks of skin and scuba diving. The statement also sets out the circumstances in which you participate in the diving experience at your own risk. </p>
    <p>Your signature on this statement is required as proof that you have received and read this statement. It is important that you read the contents of this statement before signing it. If you do not understand anything contained in this statement, then please discuss it with your instructor. If you are a minor, this form must also be signed by a parent or guardian.</p>
    <p><b>Warning</b></p>
    <p>Skin and scuba diving have inherentr isks which may result in serious injury or death.</p>
    <p>Diving with compressed air involves certain inherent risks; decompression sickness, embolism or other hyperbaric injury can occur that require treatment in a recompression chamber. Open water diving trips that maybe necessary for this experience maybe conducted at a site that is remote, either by time or distance or both, from such a recompression chamber. Skin and scuba diving are physically strenuous activities and you will be exerting your self during this diving experience. Past or present medical conditions may be contraindicative to your participation in this experience. You must be in good mental and physical fitness for diving, and
    not under the influence of alcohol, nor any drugs that are contraindicatory to diving. If you are taking medications, that you have seen a physician and have approval to dive whilst taking these.</p>
    <p>You need to inspect all of your equipment prior to this experience and not if the dive professionals and the facility through which this experience is offered if any of your equipment is not working properly.</p>
    </>
)
}

export function get_AcceptanceOfRisk(shopName) {
return(
<>
<p>I  understand  and agree that neither the dive professionals conducting this program, {shopName} Staff,
nor the facility through which this program is conducted, {shopName}, nor PADI EMEA Ltd., nor PADI Americas, Inc.
nor their affiliate or subsidiary corporations, nor any of their respective employees, officers, agents, contractors or assigns accept any responsibility
for any death, injury or other loss suffered by metothe extent that it result from my own conductorany matter or condition under my control that amounts to my own contributory negligence.</p>

<p>In the absence of any negligence or other breach of duty by the dive professionals conducting this program, {shopName} Staff,
the facility through which this program is offered, {shopName}, PADI EMEA Ltd., PADI Americas, Inc. and
all parties referred to above, my participation in this diving program is entirely at my own risk. </p>
</>
)}

export const accRiskStatementText = "I HAVE FULLY INFORMED MYSELF OF THE CONTENTS OF THIS NON-AGENCY DISCLOSURE AND ACKNOWLEDGMENT AGREEMENT AND STATEMENT OF RISK AND LIABILITY BY READING BOTH BEFORE SIGNING THESE STATEMENTS."

export function get_statement(name) {
    return(
    <>
        <p>Please read carefully before giving your consent below</p>
        <p>This is a statment in which you are informed of the established safe diving practices for skin and scuba diving.
            These practices have been compiled for your review and acknowledgement and are intended to increase your comfort and safety in diving.
            Your signature on this statement is required as proof that you are aware of these safe diving practices. Read and discuss the statement prior to signing it.
            If you are a minor, this form must also be signed by a parent or guardian.
        </p>
        <div>I, {name}, understand, that as a diver I should
        <ol>
            <li>Maintain good mental and physical fitness for diving. Avoid being under the influence of alcohol or dangerous drugs when diving. Keep proficient in diving skills, striving to increase them through continuing education and reviewing them in controlled conditions after a period of diving inactivity, and refer to my course materials to stay current and refresh myself on important information.</li>
            <li>Be familiar with my dive sites. If not, obtain a formal diving orientation from a knowledgeable, local source. If diving conditions are worse than those in which I am experienced, postpone diving or select an alternate site with better conditions. Engage only in diving activities consistent with my training and experience. Do not engage in cave diving unless specifically trained to do so.</li>
            <li>Use complete, well-maintained, reliable equipment with which I am familiar; and inspect it for correct fit and function prior to each dive. Have a buoyancy control device, low-pressure buoyancy control inflation system, submersible pressure gauge and alternate air source and dive planning/monitoring device (dive computer, RDP/dive tables-which-ever you are trained to use) when scuba diving. Deny use of my equipment to uncertified divers.</li>
            <li>Listen carefully to dive briefings and directions and respect the advice of those supervising my diving activities. Recognize that additional training is recommended for participation in specialty diving activities, in other geographic areas and after periods of inactivity that exceed six months.</li>
            <li>Adhere to the buddy system throughout every dive. Plan dives – including communications, procedures for reuniting in case of separation and emergency procedures – with my buddy.</li>
            <li>Be proficient in dive planning (dive computer or dive table use). Make all dives no decompression dives and allow a margin of safety. Have a means to monitor depth and time underwater. Limit maximum depth to my level of training and experience. Ascend at a rate of not more than 18 metres/60 feet per minute. Be a SAFE diver – Slowly Ascend From Every dive. Make a safety stop as an added precaution, usually at 5 metres/15 feet for three minutes or longer.</li>
            <li>Maintain proper buoyancy. Adjust weighting at the surface for neutral buoyancy with no air in my buoyancy control device. Maintain neutral buoyancy while underwater. Be buoyant for surface swimming and resting. Have weights clear for easy removal, and establish buoyancy when in distress while diving. Carry at least one surface signaling device (such as signal tube, whistle, mirror).</li>
            <li>Breathe properly for diving. Never breath-hold or skip-breathe when breathing compressed air, and avoid excessive hyperventilation when breath-hold diving. Avoid overexertion while in and underwater and dive within my limitations.</li>
            <li>Use a boat, float or other surface support station, whenever feasible.</li>
            <li>Know and obey local dive laws and regulations, including fish and game and dive flag laws.</li>
        </ol>
         </div>
     </>
    )
}

export const standardStatementText = "I have read the above statements and have had any questions answered to my satisfaction. I understand the importance and purposes of these established practices. I recognize they are for my own safety and well-being, and that failure to adhere to them can place me in jeopardy when diving."

export const healthQuestionsPart2 = {
0: {'Qs': {0: {"label": 'Thoracic surgery, cardiac surgery, aortic surgery, stent insertion or pneumothorax (collapse lung).',"checked": false},
            1: {"label": 'Asthma, wheezing, severe allergies, hay fever o congested respitory airways in the last 12 months that limit my physical activity or exercise.',"checked": false},
            2: {"label": 'A problem or sickness that effects my heart such as; angina of the chest, pain in the chest when making an effort, heart failure, pulmonary edema, cardiomyopathy or a cerebralvascular accident. Or I am taking medication for a cardiac affliction.',"checked": false},
            3: {"label": 'Reoccuring bronchitis, persistent cough in the last 12 months or I have been diagnosed with emphysema.',"checked": false}
            },
     'enabled': false,
     'header': 'I have/have had',
     'label': 'Box A'},
 1: {'Qs': {0: {"label": 'I currently smoke or inhale nicotine by other means',"checked": false},
            1: {"label": 'I have a high level of cholesterol.',"checked": false},
            2: {"label": 'I have high blood pressure.',"checked": false},
            3: {"label": 'I have had a family member (first or second blood relationship) who died of sudden death, heart disease, or stroke before age 50. Or I have a family history of heart disease before age 50 (including abnormal heart rhythms, heart disease of) coronary arteries or cardiomyopathia).', "checked": false}
            },
     'enabled': false,
     "header": "I am over 45 years of age AND",
     'label': 'Box B'},
 2: {'Qs': {0: {"label": 'Sinus surgery in the last 6 months',"checked": false},
            1: {"label": 'Sickness of the ears or surgery in the ears. Loss of hearing or changes to balance.',"checked": false},
            2: {"label": 'Recurring sinusitus in the last 12 months.',"checked": false},
            3: {"label": 'Eye surgery in the last 3 months.', "checked": false}
            },
     'enabled': false,
     'header': 'I have/have had',
     'label': 'Box C'},
 3: {'Qs': {0: {"label": 'Head injury with loss of consciouness in the last 5 years.',"checked": false},
            1: {"label": 'Persistent neurological injury or sickness.',"checked": false},
            2: {"label": 'Reoccurring headaches in the last 12 months or currently taking medications to prevent them.',"checked": false},
            3: {"label": 'Fainting (total loss or partial loss of consciousness) in the last 5 years.',"checked": false},
            4: {"label": 'Epilepsy attacks or convulsions or taking medications to prevent them.', "checked": false}
            },
     'enabled': false,
     'header': 'I have/have had',
     'label': 'Box D'},
 4: {'Qs': {0: {"label": 'Behavioural problems, mental health or psychological issues that require psychiatric treatment or medication.',"checked": false},
            1: {"label": 'Major depression, suicidal tendencies, panic attacks or uncontrollable bipolar disorder that requires treatment.',"checked": false},
            2: {"label": 'I have been diagnosed with a mental health condition or a learning/development difficulty that requires continuos attention.',"checked": false},
            3: {"label": 'An addiction to drugs or alcohol that has required treatment in the last 5 years.', "checked": false}
            },
     'enabled': false,
     'header': 'I have/have had',
     'label': 'Box E'},
 5: {'Qs': {0: {"label": 'Reocurring back problems that in the last 6 months have limited my daily activity.',"checked": false},
            1: {"label": 'Surgery on the back or spinal column in the last 12 months. ',"checked": false},
            2: {"label": 'Diabetes, controlled by insulin or diet, or diabetes gestational.',"checked": false},
            3: {"label": 'An uncorrected hernia that effects my physical abilities. ',"checked": false},
            4: {"label": 'Active or untreated ulcers, problematic injuries or surgery or ulcers in the last 6 months.', "checked": false}
            },
     'enabled': false,
     'header': 'I have/have had',
     'label': 'Box F'},
 6: {'Qs': {0: {"label": "Surgery of estoma and I don't have medical clearance for swimming or participate in physical activity.","checked": false},
            1: {"label": 'Dehydration that has required medical intervention in the last 7 days.',"checked": false},
            2: {"label": 'Active or untreated stomach or intestinal ulcers, or surgery for the ulcers in the last 6 months.',"checked": false},
            3: {"label": 'Frequent heartburn, reflex or gastroesophageal reflux disease (GERD).',"checked": false},
            4: {"label": "Active or uncontrolled ulcerative colitis or Chrohn's disease.","checked": false},
            5: {"label": 'Bariatric surgery in the last 12 months.', "checked": false}
            },
     'enabled': false,
     'header': 'I have',
     'label': 'Box G'}
}


export const questionnaireIntro_1 = "Scuba diving requires that practicioners are in good mental and physical health. There are some medical conditions that can cause problems during diving and these are listed below.  Those who have or are predisposed to any of these conditions should be evaluated by a doctor before taking part in any diving related activities. This Medical Questionnaire provides a base to determine whether you should seek out that evaluation. If you have any concerns about your physical health with regards to diving, and they are not represented on this formula, please consult your doctor before diving."
export const questionnaireIntro_2 = "The references to 'diving' in this formula refer equally to scuba diving with compressed air and tank as they do to freediving/apnea. This formula is designed, in principle, as an inicial medical exam for new divers but is also appropriate for those who are undertaking activities that are considered to be continuing education. For your safety, and that of others that may dive with you, please respond to all of the questions honestly."

//"Recreational scuba diving and freediving requires good physical and mental health. There are a few medical conditions which can be hazardous while diving, listed below. Those who have, or are predisposed to, any of these conditions, should be evaluated by a physician. This Diver Medical Participant Questionnaire provides a basis to determine if you should seek out that evaluation. If you have any concerns about your diving fitness not represented on this form, consult with your physician before diving. If you are feeling ill, avoid diving. If you think you may have a contagious disease, protect yourself and others by not participating in dive training and/or dive activities. References to 'diving' on this form encompass both recreational scuba diving and freediving. This form is principally designed as an initial medical screen for new divers, but is also appropriate for divers taking continuing education. For your safety, and that of others who may dive with you, answer all questions honestly."

export const questionnaireSubIntro = "Completing this questionnaire is a requirement for those wishing to partake in apnea or scuba diving activities."
//"Complete this questionnaire as a prerequisite to a recreational scuba diving or freediving course."
export const questionnaireNoteWomen = "Note for women: If you are pregnant, or trying to conceive, do not dive."
//"Note to women: If you are pregnant, or attempting to become pregnant, do not dive."

export const questionnaireOutro = "If you have responded NO to the 10 questions above, you do not require a medical evaluation. Please, read and accept the Declaration of Participant below with the date and your signature."

export const questionnaireStatement = "Declaration of participant: I have answered all of the questions honestly and to the best of my knowledge. I understand that I accept responsibility for any consequences that might come about as  result of having responded incorrectly or for not having revealed any existing or past conditions."

export const questionnaireSignature = "Signature of participant (or, if the participant is a minor, we require the signature of a parent or guardian)."

export const questionnairePostSignatureNote = "If you answered YES to questions 3, 5, or 10 above or to any of the questions on page 2, please read and accept the declaration above with the date and your signature and take this Formula for Medical Evaluation to your doctor for a full medical evaluation. Your participation in a diving training program requires evaluation and approval from your doctor."
export const questionnairePostSignatureNoteForPopup = "You answered YES to questions 3, 5, or 10 above or to any of the questions on page 2. Please take this Form for Medical Evaluation to your doctor for a full medical evaluation. Your participation in a diving training program requires evaluation and approval from your doctor."
//export const riskStatementText = "I HAVE FULLY INFORMED MYSELF OF THE CONTENTS OF THIS NON-AGENCY DISCLOSURE AND ACKNOWLEDGMENT AGREEMENT AND STATEMENT OF RISK AND LIABILITY BY READING BOTH BEFORE SIGNING THESE STATEMENTS."

export const healthQuestionsPart1 = {
    0: {"label": "I have had problems with my lungs and breathing or heart and blood.",
        "checked": false,
        "followup": 0},
    1: {"label": "I am older than 45 years old",
        "checked": false,
        "followup": 1},
    2: {"label": "I find moderate exercise (e.g. walking a mile in 12 minutes or swimming 200m without a break) difficult, or I haven't been able to complete a normal physical activity due to physical or health reasons in the last 12 months.",
        "checked": false,
        "followup": -1},
    3: {"label":  "I have had problems with my eyes, ears, nasal passages or sinuses." ,
        "checked": false,
        "followup": 2},
    4: {"label": "I have had surgery in the last 12 months, or I have ongoing problems related to a previous surgery.",
        "checked": false,
        "followup": -1},
    5: {"label": "I have lost consciousness, had headaches or migraines, convulsions, stroke, head injury, or suffered from persistent neurological injury or disease.",
        "checked": false,
        "followup": 3},
    6: {"label": "Have you had any psychological problems, been diagnosed with a learning disability, personality disorder, panic attacks or addiction to alcohol.",
        "checked": false,
        "followup": 4},
    7: {"label": "I have had problems with the back, hernia, ulcers or diabetes.",
        "checked": false,
        "followup": 5},
    8: {"label": "I have had stomach or intestinal problems recently, including diarrohea.",
        "checked": false,
        "followup": 6},
    9: {"label": " I am taking prescribed medications (with the exception of contraceptives and antimalarial medications).",
        "checked": false,
        "followup": 6}
}



