import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { ColoredLine } from "../components/Lists";
import { onError } from "../lib/errorLib";
import { createPDF } from "../lib/pdfLib";
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import Footer from "../components/Footer"
import "./SigCanvas.css"
import LoadingOverlay from 'react-loading-overlay';
import {PopNotice} from "../components/Popups";
import {getQReviewOpen} from  "../containers/DiverSingleVisit";
import {
    healthQuestionsPart1,
    healthQuestionsPart2,
    questionnaireIntro_1,
    questionnaireIntro_2,
    questionnaireSubIntro,
    questionnaireNoteWomen,
    questionnaireOutro,
     questionnaireStatement,
     questionnaireSignature,
     questionnairePostSignatureNote,
    questionnairePostSignatureNoteForPopup,
     standardStatementText,
     get_statement,
     get_NADAA,
     get_SRL,
     get_AcceptanceOfRisk,
     accRiskStatementText} from "../diverlib/dummylists";
import 'react-phone-number-input/style.css';
import ToggleBasic, { ToggleList } from '../components/Toggles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDiverContext } from "../lib/contextLib";

import NavHeader from "../components/Header";
import {YesNoList, HQ1ForPDF} from "../components/PDFHelpers";
import {scubchiEmailShopId} from "../lib/constants";

export default function Questionnaires() {

  const { diverId, visitId } = useParams(); // should be "generic" or uuid in case of shadowdivers
  const [isPageLoading, setIsPageLoading] = useState(true);

  const pageHeaders = {
    0: "Medical I",
    1: "Medical II",
    2: "Participant statement",
    3: "Save Diving"
    }

  const [currentHeader, setHeader] = useState(pageHeaders[0])

  //const {diverProfile, setDiverProfile, shopList, setShopList} = useDiverContext();
  const {diverProfile, shopList} = useDiverContext();
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [currentPage, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  //bools to check if statements were answered
  const [participantStatement, setParticipantStatement] = useState(false);
  const [standardStatement, setStandardStatement] = useState(false);
  const [accRiskStatement, setAccRiskStatement] = useState(false);

  //const [visitDiver, setVisitDiver] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [shopID, setShopID] = useState(-1);
  const [dateOfArrival, setDateOfArrival] = useState(new Date())
  const [shopName, setShopName] = useState("");
  const healtQ1FromDict = getQuestionnaireForList(healthQuestionsPart1);
  const [healthQ1List, setHealthQ1List] = useState(healtQ1FromDict);
  var followupQDict = healthQuestionsPart2;
  const [doFollowUp, setDoFollowUp] = useState(false)
  const [showMedicalNotice, setShowMedicalNotice] = useState(false);
  const today = new Date()
  // define followup Qs. Leave empty if not needed
  const [fuQA, setFuQA] = useState([])
  const [fuQB, setFuQB] = useState([])
  const [fuQC, setFuQC] = useState([])
  const [fuQD, setFuQD] = useState([])
  const [fuQE, setFuQE] = useState([])
  const [fuQF, setFuQF] = useState([])
  const [fuQG, setFuQG] = useState([])
  const [followUpKeys, setFollowUpKeys] = useState([])
  const fu_setter_dict = {0: {"setter": setFuQA, "variable": fuQA},
          1: {"setter": setFuQB, "variable": fuQB},
          2: {"setter": setFuQC, "variable": fuQC},
          3: {"setter": setFuQD, "variable": fuQD},
          4: {"setter": setFuQE, "variable": fuQE},
          5: {"setter": setFuQF, "variable": fuQF},
          6: {"setter": setFuQG, "variable": fuQG}
    }

  const [visitedPagesForInitErrors, setVisitedPages] = useState([0]);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  //const [questionnairesDone, setQuestDone] =useState(false);

  function getQuestionnaireForList(q_dict) {
    const this_questionnaire = []
    for (const [key, value] of Object.entries(q_dict)){
            this_questionnaire.push({key: key, label: value["label"], checked: false})
        }
    return(this_questionnaire)
  }

  function createFollowUpQLists(checkedList, questionnaireDict){
    var this_followupKeys = []
    var this_doFollowup = false
    checkedList.filter(item => item.checked === true )
        .forEach( item => {
            const this_followupKey = questionnaireDict[item.key].followup
            if ( this_followupKey > -1) {
                // store which keys to followup in secondary questionnaire
                this_followupKeys.push(this_followupKey)
                // get respective sub dictionary and convert into list for render
                var this_Q_dict = followupQDict[this_followupKey].Qs;
                var this_Q_list = getQuestionnaireForList(this_Q_dict)
                // set list in respective setter
                fu_setter_dict[this_followupKey]["setter"](this_Q_list)
            }
            }
         )
    if (this_followupKeys.length > 0) {
        this_doFollowup = true
    }
    setFollowUpKeys(this_followupKeys)
    setDoFollowUp(this_doFollowup)
    return(this_doFollowup)
  }

    function updateMedicalStatus(mS, shopId, visitId){
        return API.put("visits", `/divecenters/${shopId}/visits/${visitId}/medical_status`, {
            body: {"medicalStatus": mS}
        });
    }

  function getShowMedicalNotice(){
      // check if any essential question in the first quesionnarie was set to yes
      var medicalRequired = false
      const check_ids = [2,4,9]
      for (let i=0; i<check_ids.length; i++) {
              if (healthQ1List[check_ids[i]].checked === true){
                  medicalRequired = true;
              }
          }
      if (doFollowUp === true){
          for (let i=0; i< followUpKeys.length; i++){
             const this_list = fu_setter_dict[followUpKeys[i]]["variable"]
             for (let j=0; j<this_list.length; j++) {
                  if (this_list[j].checked === true){
                      medicalRequired = true;
                  }
              }
          }
      };
      if (medicalRequired=== true){
          updateMedicalStatus(1, shopID, visitId);
      }
      return medicalRequired;
  }

  useEffect(() => {
    function loadVisitInfo(this_visit_id) {
            return API.get("visits",`/diver/visits/${this_visit_id}`, {
            });
    }

    function loadDiverInfo(uuid){
        function loadDiverInfoByVisitId(visitId) {
          return API.get("divers", `/divecenters/diver/${visitId}`);
        }
        async function onLoadDiver(visitId) {
          try {
            const loaded_diver_dict = await loadDiverInfoByVisitId(visitId);
            if ("status" in loaded_diver_dict) {
                if (loaded_diver_dict["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_diver_dict;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadDiver(uuid);
    }

    async function onLoad() {

      try {
        setErrors({
            "init": "InitialSelect"
          })
        if (visitId === "new") {
            console.log("should never have happened - what are you doing here?")
        } else {
            const loaded_visit = await loadVisitInfo(visitId);
            var loaded_diver = {}
            if (diverId === "generic") {
                loaded_diver = diverProfile;
                console.log("take profile from generic to get BD")
            } else {
                loaded_diver = await loadDiverInfo(visitId);
                console.log("take profile from newly loaded diver in shop mode to get BD")
            }
            setDateOfBirth(loaded_diver["personalDetails"]["dateOfBirth"])
            setShopID(loaded_visit["centerId"]);
            var loaded_dateOfArrival = new Date(loaded_visit["dateOfArrival"]+"T10:00:00Z");
            setDateOfArrival(loaded_dateOfArrival);
            if (loaded_visit["centerId"] === "-1" || loaded_visit["centerId"] === scubchiEmailShopId ) {
                setShopName(loaded_visit["DCName"])
            } else {
                setShopName( shopList.filter(item => item.centerId === loaded_visit["centerId"])[0]["DCName"]  )
            }
            setFirstName(loaded_visit["firstName"]);
            setLastName(loaded_visit["lastName"]);
            //setQuestDone(loaded_visit["questionnairesDone"])
        }
      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false)
    }
    onLoad();
  }, [shopList, diverProfile,  diverId, visitId]);


  async function handleNextPreviousClick(next_or_previous) {
      console.log(shopName)
    var newpage = currentPage;
    var tmp_visitedPages = visitedPagesForInitErrors;
    if (!(currentPage === 3)) {
        window.scrollTo({
            top: 0
        });
    }
    if (next_or_previous === "previous"){
        if (currentPage === 0){
            history.goBack()
        } else {
            newpage -= 1;
            if (currentPage === 2 && doFollowUp === false){ // skip followup also on back-move if not required
                newpage -= 1
            }
        }

    }

    if (next_or_previous === "next") {
        newpage += 1;
        if (currentPage === 0){
            var this_doFollowup = createFollowUpQLists(healthQ1List, healthQuestionsPart1)
            if (doFollowUp === false && this_doFollowup === false){
                newpage += 1 // skip followup questionnaire
            }
        }
    }

    // set page specific initial errors so next button is disabled until something is done
    var new_errors = errors;
    if (newpage === 0 && !(visitedPagesForInitErrors.includes(0))) {
        new_errors["init"] = "init_error"
        setErrors(new_errors)
        tmp_visitedPages.push(0)
        setVisitedPages(tmp_visitedPages)
    }

    if (newpage === 2 && !visitedPagesForInitErrors.includes(2)) {
        new_errors["init"] = "init_error"
        setErrors(new_errors)
        tmp_visitedPages.push(2)
        setVisitedPages(tmp_visitedPages)
    }
    if (newpage === 3 && !visitedPagesForInitErrors.includes(3)) {
        new_errors["init"] = "init_error"
        setErrors(new_errors)
        tmp_visitedPages.push(3)
        setVisitedPages(tmp_visitedPages)
    }

  if (currentPage === 3 && next_or_previous === "finish"){
    setIsLoading(true)
      // ToDo: Upload files to bucket
      // ToDo: update questionnairesDone in database
      var p1 = createPDF("HQ1ForPDFReal", { "filename": "healthQ1.upload", "visitId": visitId, "target_center_folder": true});
      var p2 = createPDF("HQ2ForPDFReal", { "filename": "healthQ2.upload", "visitId": visitId, "target_center_folder": true});
      var p3 = createPDF("NDAgreementForPDF", { "filename": "participantStatement.upload", "visitId": visitId, "target_center_folder": true});
      var p4 = createPDF("SaveDivingAgreementForPDF",{ "filename":  "saveDivingStatement.upload", "visitId": visitId, "target_center_folder": true});
      await Promise.all([p1,p2,p3,p4]);
      await updateQuestionnairesDone()
      if (getQReviewOpen(dateOfArrival) === true){
          await updateQuestionnairesReviewed()
      }


     setIsLoading(false)


      if (diverId === "generic"){
        history.push(`/diver/visits/${visitId}/generic`)
      } else {
        // ToDo if we redirect to shop/divers/divers, the new divers are not loaded as this happens in shop/main
        history.push(`/shop/main`)
      }
      // ToDo: Maybe redirect just wito goBack()?
      //To Do if registered diver should be able to modify questionnaire when arriving at shop, i.e. in shop app, we may need to redirect at this stage
      // Adaption of questionnaire not possible when done --> delete visit and re-register

  }
    if (newpage === 2){
        setShowMedicalNotice(getShowMedicalNotice());
    }

    setPage(newpage)
    setHeader(pageHeaders[newpage])
  }

  function updateQuestionnairesDone(){

        return API.put("visits", `/divecenters/${shopID}/visits/${visitId}/questionnaires`, {
            body: {"questionnairesDone": true}
        });
  }


    function updateQuestionnairesReviewed(){
        return API.put("visits", `/divecenters/${shopID}/visits/${visitId}/questionnaires_reviewed`, {
            body: {"questionnairesReviewed": true}
        });
    }


  function validateForm() {
   /* Example: https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2 */
    return (Object.keys(errors).length === 0);
  }

  function setValidateParticipantStatement (value) {
    if (value === false){
        errors["participantStatement"] = "Please agree to the Participant Statement";
    } else {
        delete errors["init"]
        delete errors["participantStatement"];
    }
    setParticipantStatement(value)
  }

  function setValidateStandardStatement (value) {
    if (value === false){
        errors["standardStatement"] = "Please agree to the Statement for Safe diving practices";
    } else {
        delete errors["init"]
        delete errors["standardStatement"];
    }
    setStandardStatement(value)
  }

  function setValidateAccRiskStatement (value) {
    if (value === false){
        errors["accRiskStatement"] = "Please agree to the Statement regarding Non-Agency Disclosure and Acknowledgement, Risk and Liability and Acceptance of Risk above.";

    } else {
        delete errors["init"]
        delete errors["accRiskStatement"];
    }
    setAccRiskStatement(value)
  }


  function handleToggleList(id, this_list, setter) {
    const newList = this_list.map((item, index) => {
      if (index === id) {
        const updatedItem = {...item, checked: !item.checked, };
        return updatedItem;
      }
      return item;
    });
    setter(newList);
  }


  return (
    <div className="DiverInfo">
      <LoadingOverlay
              active={isPageLoading}
              spinner
              text='Loading Questionnaires...'
              >

      <Form>

      <NavHeader prev={currentPage > 0 ? true : false}
            next={true}
            disabled={!(validateForm()) || isLoading}
            label={currentHeader}
            handler={handleNextPreviousClick}/>


        { (currentPage ===  0 ) ? <>
         <div id="healthQ1">
            <Form.Group className="mb-3 pl-3 pr-3" controlId="healthQ1">
                <Form.Label><b>General health questionnaire for Recreational Diving</b></Form.Label>
                <Container fluid>
                    <Row>{questionnaireIntro_1}</Row>
                    <Row>{questionnaireIntro_2}</Row>
                    <Row><Col className="text-center">
                      <b>Instructions</b>
                    </Col></Row>
                    <Row>{questionnaireSubIntro}</Row>
                    <Row>{questionnaireNoteWomen}</Row>
                    <ColoredLine/>
                    <ToggleList mylist={healthQ1List} changehandler={handleToggleList} setter={setHealthQ1List}/>
                    <ColoredLine/>
                </Container>
            </Form.Group>
            <Container fluid>
                <Row><Col className="text-center">
                      <b>Signed by the participant</b>
                    </Col></Row>
                 <Row>{questionnaireOutro}</Row><br/>
                 <Row className="pb-3">
                     <ToggleBasic className="pl-0 pb-3 pr-0" setter={setValidateParticipantStatement} label=<b>{questionnaireStatement}</b> checked={participantStatement}/>
                </Row>
                 <Row>{questionnaireSignature}</Row><br/>
                <Row><b>Date: </b>  {today.toISOString().slice(0,10)} </Row>
                <Row className="pb-3">
                    <Col><b>Name of Participant: </b>{firstName + " " + lastName}</Col>
                    <Col><b>Date of birth: </b>{dateOfBirth}</Col>
                </Row>
                <Row className="pb-3 pt-3">
                    <Col><b>Name of Instructor: </b></Col>
                    <Col><b>Name of Divecenter: </b>{shopName}</Col>
                </Row>
                <Row className="pt-3"> <b>NOTE: </b>{questionnairePostSignatureNote} </Row>

            </Container>
        </div>
        <Row className="d-none">

        </Row>
        </>:<></>
        /*endif currentPage*/}

        { (currentPage ===  1 ) ? <>

        <Form.Group className="mb-3 pl-3 pt-3" controlId="healthQ2">
           <Form.Label><b>DiverMedical</b> | Participant Questionnaire Continued </Form.Label>

            {followUpKeys.map(function(key, index) {
              return(
              <div key={key}>{healthQuestionsPart2[key]["label"]} -- {healthQuestionsPart2[key]["header"]}
              <ToggleList mylist={fu_setter_dict[key]["variable"]} changehandler={handleToggleList} setter={fu_setter_dict[key]["setter"]}/>
              </div>
              )
            })
            }
        </Form.Group>
        <Row className="d-none">

        </Row>
        </>:<></>
        /*endif currentPage*/}

        { (currentPage ===  2 ) ? <>
            <PopNotice
                show={showMedicalNotice}
                setShow={setShowMedicalNotice}
                text={questionnairePostSignatureNoteForPopup}
                title="Notice"
                handleConfirm={() => setShowMedicalNotice(false)}
                confirmText="I understand"
            />
            <Form.Group className="mb-3 pl-3 pr-3" controlId="NDAgreement">
                <Form.Label>Please read carefully and agree to the statement below</Form.Label>
                <h3>Non-Agency Disclosure and Acknowledgment Agreement</h3>
                {get_NADAA(shopName)}
                <h3>Statement of Risk and Liability</h3>
                {get_SRL()}
                <h3>Acceptance of Risk</h3>
                {get_AcceptanceOfRisk(shopName)}
            </Form.Group>
            <div className="pb-3">
                <ToggleBasic className="pt-0 pr-0" setter={setValidateAccRiskStatement} label=<b>{accRiskStatementText}</b> checked={accRiskStatement}/>
            </div>
            { errors.hasOwnProperty("accRiskStatement") ? <>
                <div style={{color: "red"}}>Please agree to the Statement regarding Non-Agency Disclosure and Acknowledgement, Risk and Liability and Acceptance of Risk above.</div>
            </>:<></>}
            <Row className="d-none">

            </Row>
        </>:<></>
        /*endif currentPage*/}

        { (currentPage ===  3 ) ? <>
            <Form.Group className="mb-3 pl-3 pr-3" controlId="healthQ2">
                <Form.Label>Standard Save Diving Practices Statement of Understanding</Form.Label>
                    {get_statement(firstName+" "+lastName)}
            </Form.Group>
            <div className="pb-3">
                <ToggleBasic setter={setValidateStandardStatement} label=<b>{standardStatementText}</b> checked={standardStatement}/>
            </div>
            { errors.hasOwnProperty("standardStatement") ? <>
                <div style={{color: "red"}}>Please agree to the Statement for Safe Diving Practices</div>
            </>:<></>}


            {/*Following Row: rendered divs for pdf print*/}
            <Row className="d-none">
                <div id="HQ1ForPDFReal" style={{width: 1000}}>
                    <HQ1ForPDF mylist={healthQ1List} myName={firstName + " " + lastName} shopName={shopName} dateOfBirth={dateOfBirth}/>
                </div>
                <div id="HQ2ForPDFReal" style={{width: 1000}}>
                    <Container fluid className="pl-5 pr-3">
                    <Row> <b>DiverMedical</b> | Participant Questionnaire Continued </Row>
                    {followUpKeys.map(function(key, index) {
                          return(
                            <>
                            <Row style={{width: 1200}}>{healthQuestionsPart2[key]["label"]} -- {healthQuestionsPart2[key]["header"]}</Row>
                            <Row style={{width: 1200}}>
                                <YesNoList mylist={fu_setter_dict[key]["variable"]}/>
                            </Row>
                            </>
                          )
                    })}
                    </Container>
                </div>
                <div id="NDAgreementForPDF" style={{width: 1000}}>
                    <Form.Group className="mb-3 pl-3 pr-3" controlId="NDAgreement">
                        <Form.Label>Please read carefully and agree to the statement below</Form.Label>
                        <h3>Non-Agency Disclosure and Acknowledgment Agreement</h3>
                        {get_NADAA(shopName)}
                        <h3>Statement of Risk and Liability</h3>
                        {get_SRL()}
                        <h3>Acceptance of Risk</h3>
                        {get_AcceptanceOfRisk(shopName)}
                    </Form.Group>
                    <Container fluid>
                        <Row className="pl-3 pr-3">
                            <Col sm={8}><b>{accRiskStatementText}</b></Col>
                            <Col sm={2} className="text-right"> {(accRiskStatement === true) ? <>yes</>:<></>} </Col>
                            <Col sm={2} className="text-right"> {(accRiskStatement === true) ? <></>:<>no</>} </Col>
                        </Row>
                    </Container>
                </div>

                <div id="SaveDivingAgreementForPDF" style={{width: 1000}}>
                    <Form.Group className="mb-3 pl-3 pr-3" controlId="healthQ2">
                        <Form.Label>Standard Save Diving Practices Statement of Understanding</Form.Label>
                            {get_statement(firstName+" "+lastName)}
                    </Form.Group>
                    <Container fluid>
                        <Row className="pl-3 pr-3">
                            <Col sm={8}><b>{standardStatementText}</b></Col>
                            <Col sm={2} className="text-right"> {(standardStatement === true) ? <>yes</>:<></>} </Col>
                            <Col sm={2} className="text-right"> {(standardStatement === true) ? <></>:<>no</>} </Col>
                        </Row>
                        <ColoredLine className="scbline"/>
                        <Row>
                            {`These forms were filled by ${firstName+" "+lastName} on ${new Date()}:`}
                        </Row>
                    </Container>


                </div>
            </Row>
           </>:<></>
        /*endif currentPage*/}
    <Footer>
       { (currentPage === 3 ) ? <>
        <LoaderButton
          block
          onClick={(e) => handleNextPreviousClick("finish")}
          size="lg"
          value="submit"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm() }
        >
          Finish
        </LoaderButton>

        </>:<>
        <LoaderButton
          block
          onClick={(e) => handleNextPreviousClick("next")}
          size="lg"
          value="next"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Continue
        </LoaderButton>
       </>}
      </Footer>
      </Form>
      </LoadingOverlay>
    </div>
  );
}
