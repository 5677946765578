import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ColoredLine} from "../components/Lists";
import {dateTimeView} from "../components/Helpers";
import {getBrevetLabelFromId, getMainBrevets, getSpecialties} from "../diverlib/brevets";
import {StaticScubchiText} from "../components/Header";
import React from "react";

export default function VisitViewForPDF({visitForDetails, diverForDetails}){
    const specialtiesDict = getSpecialties();
    const brevetDict = getMainBrevets();
    return(
        <div >
            <Container fluid>
                <Row>
                    <Col className="fs22 text-center pl-0 pr-0 ml-0 mr-0">
                        {visitForDetails.label}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center fs18slim pl-3 pr-3 pb-3">
                        {visitForDetails.sublabel}
                    </Col>
                </Row>
            </Container>
            <ColoredLine className="scbline"/>
            <Container fluid>
                {visitForDetails.hasOwnProperty("DCName") ?
                    <Row className="pl-0 pb-3"><Col><b>Dive center:</b></Col><Col>
                        {visitForDetails.DCName}
                    </Col></Row>
                :<></>}
                <Row className="pl-0 pb-3"><Col><b>Date of Arrival:</b></Col><Col>
                    {dateTimeView(visitForDetails.dateOfArrival, false)}
                </Col></Row>
                <Row className="pl-0 pb-3"><Col><b>{"Date of Departure: "}</b></Col><Col>
                    {dateTimeView(visitForDetails.dateOfDeparture, false)}
                </Col></Row>
                <Row className="pl-3"><b>Message from {visitForDetails.label}: </b></Row>
                <Row className= "pl-5 pr-3">{visitForDetails.PM}</Row>
            </Container>

            <ColoredLine className="scbline"/>

            <Container fluid>
                <Row className="pb-3"><Col><b>Phone:</b></Col><Col>{diverForDetails.personalDetails.phoneNumber}</Col></Row>

                <Row className="pb-3"><Col><b>Email:</b></Col><Col>{diverForDetails.personalDetails.email}</Col></Row>
                <Row><Col><b>Address:</b></Col><Col>
                    <Row className="pl-3 pb-1">{diverForDetails.personalDetails.address.addressLine}</Row>
                    <Row className="pl-3 pb-1">{diverForDetails.personalDetails.address.ZIPCode + " " + diverForDetails.personalDetails.address.city}</Row>
                    <Row className="pl-3 pb-1">{diverForDetails.personalDetails.address.country}</Row>
                </Col></Row>
                <Row className="pb-3"><Col><b>Date of birth:</b></Col><Col>{dateTimeView(diverForDetails.personalDetails.dateOfBirth, false)}</Col></Row>
            </Container>

            <ColoredLine className="scbline"/>
            {diverForDetails.personalDetails.contactPerson.provideEmergencyContact ?<>
                <Row className="pb-3 pl-3"><Col><b>Emergency Contact</b></Col><Col></Col></Row>
                <Row className="pb-3 pl-3"><Col><b>First name:</b></Col><Col>{diverForDetails.personalDetails.contactPerson.firstName}</Col></Row>
                <Row className="pb-3 pl-3"><Col><b>Last name:</b></Col><Col>{diverForDetails.personalDetails.contactPerson.lastName}</Col></Row>
                <Row className="pb-3 pl-3"><Col><b>Phone:</b></Col><Col>{diverForDetails.personalDetails.contactPerson.phoneNumber}</Col></Row>
                <ColoredLine className="scbline"/>
            </>:<></>}

            <Container fluid>
                <Row  className="pb-1">
                    <Col><b>C-level:</b></Col>
                    <Col>
                        <Row>
                            <Col>{getBrevetLabelFromId(diverForDetails.exposedBrevet, brevetDict)}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row  className="pb-1">
                    <Col><b>Certificate number:</b></Col>
                    <Col>
                        <Row>
                            <Col>{diverForDetails.exposedBrevetId}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row><Col><b>Specialties:</b></Col><Col>
                    {Object.keys(diverForDetails.scubaDetails.specialtyBrevets).map((key, index) => (
                        <Row className="pb-1" key={key}>
                            <Col>{getBrevetLabelFromId(key, specialtiesDict)}</Col>
                        </Row>
                    ))
                    }
                </Col></Row>
                <Row className="pb-3"><Col><b>Number of dives:</b></Col><Col>{diverForDetails.scubaDetails.diveRecord.numberOfDives}</Col></Row>
                <Row className="pb-3"><Col><b>Date of last dive:</b></Col><Col>
                    {dateTimeView(diverForDetails.scubaDetails.diveRecord.dateOfLastDive, false)}
                </Col></Row>

                <Row  className="pb-3">
                    <Col><b>Insurance</b></Col>
                    <Col>{ diverForDetails.scubaDetails.insuranceData.hasInsurance ? <>{diverForDetails.scubaDetails.insuranceData.insuranceProvider}</>:<>No</>    }</Col>
                </Row>
                { diverForDetails.scubaDetails.insuranceData.hasInsurance ? <>
                    <Row className="pb-4"> <Col></Col><Col> {diverForDetails.scubaDetails.insuranceData.insuranceId}</Col></Row>
                    <Row className="pb-4"> <Col></Col><Col>Expires {dateTimeView(diverForDetails.scubaDetails.insuranceData.insuranceExpirationDate, false)}</Col></Row>
                </>:<></>}
                <Row  className="pb-3">
                    <Col><b>Medical</b></Col>
                    <Col>

                        { diverForDetails.scubaDetails.medicalData.hasMedical ?
                            <>
                                {dateTimeView(diverForDetails.scubaDetails.medicalData.medicalExpirationDate, false)}
                            </>
                            :<>None</>   }
                    </Col>
                </Row>
            </Container>
            <ColoredLine className="scbline"/>
            <div className="spac80">
                <StaticScubchiText>
                    This profile was provided by
                </StaticScubchiText>
            </div>
        </div>
    );
}