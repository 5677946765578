import React from 'react';

import "./LoaderButton.css"
import "./FileUploader.css"
import Button from "react-bootstrap/Button";

//const Button = SCButton;
export default function FileUploader(props) {  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const {handleChange, ...otherProps} = props;
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      <Button className="edit" onClick={handleClick} {...otherProps} >
        {otherProps.children}
      </Button>
      <input
        type="file"
        accept="image/*,application/pdf"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  );
}

export function FotoUploader(props) {  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const {handleChange, ...otherProps} = props;
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      <Button className="edit" onClick={handleClick} {...otherProps} >
        {otherProps.children}
      </Button>
      <input
        type="file"
        accept="image/*"
        capture="camera"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  );
}