import React from 'react';
//import styled from "styled-components";
//import { View, StyleSheet } from 'react';
import Button from "react-bootstrap/Button";
//import a from '../components/Buttons';
import "./Lists.css"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {OptionsButton, EditButton, SCListButton, SCListCheckButton, SCDeleteButton } from '../components/LoaderButton'
import Form from "react-bootstrap/Form";
import Switch from "react-switch";

export const ColoredLine = ( props ) => {
    return(<hr
        {...props}
    />)
};

export const ColoredLineVarWidth = ({ color, height, width }) => {
    return(<hr
        style={{
            color: color,
            backgroundColor: color,
            borderColor: color,
            height: height,
            width: "50%",
        }}
    />)
};

//just to be able to style this one
function MarkListButton(props) {
    return(
    <Button {...props}>
      {props.children}
    </Button>
    )
}

function MarkableListComponent(props)
{
    return(
        <>
          <MarkListButton
            variant="outline-light"
            className="padded"
            style={{ color: props.item.marked ? 'blue' : 'lightgrey' }}
            onClick={() => props.changehandler(props.item.list_id, props.list, props.set_fctn)}
            block
            >
        <Container>
            <Row className="padded text-left" >
                <Col sm={1}/>
                <Col sm={4}>
                    {props.item.label}
                </Col>
                <Col sm={4}>
                    {props.item.sublabel}
                </Col>
                <Col sm={2}/>
            </Row>
        </Container>

          </MarkListButton>
          </>
    )
}

export const MarkableList = ({mylist, changehandler, set_fctn}) => {
    return(
            <div>
            {mylist.map((item) => (

                <div key={item.key}>
                <MarkableListComponent item={item}
                    changehandler={changehandler}
                    list={mylist}
                    set_fctn={set_fctn}
                />
                </div>

             ))
            }
            </div>
    )
}

function OverLayWithButton({myprops, ...props}){
//        <div className = "align_right">
    return(
        <div {...props}>
            <OverlayTrigger
                placement="left"
                trigger="click"
                overlay={(
                  <Popover>
                    <Popover.Title>Options</Popover.Title>
                    <Popover.Content>
                        <Button variant="outline-primary" block
                            onClick = {(e) => {myprops.changehandler(myprops.item.key)}}
                      > Remove from event </Button>
                        <Button variant="outline-primary" block> Show diver profile </Button>
                    </Popover.Content>
                  </Popover>
                )}>
                {/*}<OptionsButton/>*/}
                <OptionsButton>...</OptionsButton>
            </OverlayTrigger>
        </div>
        )
    }



function ListWithOptionsButtonElement(props) {
    return(
        <Row >
             <Col>
              <SCListButton
                variant="outline-light"
                //onClick={() => props.expandhandler(props.item.list_id)}
                block
                >

                       <Row className="pl-0">
                            <b>{props.item.label}</b>
                        </Row>
                        <Row className="pl-0">
                            {props.item.sublabel}
                        </Row>

             </SCListButton>

            </Col>
              <Col className = "text-right" sm={2}>
                   <OverLayWithButton className="text-right" myprops={props}/>
              </Col>
        </Row>
    )
}

export const ListWithOptionsButton = ({mylist, changehandler}) => {
    return(
        <div>
        <Container fluid>
            {mylist.map((item) => (
            <div key={item.key} >
                <ListWithOptionsButtonElement item = {item} changehandler={changehandler}/>
             </div>
             ))
            }
                </Container>
        </div>
    )
}

function DoubleListElement(props) {
    return(
        <Container>
            <Row className="padded" >
                <Col sm={1}/>
                <Col sm={4}>
                    {props.item.label}
                </Col>
                <Col sm={4}>
                    {props.item.sublabel}
                </Col>
                <Col sm={2}/>
            </Row>
        </Container>
    )
}

export const ListWithTwoEntries = ({mylist, changehandler}) => {
return(
        <div>
            {mylist.map((item) => (
                <div key={item.key} >
                    <DoubleListElement item = {item} changehandler={changehandler}/>
                </div>
             ))
            }
        </div>
    )
}


export const RenderListCheck = ({mylist, changehandler}) => {
    return(
            <div>
            <ColoredLine>"black"</ColoredLine>
            {
            mylist.map((items, index) => (
                <>
                <checkableListComponent item={items}
                             changehandler={changehandler}
                             this_item_id={index}/>
                <ColoredLine>"black"</ColoredLine>
                </>
             ))
            }
            </div>
    )
}

function ExpandableListComponent(props) {
    return(
    <>
    <Container fluid>
       <Row className="pl-3 pr-3">
          <Col sm={10}>
             <SCListButton
                variant="outline-light"
                className="padded"
                onClick={() => props.changehandler(props.item.list_id)}
                block
                >

                    <Col sm={1}/>
                    <Col sm={8}>
                       <Row className="text-center">
                            {props.item.shopName}
                        </Row>
                        <Row className="text-center">
                            {props.item.arrival} to {props.item.departure}
                        </Row>
                    </Col>
             </SCListButton>
         </Col>
         <Col className = "text-right" sm={2}>
           <EditButton
           onClick={() => props.editHandler(props.item.list_id)}
           />
         </Col>
    </Row>
        </Container>

    { (props.item.marked ) ? <>
             <Container>
               <Row>
                 <Col sm={1}/>
                   <Col sm={8}>
                    <Row>Details for this trip</Row>
                    <Row>More Details for this trip</Row>
                   </Col>
                 <Col sm={1}/>
                 </Row>
             </Container>
            </>:<>
            </>
        }
    </>)
}


export const ExpandableList = ({mylist, changehandler, editHandler}) => {
    return(
        <div>
            {mylist.map((item) => (
                <div key={item.key}>
                <ExpandableListComponent item={item}
                    changehandler={changehandler}
                    editHandler={editHandler}
                />
                </div>

             ))
            }
        </div>
    )
}

function SelectableListComponent(props)
{
    return(
        <>
       <Row>
          <Col className="pl-0 pr-0">
             <SCListCheckButton
                variant="outline-light"
                className={ (props.item.selected) ? "ListCheckButtonActive" : "ListCheckButton" }
                //active={props.item.selected}
                onClick={() => props.changehandler(props.item.key)}
                block
                >
                       <Row className="pl-3">
                            <b>{props.item.label}</b>
                        </Row>
                        <Row className="pl-3">
                            {props.item.sublabel}
                        </Row>
             </SCListCheckButton>
         </Col>
    </Row>
          </>
    )
}

export const SelectableList = (props) => {
    return(
       <div>
         <Container fluid>
            {props.mylist.map((item) => (
                <SelectableListComponent key={item.key} item={item} {...props}
                />
             ))
            }
         </Container>
        </div>
    )
}

function ExpandableDiversListComponent(props) {
    return(
    <>
       <Row>
             <SCListButton
                variant="outline-light"
                onClick={() => props.expandhandler(props.item.key)}
                block
                >
                 <Col className="pl-0 pr-0">
                       <Row className="pl-3">
                            <b>{props.item.label}</b>
                        </Row>
                        <Row className="pl-3">
                            {props.item.sublabel}
                        </Row>

                </Col>
                 {props.item.is_staff ?
                     <Col>
                         <Row className="pl-3">
                             <i>Guide</i>
                         </Row>
                     </Col>
                 :<></>}
             </SCListButton>
    </Row>
    { (props.item.viewdetails ) ? <>

                    <Row   className="m-0 p-3">
                        <Col className = "text-left pl-0 pr-0">
                            Ready
                        </Col>
                        <Col className="text-right pr-0 pl-0">
                        <Switch
                             handleDiameter={23}
                             onColor={'#2562a3'}
                             offColor={'#e60909'}
                             height={25}
                             disabled={!props.active}
                             onChange={(e) => props.readyhandler(props.item.key)}
                             checked={props.item.ready} />
                        </Col>

                    </Row>

                    <Form.Group as={Row} className="m-0 p-0" controlId="formTankNumber">
                        <Form.Label column>Tank Number</Form.Label>
                        <Col>
                            <Form.Control value = {props.item.tankNumber}
                                          type="string"
                                          placeholder="Tank Number"
                                          disabled={!props.active}
                                          className="m-0 p-0"
                                          onChange={(e) => (props.tankhandler(props.item.key, e.target.value))}
                                          />
                        </Col>
                    </Form.Group>
                    <Row className="m-0 p-0 mt-3">
                        <Col >Remove from event</Col>
                        <Col className="text-right">
                                <SCDeleteButton
                                    disabled={!props.active}
                                    onClick = {(e) => {props.deletehandler(props.item.key)}}
                                 />
                        </Col>
                    </Row>
            </>:<>
            </>
        }
    </>)
}

export const ExpandableDiversList = (props) => {
    return(
        <div>
         <Container fluid>
            {props.mylist.map((item) => (

                <div className="pl-0 pr-0" key={item.key} style={{backgroundColor: item.viewdetails ? '#FFFFFF' : '#FFFFFF' }}>
                { (item.viewdetails) ? <>
                        <ColoredLine className="scbline ml-0 mr-0 float-center"/>
                </>:<></>}
                <ExpandableDiversListComponent item={item} {...props}
                />
                { (item.viewdetails && item.list_id < props.mylist.length-1) ? <>
                    <ColoredLine className="scbline ml-0 mr-0"/>
                </>:<></>}
                </div>

             ))
            }
         </Container>
        </div>
    )
}



export function ButtonListComponent(props) {
return(
       <Row className="pl-3">
          <Col className="pl-0 pr-0">
                 <SCListButton
                    variant="outline-light"
                    onClick={() => props.changehandler(props.item.key)}
                    block
                    >
                    <Row>
                    <Col sm={8} className="pl-3 pr-3">
                           <Row className="pl-0">
                                <b>{props.item.label}</b>
                            </Row>
                            <Row className="pl-0">
                                {props.item.sublabel}
                            </Row>
                    </Col>




                     { props.item.hasOwnProperty("divesublabel") ?
                     <Col sm={4} className="text-right pl-3 pr-3">
                                <i>{props.item.divesublabel}</i>
                     </Col>
                     :
                     <></>
                     }
                     </Row>
                 </SCListButton>
         </Col>
         {/* here we can add another column if needed*/}
    </Row>
)

}

export function ButtonListThreeColComponent(props) {
return(
       <Row className="pl-3">
          <Col className="pl-0 pr-0">
                 <SCListButton
                    variant="outline-light"
                    onClick={() => props.changehandler(props.item.key)}
                    block
                    >
                    <Row>
                    <Col sm={4} className="pl-3 pr-3">
                           <Row className="pl-0">
                                <b>{props.item.label}</b>
                            </Row>
                            <Row className="pl-0">
                                {props.item.sublabel}
                            </Row>
                    </Col>


                     <Col sm={4} className="pl-0 pr-0">
                     { props.item.hasOwnProperty("divesublabel_top") && props.item.hasOwnProperty("divesublabel_bottom") ?
                           <>
                           <Row className="pl-3">
                                {props.item.divesublabel_top}
                            </Row>
                            <Row className="pl-3">
                                {props.item.divesublabel_bottom}
                            </Row>
                            </>
                     :<></>}
                     </Col>

                     { props.item.hasOwnProperty("divesublabel") ?
                     <Col sm={4} className="text-right pl-3 pr-3">
                                <i>{props.item.divesublabel}</i>
                     </Col>
                     :
                     <></>
                     }
                     </Row>
                 </SCListButton>
         </Col>
         {/* here we can add another column if needed*/}
    </Row>
)

}

export const ButtonList = ({mylist, changehandler}) => {
    return(
        <div>
          <Container fluid>
            {mylist.map((item) => (
                <div key={item.key}>
                <ButtonListComponent item={item}
                    changehandler={changehandler}
                />
                </div>
             ))
            }
          </Container>
        </div>
    )
}

export const ButtonListThreeCol = ({mylist, changehandler}) => {
    return(
        <div>
          <Container fluid>
            {mylist.map((item) => (
                <div key={item.key}>
                <ButtonListThreeColComponent item={item}
                    changehandler={changehandler}
                />
                </div>
             ))
            }
          </Container>
        </div>
    )
}




function ExpandableEventListComponent(props) {
    return(
    <>
    <Container>
       <Row className="padded">
          <Col sm={9}>
             <SCListButton
                variant="outline-light"
                className="padded"
                onClick={() => props.changehandler(props.item.list_id)}
                block
                >

                    <Col sm={1}/>
                    <Col sm={8}>
                       <Row className="text-center">
                            {props.item.eventName}
                        </Row>
                        <Row className="text-center">
                            {props.item.date} at {props.item.eventDiveSite}
                        </Row>
                    </Col>
             </SCListButton>
         </Col>
         <Col className = "text-right" sm={1}>
           <EditButton
           onClick={() => props.editHandler(props.item.list_id)}
           />
         </Col>

    { (props.item.marked ) ? <>
             <Container>
               <Row>
                 <Col sm={1}/>
                   <Col sm={8}>
                    <Row>Details for this trip</Row>
                    <Row>More Details for this trip</Row>
                   </Col>
                 <Col sm={1}/>
                 </Row>
     </Container>
            </>:<>
            </>
        }
     </Row>
     </Container>
    </>)
}

export const ExpandableEventsList = ({mylist, changehandler, editHandler}) => {
    return(
        <div>
            {mylist.map((item) => (
                <div key={item.key}>
                <ExpandableEventListComponent item={item}
                    changehandler={changehandler}
                    editHandler={editHandler}
                />
                </div>

             ))
            }
        </div>
    )
}
