import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import "./spacings.css";
import "../components/Header.css";
import { API } from "aws-amplify";
import { createPDF } from "../lib/pdfLib";
import LoaderButton, { PhoneButton, EmailButton, SCButton } from "../components/LoaderButton";
import { ButtonList, ButtonListThreeCol, ColoredLine } from "../components/Lists";
import 'react-phone-number-input/style.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useShopContext } from "../lib/contextLib";
import { getMainBrevets, getBrevetLabelFromId } from "../diverlib/brevets"
import PopConfirm, {PopConfirmText} from "../components/Popups"
import PSURLDownloadButton from "../components/PSURLDownloadButton";
import NavHeader, {MenueHeader} from "../components/Header"
import { dateTimeView} from "../components/Helpers"
import "../components/PSURLDownloadButton.css"
import DiverDataView from "./DiverDataView"
import LoadingOverlay from 'react-loading-overlay';
import VisitViewForPDF from "./VisitView";

export function getVisitsForList(visitslist) {
    const brevetDict = getMainBrevets();
    const this_list = [];
    visitslist.forEach( (item, index) => {
        //ToDo: possibility to set active
        var this_brevetString;
        if (item.exposedBrevet === 100100){
            this_brevetString = item.exposedOtherBrevetName;
        } else {
            this_brevetString = getBrevetLabelFromId(item.exposedBrevet, brevetDict);
        }
        this_list.push( {...item,
            list_id: index,
            key: item.visitId,
            //dateOfArrivalString: String(item.dateOfArrival).slice(0,10),
            dateOfArrival: new Date(item.dateOfArrival + "T10:00:00Z").setHours(12),
            //dateOfDepartureString:  String(item.dateOfDeparture).slice(0,10),
            dateOfDeparture: new Date(item.dateOfDeparture + "T10:00:00Z").setHours(12),
            label: `${item.firstName} ${item.lastName}`,
            //datelabel: `${String(item.dateOfArrival).slice(0,10)} to ${String(item.dateOfDeparture).slice(0,10)}`,
            //datelabel: `${dateTimeView(item.dateOfArrival)} to ${dateTimeView(item.dateOfDeparture)}`,
            sublabel: `${this_brevetString}`,
            checkedOut: getVisitCheckedOut(item), //auto-checkout of past dives
            divesublabel_top: `${dateTimeView(item.dateOfArrival, false)} to`,
            divesublabel_bottom: `${dateTimeView(item.dateOfDeparture, false)}`,
            selected: false} )
    }
    )
    return this_list;
}


function getVisitCheckedOut(visit){
  const today_0 = new Date().setHours(0,0,0);
     if (new Date(visit.dateOfDeparture).setHours(23,59,59) < today_0){
        return true;
     } else {
        return visit.checkedOut;
     }
}


function getVisitRequests(visitslist){
    const today_0 = new Date().setHours(0,0,1);
    return visitslist.filter(item => (item.acceptedByShop === false
        && new Date(item.dateOfDeparture).setHours(23,59,59) >= today_0
        && !(item.checkedOut)
        && !(item.rejectedByShop)))
      .map(item => {return {...item, divesublabel: "Needs appoval" }})
      .sort((a, b) => (new Date(a.dateOfArrival) > new Date(b.dateOfArrival)) ? 1 : -1 )
}
function getActiveVisits(visitslist, myShop){
    const today_0 = new Date().setHours(0,0,1);
    const tmp_visits = visitslist.filter(item => (item.acceptedByShop === true
        && new Date(item.dateOfDeparture).setHours(23,59,59) >= today_0
        && !(item.checkedOut)
        && !(item.rejectedByShop)))
    return tmp_visits.filter(item => !myShop.DCStaff.includes(item.userId))
        .sort((a, b) => (new Date(a.dateOfArrival) > new Date(b.dateOfArrival)) ? 1 : -1 )
}

function getStaffVisits(visitslist, myShop){
    const today_0 = new Date().setHours(0,0,1);
    const tmp_visits = visitslist.filter(item => (item.acceptedByShop === true
        && new Date(item.dateOfDeparture).setHours(23,59,59) >= today_0
        && !(item.checkedOut)
        && !(item.rejectedByShop)))
    return tmp_visits.filter(item => myShop.DCStaff.includes(item.userId))
    .sort((a, b) => (new Date(a.dateOfArrival) > new Date(b.dateOfArrival)) ? 1 : -1 )
}

function getPastVisits(visitslist){
    const today_0 = new Date().setHours(0,0,1);
    return visitslist.filter(item => (new Date(item.dateOfDeparture).setHours(23,59,59) < today_0
        ||  (item.checkedOut) || (item.rejectedByShop)))
        .map(item => {
            if (item.checkedOut){
                return {...item, divesublabel: "Checked out"}
            } else if (item.rejectedByShop){
                return {...item, divesublabel: `Declined with message: ${item.rejectMessage}`}
            } else {
                return item
            }
        })
    .sort((a, b) => (new Date(a.dateOfDeparture) > new Date(b.dateOfDeparture)) ? -1 : 1 )
}


export default function ShopDivers() {

  const { divers_or_staff } = useParams();

  const headers_template = [
    {label: "Accepted visits", key: "activevisits", checked: true },
    {label: "Open requests", key: "visitrequests", checked: false },
    {label: "Past visits", key: "pastvisits", checked: false }
  ]
  const today_0 = new Date().setHours(0,0,1);
  const [confirmShow, setConfirmShow] = useState(false);
  const [confirmCheckinDataShow, setConfirmCheckinDataShow] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("Thanks for reaching out. Unfortunately we are booked out, please call us for alternatives.");
  const history = useHistory();

  const [currentPage, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const value=false;
  const [diverForDetails, setDiverForDetails] = useState({});
  const [visitForDetails, setVisitForDetails] = useState({});

  const {myShop, setMyShop,
        shopVisits, setShopVisits,
        shopDivers, setShopDivers
        } = useShopContext();

  const [visitsList, setVisitsList] = useState([]);

  const [visitRequests, setVisitRequests ] = useState([]);
  const [activeDiverVisits, setActiveDiverVisits ] = useState([]);
  const [staffVisits, setStaffVisits] = useState([]);
  const [pastVisits, setPastVisits] = useState([]);

  const [headers, setHeaders] = useState(headers_template)

  const [menueId, setMenueId] = useState(0);

  function loadVisitInfo(uuid){
    function loadVisitInfoByVisitId(this_visit_id) {
            return API.get("visits","/diver/visits/"+this_visit_id, {
            });
    }
    async function onLoadVisit(this_uuid){
        try{
            const loaded_visitInfo = await loadVisitInfoByVisitId(this_uuid);
            if ("status" in loaded_visitInfo) {
                if (loaded_visitInfo["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_visitInfo;
        } catch (e) {
            onError(e);
        }
    }
    return onLoadVisit(uuid);
  }

  function loadDiverInfo(uuid){
        function loadDiverInfoByVisitId(visitId) {
          return API.get("divers", `/divecenters/diver/${visitId}`);
        }
        async function onLoadDiver(visitId) {
          try {
            const loaded_diver_dict = await loadDiverInfoByVisitId(visitId);
            if ("status" in loaded_diver_dict) {
                if (loaded_diver_dict["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_diver_dict;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadDiver(uuid);
    }

    function resetLists(visitslist){
        setActiveDiverVisits(getActiveVisits(visitslist, myShop));
        setVisitRequests(getVisitRequests(visitslist));
        setStaffVisits(getStaffVisits(visitslist, myShop));
        setPastVisits(getPastVisits(visitslist));
    }


  useEffect(() => {
     function resetLists(visitslist){
        setActiveDiverVisits(getActiveVisits(visitslist, myShop));
        setVisitRequests(getVisitRequests(visitslist));
        setStaffVisits(getStaffVisits(visitslist, myShop));
        setPastVisits(getPastVisits(visitslist));
    }
    async function onLoad() {
        setIsPageLoading(true)
      try {
        setVisitsList(getVisitsForList(shopVisits));
        resetLists(getVisitsForList(shopVisits));
      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false)
    }
    onLoad();
  }, [value, shopVisits, myShop]);

  function getQOpen(dateOfArrival){
    var diffDate = (new Date(dateOfArrival).setHours(0,0,0) - today_0) / (1000*60*60)
    if (diffDate < 48){
        return true
    }
    return false
  }






  async function navigateButton(next_or_previous) {

    if (currentPage === 0 && next_or_previous === "previous") {
        handleRedirectButton("/shop/main")
    } else if (currentPage === 1 && next_or_previous === "previous") {
        resetLists(visitsList)
        setPage(0)
  }
  }




  async function handleRedirectButton(target){
        history.push(target)
  }


  function addDiverToShopDivers(diverInfo){
        var newShopDivers = shopDivers;
        newShopDivers.push(diverInfo);
        setShopDivers(newShopDivers);
  }

  function updateVisitInShopVisits(visitInfo){
        var newShopVisits = shopVisits;
        const visitListId = newShopVisits.findIndex((item => item.key === visitInfo.key))
        newShopVisits[visitListId] = visitInfo
        setShopVisits(newShopVisits)
  }

  async function handleShowDiverInfo(key){
    setIsPageLoading(true)
    var diverInfo = {}
    const visitListId = visitsList.findIndex((item => item.key === key));
    const visitInfo = visitsList[visitListId]
    const diverInfoArray = shopDivers.filter(item => item.userId === visitInfo.userId)
    if (diverInfoArray.length > 0) {
        diverInfo = diverInfoArray[0]
    } else {
        try {
            // if diver is not in shopDivers, he may just have been added by shop, so we reload everything from db, required for questionnaires done, e.g.
            diverInfo = await loadDiverInfo(visitInfo["visitId"]);
            addDiverToShopDivers(diverInfo);
            const reload_visitInfo = await loadVisitInfo(visitInfo["visitId"])
            updateVisitInShopVisits(reload_visitInfo)
            visitsList[visitListId] = getVisitsForList([reload_visitInfo])[0]

        } catch (e) {
        onError(e);
      }
    }
    setVisitForDetails(visitsList[visitListId])
    setDiverForDetails(diverInfo);
    setPage(1);
    setIsPageLoading(false)
  }

  function handleHeader(id){
    const newList = headers.map((item, index) => {
        if (index === id) {
            const updatedItem = {...item, checked: true, };
            return updatedItem;
        } else {
            const updatedItem = {...item, checked: false, };
            return updatedItem;
        }
    })
    setHeaders(newList)
    setMenueId(id)
  }

  async function updateDCStaff(stafflist){
        const result = await API.put("divecenters", `/divecenters/${myShop.centerId}/update_staff`, {
            body: {"DCStaff": stafflist}
        });
        return result
    }

  function updateVisitAccept(centerId, visitId, accept){
        return API.put("visits", `/divecenters/${centerId}/visits/${visitId}/accept`, {
            body: {"acceptedByShop": accept}
        });
    }

  function updateVisitConfirmCheckinData(centerId, visitId, confirm){
        return API.put("visits", `/divecenters/${centerId}/visits/${visitId}/confirmCeckinData`, {
            body: {"checkinDataConfirmedByShop": confirm}
        });
    }

    function updateVisitReject(centerId, visitId, message){
        return API.put("visits", `/divecenters/${centerId}/visits/${visitId}/reject`, {
            body: {"acceptedByShop": false, "rejectedByShop": true, "rejectMessage": message}
        });
    }

  function updateVisitCheckout(centerId, visitId, newDate){
        return API.put("visits", `/divecenters/${centerId}/visits/${visitId}/checkout`, {
            body: {"dateOfDeparture": newDate, "checkedOut": true}
        });
    }

  async function handleAccept(){
    setIsLoading(true)
    // change db entry for visit with visitId stred in key
    await createPDF("CheckInDataSummary", { "filename": "checkin_summary.upload", "visitId": visitForDetails.key, "target_center_folder": true});
    await updateVisitAccept(myShop["centerId"], visitForDetails.key, true);

    visitForDetails["acceptedByShop"] = true
    setVisitForDetails(visitForDetails)

    const newShopVisits = shopVisits.map((item, index) => {
        if (item.visitId === visitForDetails.key) {
            const updatedItem = {...item, acceptedByShop: true, };
            return updatedItem;
          }
          return item;
        });
    setShopVisits(newShopVisits)
    setVisitsList(getVisitsForList(newShopVisits));
    resetLists(getVisitsForList(newShopVisits))
    setIsLoading(false)
  }

  async function handleConfirmCheckinData(){
    setIsLoading(true)

    visitForDetails["checkinDataConfirmedByShop"] = true
    setVisitForDetails(visitForDetails)

    const newShopVisits = shopVisits.map((item, index) => {
    if (item.visitId === visitForDetails.key) {
        const updatedItem = {...item, checkinDataConfirmedByShop: true, };
        return updatedItem;
      }
      return item;
    });
    setShopVisits(newShopVisits)
    setVisitsList(getVisitsForList(newShopVisits));
    resetLists(getVisitsForList(newShopVisits))

    await updateVisitConfirmCheckinData(myShop["centerId"], visitForDetails.key, true)
    setIsLoading(false)
    setConfirmCheckinDataShow(false)
  }

  async function handleReject(message){
        visitForDetails["rejectedByShop"] = true
        visitForDetails["rejectMessage"] = message

        const newShopVisits = shopVisits.map((item, index) => {
            if (item.visitId === visitForDetails.key) {
                const updatedItem = {...item, acceptedByShop: false,
                        rejectedByShop: true,
                        rejectMessage: message
                 };
                return updatedItem;
              }
              return item;
            });
        setShopVisits(newShopVisits)
        setVisitsList(getVisitsForList(newShopVisits));
        resetLists(getVisitsForList(newShopVisits))
        await updateVisitReject(myShop["centerId"], visitForDetails.key, message)
        setRejectShow(false)
  }

  async function handleCheckout(){
    // manually check out diver by placing date of visit to now in database and setting state of visit to active: false
    const visit=visitForDetails
    if (myShop.DCStaff.includes(visit.userId)) {
        handleAddRemoveStaff(visit.userId, "remove")
    }
    const newShopVisits = shopVisits.map((item, index) => {
        if (item.visitId === visit.visitId) {
            const updatedItem = {...item,
                dateOfDeparture: new Date().toISOString().slice(0,10),
                checkedOut: true};

            return updatedItem;
          }
          return item;
        });
    setShopVisits(newShopVisits)
    setVisitsList(getVisitsForList(newShopVisits));
    resetLists(getVisitsForList(newShopVisits))
    await updateVisitCheckout(myShop["centerId"], visit.visitId, new Date().toISOString().slice(0,10))  ;

    setPage(0)
  }

  function handleAddRemoveStaff(diverId, add_or_remove){
    setIsLoading(true)
    var newStaff = null
    var newStaffVisits = staffVisits;
    var new_is_staff = false
    if (add_or_remove === "add"){
        new_is_staff = true
        newStaff = myShop.DCStaff
        newStaff.push(diverId)
        newStaffVisits.push(
            activeDiverVisits.filter(item => (item.key === diverId))[0]
        )
    } else if (add_or_remove === "remove") {
        newStaff = myShop.DCStaff.filter(item => !(item === diverId))
        newStaffVisits = staffVisits.filter(item => !(item.key === diverId))

    }
    const newShopDivers = shopDivers.map((item, index) => {
        if (item.userId === diverId) {
            const updatedItem = {...item, is_staff: new_is_staff, };
            return updatedItem;
          }
          return item;
        });
    setDiverForDetails({...diverForDetails, is_staff: new_is_staff})
    setShopDivers(newShopDivers)
    var myNewShop = {...myShop, DCStaff: newStaff}
    setMyShop(myNewShop)
    updateDCStaff(newStaff)
    setStaffVisits(newStaffVisits)
    //resetLists(getVisitsForList(shopVisits))
    setIsLoading(false)
  }

  async function createUploadPDFSetShow() {
    console.log("creating pdf ")
    const centerId=visitForDetails["centerId"]
    const visitId=visitForDetails["visitId"]
    await createPDF("CheckInDataSummary", { "filename": "checkin_summary.upload", "visitId": visitForDetails.key, "target_center_folder": true});

    await API.put("visits", `/divecenters/${centerId}/visits/${visitId}/generateUploadCheckinSummary`, {
            body: {}
        });
    setConfirmCheckinDataShow(true);
  }

  function getCheckinSummaryFilename(){
      const now=  (new Date())
      now.setTime(now.getTime() - new Date().getTimezoneOffset()*60*1000);
      const now_data = now.toISOString().split('T')
      const dateString = now_data[0] +'-'+ now_data[1].slice(0,2)+'-'+ now_data[1].slice(3,5)+'-'+ now_data[1].slice(6,8)
      return `checkin_documents_${diverForDetails.firstName}_${diverForDetails.lastName}_${dateString}.pdf`
  }


  return (
    <LoadingOverlay
      active={isPageLoading}
      spinner
      text='Loading ...'
      >
    <div className="DiverEvents">


     {currentPage === 1110 ? <> </>: <> </>}

    {currentPage === 0 ? <>

       { divers_or_staff === "divers" ? <>
       <NavHeader prev={true}
        next={false}
        label="Divers"
        handler={navigateButton}/>

              <MenueHeader
                list={headers}
                changehandler={handleHeader}
              />


            { (visitsList.length === 0) ? <>
              <p className="text-center">You have no upcoming events yet</p>
             </>:<>

                { (menueId === 1) ? <>
                    Here we show visits that need to be approved
                    <ButtonListThreeCol mylist={visitRequests} changehandler={handleShowDiverInfo}/>
                </>:<></>}
                { (menueId === 0) ? <>
                    Here we show visits that are approved and active, i.e. within the dates
                    <ButtonListThreeCol mylist={activeDiverVisits} changehandler={handleShowDiverInfo}/>
                </>:<></>}
                { (menueId === 2) ? <>
                    Here we show past visits and divers you checked out manually.
                    <ButtonListThreeCol mylist={pastVisits} changehandler={handleShowDiverInfo}/>
                </>:<></>}

             </>
             }

        </>:
        <>

         <NavHeader prev={true}
            next={false}
            label="Staff"
            handler={navigateButton}/>
         <ButtonList mylist={staffVisits} changehandler={handleShowDiverInfo}/>
        </>}
        </>: <> </>}

    {currentPage === 1 ? <>
      <NavHeader prev={true}
        next={false}
        label="Diver Profile"
        handler={navigateButton}/>

        <Container fluid>
          <Row className="pl-3">
            <Col>
                       <Row className="pl-0">
                            <b>{visitForDetails.label} </b>
                        </Row>
                        <Row className="pl-0">
                            {visitForDetails.sublabel}
                        </Row>
                <Row>
                    {(visitForDetails.acceptedByShop) ? <>
                        {(visitForDetails.checkedOut) ? <>
                            <i>Checked out</i>
                        </>:<></>}
                    </>:<>
                        {(visitForDetails.rejectedByShop) ? <>
                            <i>Declined with message: {visitForDetails.rejectMessage}</i>
                        </>:<>
                            <i>Needs appoval</i>
                        </>}
                    </>}
                </Row>
            </Col>
           <Col className="text-right">
             <PhoneButton phone={diverForDetails.personalDetails.phoneNumber} className="squaresymbol ml-3"/>
             <EmailButton email={diverForDetails.personalDetails.email} />
          </Col>
          </Row>
        </Container>

        <ColoredLine className="scbline"/>

        <Container fluid>
            <Row className="pl-0 pb-3"><Col><b>Date of Arrival:</b></Col><Col>
                    {dateTimeView(visitForDetails.dateOfArrival, false)}
            </Col></Row>
            <Row className="pl-0 pb-3"><Col><b>{"Date of Departure: "}</b></Col><Col>
                {dateTimeView(visitForDetails.dateOfDeparture, false)}
            </Col></Row>
            <Row className="pl-3"><b>Message from {visitForDetails.label}: </b></Row>
            <Row className= "pl-5 pr-3">{visitForDetails.PM}</Row>
        </Container>

        <ColoredLine className="scbline"/>
         <DiverDataView
            diverDict={diverForDetails}
            visitId={visitForDetails.visitId}
            includeName={false}
        />

        <ColoredLine className="scbline"/>
        {!visitForDetails.rejectedByShop ? <>
            { !visitForDetails.acceptedByShop ? <>
                <LoaderButton
                        block
                        isLoading={isLoading}
                        onClick={(e) => handleAccept()
                        }> Confirm Dates </LoaderButton>
                <PopConfirmText
                        show={rejectShow}
                        setShow={setRejectShow}
                        textInput={{placeholder: "Thanks for reaching out. Unfortunately we are booked out, please call us for alternatives.",
                            value: rejectMessage,
                            setTextInput: setRejectMessage
                         }}
                        title="Confirm Rejection"
                        handleConfirm={handleReject}
                />
                <SCButton block onClick={(e) => setRejectShow(true)}> Reject request </SCButton>
            </>:<>
                { !visitForDetails["checkinDataConfirmedByShop"] ? <>
                    <PopConfirm
                        show={confirmCheckinDataShow}
                        setShow={setConfirmCheckinDataShow}
                        text="A pdf is being prepared and downloaded in a couple of seconds. Upon your confirmation, this summary will be stored for this visit and cannot be modified afterwards."
                        title="Checkin confirmation"
                        handleConfirm={handleConfirmCheckinData }
                        confirmText="Confirm Checkin Data"
                    />
                    <PSURLDownloadButton
                            block
                            isLoading={isLoading}
                            className="dl"
                            additionalInfoDict={ {preHandler: createUploadPDFSetShow} }
                            disabled={!visitForDetails["questionnairesDone"]}
                            download_spec={ {filename:`checkin_documents.pdf`, renameFileTo: getCheckinSummaryFilename(), visitId: visitForDetails["visitId"], target_center_folder: true}}
                       >
                                       {visitForDetails["questionnairesDone"]  ? <>Review Checkin Data </>:<> Questionnaires not yet available</> }
                    </PSURLDownloadButton>
                </>:<>
                    <PSURLDownloadButton
                            block
                            isLoading={isLoading}
                            className="dl"
                            additionalInfoDict={ {} }
                            download_spec={ {filename:`checkin_documents.pdf`, renameFileTo: getCheckinSummaryFilename(), visitId: visitForDetails["visitId"], target_center_folder: true}}
                       >
                                       View Checkin Data
                    </PSURLDownloadButton>
                    { !visitForDetails.checkedOut ? <>
                        {diverForDetails.is_staff ?
                            <LoaderButton
                                block
                                onClick={(e) => handleAddRemoveStaff(diverForDetails.userId, "remove")}
                                isLoading={isLoading}
                                > Remove {visitForDetails.label} from staff
                            </LoaderButton>
                            :
                            <LoaderButton
                                block
                                onClick={(e) => handleAddRemoveStaff(diverForDetails.userId, "add")}
                                isLoading={isLoading}
                                > Add {visitForDetails.label} to staff
                            </LoaderButton>
                        }
                        <PopConfirm
                            show={confirmShow}
                            setShow={setConfirmShow}
                            text="Please confirm checkout of Blabla. After checkout, a new registration by Blabla is required to participate in events."
                            title="Checkout confirmation"
                            handleConfirm={handleCheckout}
                            confirmText="Confirm"
                        />
                        <SCButton block onClick={(e) => setConfirmShow(true)}> Checkout </SCButton>
                    </>:<></> /*endif checked out*/}
                </> /*endif NOT confirmed checkinData by shop*/}
            </> /*endif NOT accepted / accepted*/}
        </>:<>
            <ColoredLine className="scbline"/>
        </> /*endif NOT rejected*/}

                {/*Invisible part to generate PDF from*/}
                <Row className="d-none">

                    <div id="CheckInDataSummary" style={{width: 1000}}>
                        <VisitViewForPDF visitForDetails={visitForDetails} diverForDetails={diverForDetails}/>
                    </div>
                </Row>
                {/* END invisible part for pdf summary */}


            { (visitForDetails.isShadow===true && visitForDetails.questionnairesDone === false) ? <>
                <div className="pt-3">
                <SCButton
                    block
                    disabled={!getQOpen(visitForDetails.dateOfArrival)}
                    onClick={(e) => history.push(`/diver/questionnaire/${visitForDetails.userId}/${visitForDetails.visitId}`)}
                > Go to questionnaires </SCButton>
                </div>
            </>:<>
            </>}
        </>:<></> /*endif page is 1*/}
    </div>
   </LoadingOverlay>
  );
}
