import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton, { PhoneButton, EmailButton} from "../components/LoaderButton";
import { ColoredLine } from "../components/Lists";
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import 'react-phone-number-input/style.css'
import Select from 'react-select';
import SCDatePicker from '../components/SCDatePicker'
/*import clone from '../components/MiscFunctions'*/
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useDiverContext } from "../lib/contextLib";
import DiverDataView from "./DiverDataView"
import NavHeader from "../components/Header"
import Footer from "../components/Footer"
import { dateTimeView} from "../components/Helpers"
import PopConfirm, {PopNotice, PopConfirmText} from "../components/Popups"
import {FiArrowRight} from "react-icons/fi"
import LoadingOverlay from 'react-loading-overlay';
import "../components/colors.css";
import {getVisitsForList} from "./ShopDivers";
import VisitViewForPDF from "./VisitView";
import {createPDF} from "../lib/pdfLib";
import PSURLDownloadButton from "../components/PSURLDownloadButton";
import {scubchiEmailShopId} from "../lib/constants";

function getQOpen(this_dateOfArrival){
    const today_0 = new Date().setHours(0,0,1);
    console.log(this_dateOfArrival)
    var diffDate = (new Date(this_dateOfArrival).setHours(0,0,0) - today_0) / (1000*60*60)
    if (diffDate < 48){
        return true;
    }
    //return false;
    return true;
}

export function getQReviewOpen(this_dateOfArrival){
    const today_0 = new Date().setHours(0,0,1);
    console.log(this_dateOfArrival)
    var diffDate = (new Date(this_dateOfArrival).setHours(0,0,0) - today_0) / (1000*60*60)
    if (diffDate < 48){
        return true;
    }
    return false;
}

export default function DiverSingleVisit() {
  const pageHeaders = {
    0: "Dive center",
    1: "Dates",
    2: "Personal message",
    3: "Review",
    4: "Medical I",
    5: "Medical II",
    6: "Participant statement",
    7: "Save Diving"
    };

  const [currentHeader, setHeader] = useState(pageHeaders[0])

  // get visitId (new for new visits or actual ID for reload of existing visits) and
  // predefinedCenterId (generic or actual centerId) so shop can send a link to a diver
  const { visitId, predefinedCenterId } = useParams();
  const {diverProfile, shopList} = useDiverContext();
  const [allVisitsList, setAllVisitslist] = useState([])
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [currentPage, setPage] = useState(0);
  const [confirmShow, setConfirmShow] = useState(false);
  const [confirmEmailShow, setConfirmEmailShow] = useState(false);
  const [showRedirectToProfile, setShowRedirectToProfile] = useState(false);
  const [showNoScubchiShop, setShowNoScubchiShop] = useState(false);
  const [noScubchiDCName, setNoScubchiDCName] = useState("")
  const [profileEvaluationText, setProfileEvaluationText]  = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dateOfArrival, setDateOfArrival] = useState(new Date(new Date().setHours(10,0,0)) );
  const [dateOfDeparture, setDateOfDeparture] = useState(new Date(new Date().setHours(10,0,0)) );
  const [chosenShopID, setChosenShopID] = useState(-1);
  const [pM, setPM] = useState("");
  const [useScubchiCenter, setUseScubchiCenter] = useState(true);
  const [centerEmail, setCenterEmail] = useState("");
  const [centerPhone, setCenterPhone] = useState("");
  const [centerName, setCenterName] = useState("");
  const [visitedPagesForInitErrors, setVisitedPages] = useState([]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [exposedBrevet, setExposedBrevet] = useState(0);
  const [optionalCLevelName, setOptionalCLevelName] = useState("None");

  const [shops4List, setShops4List] = useState([]);
  const [acceptedByShop, setAccepted] = useState(false);
  const [checkinDataConfirmedByShop, setCheckinConfirmed] = useState(false);
  const [rejectedByShop, setRejected] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [checkedOut, setCheckedOut] = useState(false);
  const [questionnairesDone, setQuestDone] =useState(false);
  const [questionnairesOpen, setQuestOpen] = useState(false);
  const [questionnairesReviewed, setQuestReviewed] = useState(false);
  const [medicalStatus, setMedicalStatus] = useState(0)
  const [showDateCollisionHint, setShowDateCollisionHint] = useState(false);
  const [dateCollisionHint, setDateCollisionHint] = useState("")
  const [pdfCreated, setPdfCreated] = useState(false);
  const [confirmCheckinDataShow, setConfirmCheckinDataShow] = useState(false);



  function getShopsForSelect(thisShopList){
    const this_list = [{label: "None", value: "-1"}];
    thisShopList.forEach(item => {
        this_list.push({label: item.DCName, value: item.centerId})
    })
    return(this_list)
  }

  function getQuestOpenDate(dateOfArrival){
    const openDate = new Date(dateOfArrival).setHours(10,0,0) - 48*1000*60*60
    return openDate
  }

  function updateQuestionnairesReviewed(){
        return API.put("visits", `/divecenters/${chosenShopID}/visits/${visitId}/questionnaires_reviewed`, {
            body: {"questionnairesReviewed": true}
        });
  }


  useEffect(() => {
    const today_0 = new Date().setHours(0,0,1);
      function loadVisitsInfo() {
          return API.get("visits", "/diver/visits");
      }
    function loadVisitInfo(this_visit_id) {
        return API.get("visits","/diver/visits/"+this_visit_id, {
        });
    }

    function loadCenter(this_centerId) {
        return API.get("scubchi",`/divecenters/${this_centerId}`, {
        });
    }

    async function onLoad() {
      setShops4List(getShopsForSelect(shopList));
      try {

        if (visitId === "new") {
            setErrors({
                "shopSelectInit": "InitialSelect"
              })
            var profText = ""
            if (diverProfile.scubaDetails.insuranceData.hasInsurance){
                const exp_date = new Date( diverProfile.scubaDetails.insuranceData.insuranceExpirationDate ).setHours(0,0,0)
                const diffdays = (exp_date - today_0) / (1000*60*60*24)

                if (diffdays < 0){
                    profText = "Your insurance expired. "
                    setShowRedirectToProfile(true)
                }
                else if (diffdays < 31){
                    profText = `Your insurance will expired in ${Math.round(diffdays)} days. `
                    setShowRedirectToProfile(true)
                }
            }
            if (!diverProfile.scubaDetails.medicalData.hasMedical){
                profText += "Please note that some dive centers require a medical certificate to dive."
                setShowRedirectToProfile(true)
            } else {
                const medicalDate = new Date( diverProfile.scubaDetails.medicalData.dateOfIssue ).setHours(0,0,0)
                const diffyears = (today_0 - medicalDate) / (1000*60*60*24*31)
                if (diffyears > 12) {
                    profText += "Your medical was issued more than a year ago. Please update your medical if required. "
                    setShowRedirectToProfile(true)
                }
            }
            setProfileEvaluationText(profText)

            // load all my visits in case there is an overlap

            try {
                const loaded_visits = await loadVisitsInfo();
                if ("status" in loaded_visits) {
                    if (loaded_visits["status"] === "notfound") {
                        return {};
                    }
                }
                setAllVisitslist(loaded_visits);
            } catch (e) {
                onError(e);
            }

            if (!(predefinedCenterId === "generic")){
                // validate that chosenShopID exists
                var center = {}
                try {
                    center = await loadCenter(predefinedCenterId)
                    console.log(center)
                } catch(e) {
                    onError(e);
                }
                setChosenShopID(predefinedCenterId)
                setCenterName(center["DCName"]);
                setCenterEmail(center["contactData"]["email"])
                setCenterPhone(center["contactData"]["phoneNumber"])
                setVisitedPages([0])
                setErrors({})
                setPage(1)
            }
        } else {
            setVisitedPages([0,1,2]) // for errors
            const loaded_visit = await loadVisitInfo(visitId);
            var loaded_dateOfArrival = new Date(loaded_visit["dateOfArrival"]+"T10:00:00Z");
            setDateOfArrival(loaded_dateOfArrival);
            var loaded_dateOfDeparture = new Date(loaded_visit["dateOfDeparture"]+"T10:00:00Z");
            setDateOfDeparture(loaded_dateOfDeparture);
            setPM(loaded_visit["PM"]);
            setChosenShopID(loaded_visit["centerId"]);
            if (loaded_visit["useScubchiCenter"] === false){
                setCenterName(loaded_visit["DCName"])
                setCenterEmail(loaded_visit["centerEmail"])
            } else {
                setCenterName(shopList.filter(item => item.centerId === loaded_visit["centerId"])[0]["DCName"]);
                setCenterEmail(shopList.filter(item => item.centerId === loaded_visit["centerId"])[0]["contactData"]["email"])
                setCenterPhone(shopList.filter(item => item.centerId === loaded_visit["centerId"])[0]["contactData"]["phoneNumber"])
            }
            var this_useScubchiCenter = true ;
            if (loaded_visit.hasOwnProperty("useScubchiCenter")){
                this_useScubchiCenter = loaded_visit["useScubchiCenter"] ;
            }
            setUseScubchiCenter(this_useScubchiCenter)
            setAccepted(loaded_visit["acceptedByShop"]);
            setCheckinConfirmed(loaded_visit["checkinDataConfirmedByShop"])
            setRejected(loaded_visit["rejectedByShop"]);
            setRejectMessage(loaded_visit["rejectMessage"]);
            setCheckedOut(loaded_visit["checkedOut"]);
            setQuestDone(loaded_visit["questionnairesDone"])
            const loaded_medicalStatus = loaded_visit["medicalStatus"] || 0 ;
            setMedicalStatus(loaded_medicalStatus);
            var this_reviewed = false
            if (loaded_visit.hasOwnProperty("questionnairesReviewed")){
                this_reviewed = loaded_visit["questionnairesReviewed"];
            }
            setQuestReviewed(this_reviewed);
            setQuestOpen(getQOpen(loaded_dateOfArrival));
            setPage(3)
            setHeader("Check-in overview ")
        }

        const diver_dict = diverProfile;
        setFirstName(diver_dict["firstName"]);
        setLastName(diver_dict["lastName"]);
        setExposedBrevet(diver_dict["exposedBrevet"]);
        setOptionalCLevelName(diver_dict["exposedOtherBrevetName"]);

      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false)
    }
    onLoad();

  }, [diverProfile, shopList, visitId, predefinedCenterId]);


    function getCenterByEmail(email) {
        return API.get("scubchi","/divecenters/bymail/"+email, {
        });
    }

  function checkDateCollision(){
      function setDateCollisionText(visit){
          const shopName = shopList.filter(item => item.centerId === visit["centerId"])[0]["DCName"];
          setDateCollisionHint(`This visit collides with a visit at ${shopName} from ${visit.dateOfArrival} to ${visit.dateOfDeparture}.`)
      }
      function getCollision(doA, this_doA, doD, this_doD){
          if (doD < this_doA || doA > this_doD){
              if (this_doD < doA || this_doA > doD) {
                  return false;
              }
          }
          return true;
      }
      const doA = new Date(dateOfArrival).setHours(0,0,1);
      const doD = new Date(dateOfDeparture).setHours(23,59,59);

      for (let i=0; i<allVisitsList.length; i++){
          const item = allVisitsList[i]
          const this_doA = new Date(item.dateOfArrival).setHours(23,59,59)
          const this_doD = new Date(item.dateOfDeparture).setHours(0,0,1)
          const this_collision = getCollision(doA, this_doA, doD, this_doD)
          if (this_collision === true){
              setShowDateCollisionHint(true);
              setDateCollisionText(item)
              return true;
          }
      }
      return false;
  }

  async function handleIgnoreDateCollision(){
      var newpage = currentPage + 1;
      setPage(newpage);
      setShowDateCollisionHint(false);
  }

  async function handleNextPreviousClick(next_or_previous) {
        console.log(useScubchiCenter)
    var newpage = currentPage;
    var tmp_visitedPages = visitedPagesForInitErrors;

    if (next_or_previous === "previous" && !(visitId === "new")){
        history.push("/diver/visits")
    }

    if (next_or_previous === "finish") {
        history.push("/diver/main")
    }

    if (next_or_previous === "next") {
        setIsLoading(true)
        if (currentPage === 0){
            if (useScubchiCenter===false){
                // try to get center via email. If exists: suggest to use this instead
                //substitute @ by AT and make all lower case
                const newmail = centerEmail.toLowerCase().replace('@', 'AT')
                console.log(newmail)
                const result = await getCenterByEmail(newmail)
                console.log(result)
                if (result ==="NotFound") {
                    setShowNoScubchiShop(true)
                    setUseScubchiCenter(false)
                } else {
                    setShopFromEmail(result)
                    setUseScubchiCenter(true)
                    //newpage += 1
                }
            } else {
                newpage += 1
            }
        } else if (currentPage === 1) {
            const dateCollides = checkDateCollision();
            if ((!dateCollides)) {
                newpage += 1 // if date is in conflict, newpage += 1 is set after pressing ok in the popup.
            }
            setQuestOpen(getQOpen(dateOfArrival));
        } else {
            newpage += 1;
        }
        setIsLoading(false)
    }
    else if (next_or_previous === "previous") {
        newpage -= 1;
    }

    // set page specific initial errors so next button is disabled until something is done
    var new_errors = errors;
    if (newpage === 2 && !visitedPagesForInitErrors.includes(2)) {
        new_errors["init"] = "init_error"
        setErrors(new_errors)
        tmp_visitedPages.push(2)
        setVisitedPages(tmp_visitedPages)
    }
    setPage(newpage)
    setHeader(pageHeaders[newpage])
  }

  async function handleCreateRedirect(whereto){
        var tmp_visitId = ""
        if (visitId === "new"){
            tmp_visitId = await createUpdateVisit()
        } else {
            tmp_visitId = visitId
        }
        if (whereto === "questionnaires"){
            history.replace(`/diver/visits/${tmp_visitId}/generic`)
            history.push(`/diver/questionnaire/generic/${tmp_visitId}`)

        } else if (whereto==="home"){
            history.push(`/diver/visits`)
        }

  }

  function handleCreateRedirectHome(){
    setConfirmShow(false)
    handleCreateRedirect("home")
  }

  function createVisitDict(){
      const registration_dict = {
          "content":{
              "firstName": firstName,
              "lastName": lastName,
              "exposedBrevet": exposedBrevet,
              "exposedOtherBrevetName": optionalCLevelName,
              "dateOfArrival": dateOfArrival.toISOString().slice(0,10),
              "dateOfDeparture": dateOfDeparture.toISOString().slice(0,10),
              "PM": pM,
              "acceptedByShop": acceptedByShop,
              "checkinDataConfirmedByShop": checkinDataConfirmedByShop,
              "active": false,
              "isShadow": false,
              "checkedOut": checkedOut,
              "rejectedByShop": rejectedByShop,
              "rejectMessage": rejectMessage,
              "questionnairesDone": questionnairesDone,
              "questionnairesReviewed": questionnairesReviewed,
              "useScubchiCenter": useScubchiCenter,
              "DCName": centerName,
              "centerEmail": centerEmail,
              "medicalStatus": medicalStatus
          }
      }
      return registration_dict;
  }

  async function createUpdateVisit(){
    var registration_return;
    setIsLoading(true);

    try {
        const registration_dict =  createVisitDict();
        registration_return = await createRegistration(registration_dict);

    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
    return registration_return["visitId"]
  }


  function createRegistration(registrationinfo) {
      return API.post("visits", `/diver/newvisit/${chosenShopID}` , {
      body: registrationinfo
    });
  }

  function validateForm() {
    return (Object.keys(errors).length === 0);
  }


  const verifyShopSelect = (value, errorkey, label) => {
    if (value === "-1" ) {
        errors[errorkey] = 'Chose '+label+' first';
    } else {
      delete errors[errorkey];
    }
  }

  function setShopFromEmail(centerFromEmail){
    setChosenShopID(centerFromEmail["centerId"])
    setUseScubchiCenter(true)
    setCenterName(centerFromEmail["DCName"])
    setCenterPhone(centerFromEmail["contactData"]["phoneNumber"])
  }

  function handleContinueFromEmail(){
    setCenterName(noScubchiDCName)
    setShowNoScubchiShop(false)
    setPage(1);
  }

  function handleShopSelect(value, errorkey, label) {
    delete errors["shopSelectInit"];
    delete errors["cE"];
    if (value === -1 || value === "-1"){
        setCenterEmail("")
        setCenterPhone("")
        setCenterName("")
    } else {
        setCenterEmail(shopList.filter(item => item.centerId === value)[0]["contactData"]["email"])
        setCenterPhone(shopList.filter(item => item.centerId === value)[0]["contactData"]["phoneNumber"])
        setCenterName(shopList.filter(item => item.centerId === value)[0]["DCName"])
        setUseScubchiCenter(true);
    }
    verifyShopSelect(value, errorkey, label);
    setChosenShopID(value);
  }

  const verifyString = (value, errorkey, label) => {
    delete errors["init"]
    if ( !value || value === '' ) {
        errors[errorkey] = label + ' cannot be blank!'
    }
    else if (value.length > 200) {
        errors[errorkey] = label + ' cannot be longer than 200 characters'
    }
    else {
      delete errors[errorkey];
    }
  }
  function setValidateString (setter, value, errorkey, label) {
    verifyString(value, errorkey, label);
    setter(value);
  }


  const verifyEmail = (value) => {
    if ( value === "" || (! value.includes("@")) ) {
        return false
    }
    else {
      return true
    }
  }

  function setValidateCenterEmail(value){
    delete errors["shopSelectInit"];
    setChosenShopID(scubchiEmailShopId);
    delete errors["shopSelect"]
    if (verifyEmail(value)===false){
        errors["cE"] = "Please enter a valid email address, or pick a dive center from the list"
    } else {
        delete errors["cE"]
    }
    setCenterName("")
    setCenterEmail(value)
    setUseScubchiCenter(false)
    //setDisableCenterFromEmail(false)
  }


  function handleDate(arr_or_dep, date){
        console.log(errors)
      console.log(date)
      console.log(dateOfArrival)
      console.log(dateOfDeparture)
      console.log(dateOfDeparture < dateOfArrival)
      console.log(new Date(date).setHours(23,0,0) < new Date(dateOfArrival).setHours(0,0,1))
      var newerrors = errors;
      date.setHours(11, 0, 0);
        if (arr_or_dep === "arrival"){
            setDateOfArrival(date)
            setDateOfDeparture(date)
        } else if (arr_or_dep === "departure"){
            setDateOfDeparture(date)
            if (new Date(date).setHours(23,0,0) < new Date(dateOfArrival).setHours(0,0,1)){
                newerrors["dateOfDeparture"] = "Date of departure cannot be earlier than date of arrival."
            } else {
                delete errors["dateOfDeparture"]
            }
        }
      setErrors(newerrors);
  }

  function get_nextdisabled(){
    if (currentPage === 3) {
        if (questionnairesDone === false && questionnairesOpen === true){
            return false
        }
        return true
    } else {
        return !validateForm()
    }

  }

  function handleRedirectToProfile(){
    history.push("/diver/profile/generic")
  }


  async function createUploadPDFSetShow() {
    console.log("creating pdf ")
    console.log(useScubchiCenter)
    const centerId=chosenShopID
    await createPDF("CheckInDataSummary", { "filename": "checkin_summary.upload", "visitId": visitId, "target_center_folder": true});
    await API.put("visits", `/divecenters/${centerId}/visits/${visitId}/generateUploadCheckinSummary`, {
        body: {}
    });
    setPdfCreated(true)
    setConfirmCheckinDataShow(true);
  }

  async function createUploadPDF() {
        const centerId=chosenShopID
        await createPDF("CheckInDataSummary", { "filename": "checkin_summary.upload", "visitId": visitId, "target_center_folder": true});
        await API.put("visits", `/divecenters/${centerId}/visits/${visitId}/generateUploadCheckinSummary`, {
            body: {}
        });
        setPdfCreated(true)
  }

  function getCheckinSummaryFilename(){
      const now=  (new Date())
      now.setTime(now.getTime() - new Date().getTimezoneOffset()*60*1000);
      const now_data = now.toISOString().split('T')
      const dateString = now_data[0] +'-'+ now_data[1].slice(0,2)+'-'+ now_data[1].slice(3,5)+'-'+ now_data[1].slice(6,8)
      return `checkin_documents_${centerName}_${dateString}.pdf`
  }

  async function handleSetReviewed(){
      if (getQReviewOpen(dateOfArrival) === true) {
          if (questionnairesReviewed === false) {
              setQuestReviewed(true);
              await updateQuestionnairesReviewed();
          }
      }
      setConfirmCheckinDataShow(false)
  }

  function getReviewText(){
      var text="A pdf is being prepared and downloaded in a couple of seconds. Please review carefully."
      if (getQReviewOpen(dateOfArrival) === false){
          text += " We will ask you again within 48h of arrival to finally confirm your data."
      }
      return text;
  }

  function getMailData(){
        const thisDict = {
            "centerEmail": centerEmail,
            "DCName": centerName,
            "firstName": firstName,
            "lastName": lastName,
            "diverEmail": diverProfile.personalDetails.email
        };
        console.log(thisDict)
        return thisDict;
  }

  async function handlePrepareSendEmail(){
      setIsLoading(true)
      if (pdfCreated === false){
        await createUploadPDF();
      }
    await API.post("scubchi", `/email/send/${visitId}`,{
        body: {content: getMailData()}
        })
        setIsLoading(false);    
    setConfirmEmailShow(true);    
  }

  function DiveCenterView(centerId){
      console.log(centerId)
      if (centerId === scubchiEmailShopId){
          return(<></>)
      }
      const thisShop = shopList.filter(item => item.centerId === centerId)[0]
      return(
        <div>
            <Container fluid>
                <Row>
                    <Col  className="fs20 text-center pl-0 pr-0 ml-0 mr-0">
                        {thisShop["DCName"]}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center pl-3 pr-3 ml-3 mr-3">
                        {thisShop["contactData"]["email"]}
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="text-center pl-3 pr-3 ml-3 mr-3">
                        {thisShop["contactData"]["phoneNumber"]}
                    </Col>
                </Row>

                <Row>
                    <Col className="text-center pl-3 pr-3 ml-3 mr-3">
                        {thisShop["contactData"]["address"]["addressLine"]}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center pl-3 pr-3 ml-3 mr-3">
                        {thisShop["contactData"]["address"]["ZIPCode"] + " " + thisShop["contactData"]["address"]["city"]}
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="text-center pl-3 pr-3 ml-3 mr-3">
                        {thisShop["contactData"]["address"]["country"]}
                    </Col>
                </Row>

            </Container>
        </div>
      )
  }

  return (
       <LoadingOverlay
              active={isPageLoading}
              spinner
              text='Loading ...'
              >
    <div className="pl-0 pr-0">

      <Form>

      <NavHeader prev={((!(predefinedCenterId==="generic") && currentPage === 1) || currentPage === 0 || currentPage === 3 ) ? false : true}
            next={ currentPage < 3 ? true : false }
            disabled={get_nextdisabled()}
            label={currentHeader}
            handler={handleNextPreviousClick}/>

        { (currentPage === 0) ? <>

            <PopConfirm
                    show={showRedirectToProfile}
                    setShow={setShowRedirectToProfile}
                    text={profileEvaluationText}
                    title="Notice"
                    handleConfirm={handleRedirectToProfile }
                    confirmText="Go to Profile"
                    cancelText="Continue anyways"
                />

            <PopConfirmText
                    show={showNoScubchiShop}
                    setShow={setShowNoScubchiShop}
                    text={`No dive center with this email (${centerEmail}) is registered with us so we cannot transfer your check-in data to this dive center automatically. 
                        At the end of this check-in, you can download your check-in data as pdf and send it to the dive center via email. \n\nPlease provide the name of the dive center to proceed with the checkin.`}
                    title="No dive center found"
                    textInput={{placeholder: "Dive center name",
                        value: noScubchiDCName,
                        setTextInput: setNoScubchiDCName
                    }}
                    handleConfirm={handleContinueFromEmail}
                    confirmText="Continue"
                    cancelText="Cancel"
                />

            <Form.Group className="pl-3 pr-3">
            Select a dive center:

                <Select options={shops4List} value={shops4List.filter(option => option.value === chosenShopID)} onChange={(e) => handleShopSelect(e.value, "shopSelect", "Dive center")} isDisabled={!(visitId==="new")}/>

            { errors.hasOwnProperty("shopSelect") ? <>
                <div className="pl-3 pr-3" style={{color: "red"}}>Please select a dive center</div>
            </>:<></>}
            </Form.Group>

            { (chosenShopID === "-1" || chosenShopID === -1 || useScubchiCenter === false) ? <></>:
                DiveCenterView(chosenShopID)
            }

            <Form.Group className="mb-3 pr-3 pl-3" controlId="formCE">
                <Form.Label>Or enter the email address of the dive center:</Form.Label>
                <Form.Control value={centerEmail}
                              type="string"
                              as="textarea"
                              rows="1"
                              placeholder="dive center email"
                              onChange={(e) => setValidateCenterEmail(e.target.value)}
                              isInvalid={ !!errors.cE }/>
                <Form.Control.Feedback type='invalid'>
                    { errors.cE }
                </Form.Control.Feedback>
            </Form.Group>
        </>:<></>}

        { (currentPage ===  1 ) ? <>

            <PopConfirm
                show={showDateCollisionHint}
                setShow={setShowDateCollisionHint}
                text={dateCollisionHint}
                title="Date collision"
                handleConfirm={handleIgnoreDateCollision }
                confirmText="Continue anyways"
                cancelText="Oops"
            />
            <Form.Group className="mb-3 pl-3 pr-3" controlId="formDates">
                <Form.Label>Please specify in which time period you would like to dive with us</Form.Label>
                <SCDatePicker
                    value={dateOfArrival}
                    onChange={value => handleDate("arrival", value)}
                    label="Arrival"
                    formatStyle="large"
                    locale="en-EN"
                />
                <SCDatePicker
                    value={dateOfDeparture}
                    onChange={value => handleDate("departure", value)}
                    label="Departure"
                    formatStyle="large"
                    locale="en-EN"
                    invalid={errors.hasOwnProperty("dateOfDeparture")}
                    error={errors["dateOfDeparture"]}
                />
            </Form.Group>
        </>:<></> /*endif page*/ }

        { (currentPage ===  2 ) ? <>
            <Form.Group className="mb-3 pr-3 pl-3" controlId="formPM">
                <Form.Label>Your message to {centerName}</Form.Label>
                <Form.Control value={pM}
                              type="string"
                              as="textarea"
                              rows="4"
                              placeholder="Tell us a bit about yourself, your diving experience and your stay, e.g. on which days you would like to dive / how many dives per day, or if you wish to take a course."
                              onChange={(e) => setValidateString(setPM, e.target.value, "pM", "Your personal message")}
                              isInvalid={ !!errors.pM }/>
                <Form.Control.Feedback type='invalid'>
                    { errors.pM }
                </Form.Control.Feedback>
            </Form.Group>
        </>:<></>
        }

        { (currentPage === 3)  ? <>
        <Container fluid>
          <Row className="pl-0">
                            { (visitId === "new") ? <>
                                Please carefully review your data submitted to
                            </>:<>
                                Your registration with
                             </>}
          </Row>
          <Row className="pl-3">
            <Col>
                <Row className="pl-0">
                    <b>{centerName} </b>
                </Row>
                <Row>
                  {(acceptedByShop) ? <>
                      {(checkedOut) ? <>
                        <i>Checked out by Dive Center</i>
                      </>:<>
                        <i>Visit confirmed by Dive Center</i>
                      </>}
                  </>:<>
                    {(rejectedByShop) ? <>
                        <i>Declined: {rejectMessage}</i>
                    </>:<>
                        <i>Request pending </i>
                    </>}
                  </>}
              </Row>
            </Col>
            <Col className="text-right">
                <EmailButton
                    email={centerEmail}
                />
                <PhoneButton disabled={!useScubchiCenter} phone={centerPhone} className="squaresymbol ml-3"/>
                {/*}<CancelIconButton onClick={(e) => handlePass}/>*/}
            </Col>
          </Row>
        </Container>

        <ColoredLine className="scbline"/>

        <Container fluid>
            <Row className="pl-0 pb-3">
                <Col><b>Date of Arrival:</b></Col>
                <Col>
                    {dateTimeView(dateOfArrival, false)}
                </Col>
            </Row>
            <Row className="pl-0 pb-3">
                <Col><b>{"Date of Departure: "}</b></Col>
                <Col>{dateTimeView(dateOfDeparture, false)}</Col>
            </Row>
            <Row className="pl-3"><b>Your message to {centerName}: </b></Row>
            <Row className= "pl-5 pr-3">{pM}</Row>
            <Row className="pl-0">
                <Col><b> Questionnaire status</b></Col>
                <Col>
                    { (questionnairesDone) ? <>
                        Processed
                     </>:<>
                        { (questionnairesOpen) ? <>
                            Please answer questionnaires
                        </>:<>
                            Questionnaires available from {dateTimeView( getQuestOpenDate( dateOfArrival), false)}
                        </>}
                     </>}
                </Col>
            </Row>
        </Container>

        <ColoredLine className="scbline"/>
        <DiverDataView
            diverDict={diverProfile}
            visitId={visitId}
            isDiver={true}
            includeName={false}
        />
        <Row className="d-none">
            <div id="CheckInDataSummary" style={{width: 1000}}>
                <VisitViewForPDF visitForDetails={getVisitsForList([createVisitDict()["content"]])[0]} diverForDetails={diverProfile}/>
            </div>
        </Row>

        { (questionnairesDone===false && questionnairesOpen === true) ? <>
            <Footer><LoaderButton
                  block
                  onClick={(e) => handleCreateRedirect("questionnaires")}
                  size="lg"
                  value="next"
                  variant="primary"
                  isLoading={isLoading}
                  //disabled={(questionnairesOpen === false)}
            >
                  Continue to Questionnaires
            </LoaderButton></Footer>
            </>:<>
                <ColoredLine className="scbline"/>
        </> /*endif questionnairesDone*/}


        { (questionnairesOpen === false && visitId === "new") ? <>
                <i> Your registration data will be submitted to dive center. Within 48h before arrival, please fill required questionnairs </i>
                <ColoredLine className="scbline"/>

                <PopNotice
                    show={confirmShow}
                    setShow={setConfirmShow}
                    text="Your registration data will be submitted to dive center. Within 48h before arrival, please fill required questionnairs "
                    title="Notice"
                    handleConfirm={handleCreateRedirectHome}
                    confirmText="OK"
                />
                <Footer><LoaderButton
                  block
                  onClick={(e) => setConfirmShow(true)}
                  size="lg"
                  value="next"
                  variant="primary"
                  isLoading={isLoading}
                  //disabled={(questionnairesOpen === false)}
                >
                   Submit
              </LoaderButton></Footer>
        </>:<></> /*endif visitId=== new but we cannot fill the things yet*/}

        { (questionnairesDone === true || (questionnairesOpen === false && questionnairesDone === false && !(visitId==="new"))) ? <>
            <PopConfirm
                show={confirmCheckinDataShow}
                setShow={setConfirmCheckinDataShow}
                text={getReviewText()}
                title="Check-in review"
                handleConfirm={() => {handleSetReviewed()} }
                cancelText="Re-do questionnaires"
                handleCancel={(e) => handleCreateRedirect("questionnaires")}
                confirmText="Confirm check-in documents"
            />
                <Footer spacerClassName="spac120">
                        <Row className="fullwidth pl-3">
                            <Col className="pr-0">
                                {/* (questionnairesReviewed ) ?
                                    <PSURLDownloadButton
                                        block
                                        size="lg"
                                        isLoading={isLoading}
                                        className="dl"
                                        additionalInfoDict={ {} }
                                        download_spec={ {filename:`checkin_documents.pdf`, visitId: visitId, target_center_folder: true}}
                                    >
                                        {(getQReviewOpen(dateOfArrival) && !(questionnairesReviewed)) ? "Download and review check-in documents" : "Download check-in documents"}
                                    </PSURLDownloadButton>
                                :*/}
                                    <PSURLDownloadButton
                                        block
                                        size="lg"
                                        isLoading={isLoading}
                                        className="dl"
                                        additionalInfoDict={ {preHandler: createUploadPDFSetShow} }
                                        download_spec={ {filename:`checkin_documents.pdf`, renameFileTo: getCheckinSummaryFilename(), visitId: visitId, target_center_folder: true}}
                                    >
                                        {(getQReviewOpen(dateOfArrival) && !(questionnairesReviewed)) ? "Download and review check-in documents" : "Download check-in documents"}
                                    </PSURLDownloadButton>
                            </Col>
                        </Row>

                    { !useScubchiCenter ?
                        <Row className="fullwidth pl-3 mt-2">
                            <Col className="pr-0">
                                <PopNotice
                                    show={confirmEmailShow}
                                    setShow={setConfirmEmailShow}
                                    text={`An email was sent to ${centerEmail} and cc'd to ${diverProfile.personalDetails.email}. Check your mailbox ;) `}
                                    title="Email"
                                    confirmText="OK"
                                />
                                <LoaderButton
                                    block
                                    size="lg"
                                    isLoading={isLoading}
                                    className="dl"
                                    onClick={(e) => handlePrepareSendEmail()}
                                >
                                    Email check-in data to dive center
                                </LoaderButton>
                            </Col>
                        </Row>
                    :<></>}
                    <Row className="fullwidth pl-3 mt-2">
                            <Col className="pr-0">
                        <LoaderButton
                          block
                          onClick={(e) => history.push("/diver/visits")}
                          size="lg"
                          value="next"
                          className="dl"
                          isLoading={isLoading}
                          //disabled={(questionnairesOpen === false)}
                        >
                           Back to my check-ins
                      </LoaderButton>
                            </Col>
                    </Row>
                </Footer>
        </>:<></> /*endif questionnairesDone*/}


        </>:<></> /*endif currentPage*/}

        { (currentPage < 7 && !(currentPage === 3) ) ? <>
        <Footer><LoaderButton
          block
          onClick={(e) => handleNextPreviousClick("next")}
          size="lg"
          value="next"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          <FiArrowRight size={22}/>
        </LoaderButton></Footer>
       </>:<>

        </>
       }
      </Form>
    </div>
   </LoadingOverlay>
  );
}
