import { useContext, createContext } from "react";

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export const ShopContext = createContext(null);

export function useShopContext() {
    return useContext(ShopContext);
}

export const DiverContext = createContext(null);

export function useDiverContext() {
    return useContext(DiverContext);
}

