import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import LoaderButton, { SCButton, SCSquareSymbolButton, EditButton} from "../components/LoaderButton";
import {FiArrowRight} from "react-icons/fi"
import { ExpandableDiversList, SelectableList, ColoredLine } from "../components/Lists";
import { SCDateTimePicker } from '../components/SCDatePicker'
import { getMainBrevets, getBrevetLabelFromId } from "../diverlib/brevets"
import NavHeader from "../components/Header"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { API } from "aws-amplify";
import { useShopContext } from "../lib/contextLib";
import { dateTimeLabel, dateTimeView} from "../components/Helpers"
//import {PopConfirmText} from "../components/Popups"
import "../components/Header.css"
import "./spacings.css"
import Footer from "../components/Footer"
import LoadingOverlay from 'react-loading-overlay';
import {RiPrinterLine} from 'react-icons/ri'
import { createPDFLocal } from "../lib/pdfLib";

export function getFullDiversList(diverlist){
    // create list for this event to handle divers, i.e. select for the event, add to a certain group, make ready and add tanknumber
    // input: list of diverProfiles
    var this_list = [];
    diverlist.forEach( (item, index) => {
            var brevetLabel;
            if (item.exposedBrevet === 100100) {
                brevetLabel = item.exposedOtherBrevetName;
            } else {
                brevetLabel = getBrevetLabelFromId(item.exposedBrevet, getMainBrevets())
            }
            this_list.push( {key: item.userId,
                list_id: index,
                label: item.firstName + " " + item.lastName,
                sublabel: brevetLabel,
                firstName: item.firstName,
                lastName: item.lastName,
                viewdetails: false,
                selected: false,
                is_staff: item.is_staff,
                eventGroup: item.eventGroup,
                ready: false,
                tankNumber: ""
            } );
        }
    );
    return this_list;
}

function filterBusy(diverlist, eventlist, datetime, eventId){
    function diverBusy(eventlist, userId){
        var busy = false;
        const participatingEvents = eventlist.filter(eventItem => (!(eventItem.eventId === eventId))).filter(eventItem => (eventItem.eventDivers.hasOwnProperty(userId) === true))
        for (let i=0; i<participatingEvents.length; i++){
            const this_datetime =  new Date(participatingEvents[i]["eventDate"])
            if ((Math.abs(datetime - this_datetime)/(1000*60*60) < 2)) {
                return true;
            }
        }
        return busy;
    }
    var outlist = [];
    diverlist.forEach( item => {
        if (diverBusy(eventlist,item.key) === false){
            outlist.push(item);
        }
    })
    return outlist;
}

function checkReady(divers){
    const not_ready_divers = divers.filter(item => (item.selected === true)).filter(item => (item.ready === false))
    if (not_ready_divers.length === 0){
        return true //
    } else {
        return false //
    }
}

export default function ShopEditEvent(){
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { eventIdLoad } = useParams();
    const { state } = useParams();
    const doLoad = useRef(true);
    const [eventId, setEventId] = useState(eventIdLoad);
    // is overwritten, if event is new and saved

    const [eventName, setEventName] = useState("New Event");
    const [eventGroups, setEventGroups] = useState([0]);
    const [groupForEdit, setGroupForEdit] = useState(0);
    const [nowSelected, setNowSelected] = useState([]);
    const [nowUnselected, setNowUnSelected] = useState([]);
    const {myShop, shopVisits,  shopDivers, shopEvents, setShopEvents
        } = useShopContext();

    const subMenueLabels = {
        0: eventName,
        1: "Staff",
        2: "Divers",
        3: "Event Overview",
    }
    function getMenuHeader(page){
        if (page < 3){
            return subMenueLabels[page]
        } else {
            return diveSpot
        }
    }

    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [eventReady, setEventReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [currentHeader, setCurrentHeader] = useState(subMenueLabels[currentPage]);
    const [eventDateTime, setEventDateTime] = useState(new Date());
    //const [groupData, setGroupData] = useState({}); // to store time when dive group entered / exited water
    const [diveSpot, setDiveSpot] = useState("");

    const [buttonMessage, setButtonMessage] = useState("");

    // full list of divers to chose from for this event
    const [availableDivers, setAvailableDivers] = useState([]);


  const centerId = myShop["centerId"];

  const [visited_pages, setVisitedPages] = useState([])

  function getHeaderNextTrueFalse(page){
      if (page === 3){
          return false;
      }
      return visited_pages.includes(page)
  }

  function getHeaderPreviousTrueFalse(page){
        if (page === 0 || page === 3) {
            return false
        }
        else {
            return true
        }
  }



  // ============ List helpers ===============

  function updateAvailableDivers(in_list, eventDiverDict){
      /* update registered staff or divers list (i.e. mark some of them) according to participantUuids
        in_list: list with all regsitered Divers or Staff
        uuids: list of uuids that are part of an event that come out of database
        output: list with all registered Divers or Staff with selected set to true if part of event
      */
      const out_list = [];
      var thisEventGroups = [0];
      const thisUuids = Object.keys(eventDiverDict);
      in_list.forEach( item => {
          const this_selected = (thisUuids.includes(item.key));
          var this_group = 0;
          var this_tankNumber = -1;
          var this_ready = false;
          if (this_selected){
              this_group = eventDiverDict[item.key]["eventGroup"];
              if (!thisEventGroups.includes(this_group)){
                  thisEventGroups.push(this_group)
              }
              this_tankNumber = eventDiverDict[item.key]["tankNumber"];
              this_ready = eventDiverDict[item.key]["ready"]
          }
        out_list.push( {...item, selected: this_selected, eventGroup: this_group, ready: this_ready, tankNumber: this_tankNumber } )
      })

      setEventGroups(thisEventGroups.sort(function(a, b) {
        return a - b;
      }))
      
      return out_list
  }

  function gatherEventGroups(eventDiverDict){
      console.log(eventDiverDict)
    var thisEventGroups = [0];
    const thisUuids = Object.keys(eventDiverDict);
    thisUuids.forEach(item => {
        const thisGroup = eventDiverDict[item]["eventGroup"]
        if (!thisEventGroups.includes(thisGroup)){
            thisEventGroups.push(thisGroup)
        }
    })
    setEventGroups(thisEventGroups.sort(function(a, b) {
        return a - b;
      }))
  }

  function setNewDiverDictFromList(inList){
    var tmp_diverDict = {}
    inList.forEach( item => {
        tmp_diverDict[item.key] = {"ready": item.ready, tankNumber: item.tankNumber,
            firstName: item.firstName, lastName: item.lastName, exposedBrevetLabel: item.sublabel, eventGroup: item.eventGroup, is_staff: item.is_staff}
    });
    return tmp_diverDict
  }
  // END =============== List Helpers ========================================

  // Create/Update of full event: Create staffUUIDs, diverUUIDs and diverDict with all keys in DB
  // Load: Load StaffUUIDs, diverUUIDs, update registeredX and generate XList from registeredX and diverDict
  // closing of expansion: update diverDict entry in DB, reload diverDict, reset registeredX and reset staffList diverList
  // Delete diver: - update diverList/staffList, -update




  function createEvent(eventinfo) {
    return API.post("events", `/events/${centerId}`, {
      body: eventinfo
    });
  }

  function updateEvent(eventinfo) {
    return API.post("events", `/events/${centerId}/${eventId}`, {
      body: eventinfo
    });
  }

  async function createDiverReg(inDiverDict, thisEventId){
    function createSingleDiverReg(diverId){
        return API.post("eventregistrations", `/eventregistrations/${thisEventId}/${diverId}`, {
            body: {}
    });
    }
    //ToDo: Only update
    const diverKeys = Object.keys(inDiverDict)
    for (let i=0; i<nowSelected.length; i++ ) {
        if (diverKeys.includes(nowSelected[i])){
            await createSingleDiverReg(nowSelected[i])
        }
    }
  }

  // functions below to update info in diverDict in table for specific diver (ready&tanknumber),
  // add new Diver to table, both Uuid and diverDict
  // remove Diver from table, both Uuid and diverDict
  function updateDiver(diverUuid, eventinfo, type = "diver"){
    return API.put("events", `/events/${centerId}/${eventId}/${type}/${diverUuid}/update`, {
      body: eventinfo
    });
  }

  /*function addDiver(diverUuid, eventinfo, type = "diver"){
    return API.put("events", `/events/${centerId}/${eventId}/${type}/${diverUuid}/add`, {
      body: eventinfo
    });
  }*/

  function removeDiver(diverUuid, eventinfo, type = "diver"){
      console.log("removing diver")
    return API.put("events", `/events/${centerId}/${eventId}/${type}/${diverUuid}/delete`, {
      body: eventinfo
    });
  }

  function removeDiverEventReg(diverUuid, eventId){
    console.log("removing diver evebt registration")
    return API.del("eventregistrations", `/eventregistrations/${eventId}/${diverUuid}`, {
      body: {}
    });
  }


  async function handleCreateEvent(ready=false){
    //Function for creating event, and updating event by shopOwner, i.e. NOT for getting ready, but changing name, destination, etc.
    setIsLoading(true)
    setIsPageLoading(true)
    // create diver Dict from staffList and diverList
    const tmp_diverDict = setNewDiverDictFromList(availableDivers.filter((item) => item.selected === true))
    var registration_return;

    const eventDict = {
      "content":{
          "eventDate": eventDateTime.toISOString(),
          "eventName": eventName,
          "eventDiveSite": diveSpot,
          "eventDivers": tmp_diverDict,
          //"groupData": groupData,
          "ready": ready,
          "diveDates": {}
        }
      }
    try {
      if (eventId === "new") {
          registration_return = await createEvent(eventDict);
          setEventId(registration_return.eventId)
          history.replace(`/shop/events/edit/${registration_return.eventId}/active`)
      } else
      {
        registration_return = await updateEvent(eventDict);
      }
      await createDiverReg(tmp_diverDict, registration_return.eventId);
    } catch (e) {
      onError(e);
      setIsLoading(false);
        setIsPageLoading(false);
    }
    setIsLoading(false);
      setIsPageLoading(false);
  }

  async function handleUpdateDiverInDict(diverKey, newDict){
    const eventDict = {
      "content": newDict
      }
    try {
        await updateDiver(diverKey, eventDict);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  /*async function handleAddDiverToDict(diverKey){
    const eventDict = {
      "content": {"tankNumber": "", "ready": false, "eventGroup": groupForEdit}
      }
    try {
        await addDiver(diverKey, eventDict);
        console.log("diver added")
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  }*/





  useEffect(() => {
    function setActiveDivers(){
        const today_0 = new Date().setHours(0,0,1);
        const today_24 = new Date().setHours(23,59,59);
        //thsi_diverList filters divers for listing. Make sure that no duplicates are in list in case somebody logged in twice
        var this_diverList = []
        shopDivers.forEach(item => {
            const these_visits = shopVisits.filter(sitem => sitem.userId === item.userId)
            these_visits.forEach( these_visits_item => {
                if (these_visits_item.acceptedByShop === true
                    && new Date(these_visits_item.dateOfArrival).setHours(0,0,1) < today_24
                    && these_visits_item.checkedOut === false
                    && new Date(these_visits_item.dateOfDeparture).setHours(23,59,59) > today_0 ){
                    if (this_diverList.filter(listitem => (listitem.userId === item.userId)).length === 0){
                        this_diverList.push(item)
                }
                }
            })
        })
        return this_diverList
    }



    function loadEventInfo(centerId, eventId) {
      return API.get("events", `/divecenters/${centerId}/events/${eventId}`, {
      });
    }

    function loadEventsInfo(centerId) {
      return API.get("events", `/divecenters/${centerId}/events`);
    }

    async function onLoad() {
        const this_diverList = getFullDiversList(setActiveDivers());
        //console.log(this_diverList);
        setAvailableDivers(this_diverList)
        if (shopEvents.length===0){
            const loaded_events = await loadEventsInfo(myShop["centerId"]);
            setShopEvents(loaded_events);
        }
        try {
            if (eventIdLoad === "new") {
                setErrors({
                    "init_errors": "InitialSelect"
                  })
                doLoad.current = false;
            } else {
                const centerId = myShop["centerId"]
                const loaded_event = await loadEventInfo(centerId, eventIdLoad);
                setDiveSpot(loaded_event["eventDiveSite"]);
                setEventName(loaded_event["eventName"]);
                setCurrentHeader(loaded_event["eventDiveSite"])
                var loaded_date = new Date(loaded_event["eventDate"]);
                setEventDateTime(loaded_date);
                // updated "selected" property of registeredDivers/Staff  to get those added to this event
                var tmp_divers = [];
                if ( state === "inactive" ){
                    // if event is past, simply show info from eventDict from DB
                    tmp_divers = Object.keys(loaded_event["eventDivers"]).map((item, index) => {
                        return {key: item,
                        label: loaded_event["eventDivers"][item]["firstName"] + " " + loaded_event["eventDivers"][item]["lastName"],
                        sublabel: loaded_event["eventDivers"][item]["exposedBrevetLabel"],
                        eventGroup: loaded_event["eventDivers"][item]["eventGroup"],
                        viewdetails: false,
                        selected: true,
                        list_id: index}
                    })
                    gatherEventGroups(loaded_event["eventDivers"])
                } else {
                    // if event is not past, update the available divers (i.e. mark as selected and add group) and setAvailableDivers
                    tmp_divers = updateAvailableDivers(this_diverList, loaded_event["eventDivers"])
                    setEventReady(checkReady(tmp_divers));
                }
                setAvailableDivers(tmp_divers);
                setCurrentPage(3)
                //doLoad.current = false;
            }
        } catch (e) {
            onError(e);
        }
          setIsPageLoading(false);
    }
    if (doLoad.current === true) {
        onLoad();
    }
  }, [state, myShop, shopDivers, shopVisits, shopEvents, setShopEvents, eventIdLoad]);


  const verifyString = (value, errorkey, label) => {
    if ( !value || value === '' ) {
        errors[errorkey] = label + ' cannot be blank!'
    }
    else if (value.length > 200) {
        errors[errorkey] = label + ' cannot be longer than 200 characters'
    }
    else {
      delete errors[errorkey];
    }
  }
  function setValidateString (setter, value, errorkey, label) {
    verifyString(value, errorkey, label);
    setter(value);
  }


  async function updateEventRegs(){
    for (let i=0; i<nowUnselected.length; i++){
        const item = nowUnselected[i];
        await removeDiverEventReg(item, eventId); 
    }
    setNowSelected([]);
    setNowUnSelected([]);
  }


  function handleSelectDiver(key){
    var thisUnSelected = nowUnselected;
      var thisSelected = nowSelected;  
    const newList = availableDivers.map((item, index) => {
          if (item.key === key) {
              if (item.selected === true){
                  thisUnSelected.push(key)
              } else if (item.selected === false ){
                  thisSelected.push(key)
              }
              const updatedItem = {...item, selected: !item.selected, eventGroup: groupForEdit};
              
              return updatedItem;
          }
          return item;
      });
      setAvailableDivers(newList);
      setNowUnSelected(thisUnSelected);
      setNowSelected(thisSelected);
  }

  function handleAddGroup(){
      const newGroupId = Math.max.apply(Math, eventGroups)+1;
      var newGroups = eventGroups;
      newGroups.push(newGroupId);
      setGroupForEdit(newGroupId);
      setEventGroups(newGroups.sort(function(a, b) {
        return a - b;
      }));
      navigateButton(1);
  }



  function handleExpandDivers(key) {
    if (eventId === "new"){
        //disable expansion for new event
    } else {
        const selectedDivers = availableDivers.filter(item => item.selected === true)

        var openDiverKey = "";
        const newList = selectedDivers.map((item, index) => {
            if (item.viewdetails === true) {
                openDiverKey = item.key
            }
            const collapsedItem = {...item, viewdetails: false, };
            return collapsedItem;
        })
        setAvailableDivers(newList)
        // if expand was just closed...
        if (!(openDiverKey === "")) {
            const thisOpenItem = newList.find(item => item.key === openDiverKey)
            const newDict={"tankNumber": thisOpenItem.tankNumber, "ready": thisOpenItem.ready, "eventGroup": thisOpenItem.eventGroup}
            handleUpdateDiverInDict(openDiverKey, newDict)
        }
        //third, in case this item here was just closed, update this one
        const thisItem = newList.find(item => item.key === key)
        if (thisItem.viewdetails === true){
            const newDict={"tankNumber": thisItem.tankNumber, "ready": thisItem.ready}
            handleUpdateDiverInDict(thisItem["key"], newDict)
        }
        handleExpand(key)
    }
  }


  function handleExpand(key){
    const newList = availableDivers.map((item, index) => {
      if (item.key === key || item.viewdetails === true ) {
        const updatedItem = {...item, viewdetails: !item.viewdetails, };
        return updatedItem;
      }
      return item;
    });
    setAvailableDivers(newList)
    setEventReady(checkReady(newList));
  }

  function handleTanksDivers(key, value) {
      const newList = availableDivers.map((item, index) => {
          if (item.key === key) {
              const updatedItem = {...item, tankNumber: value, };
              return(updatedItem);
          }
          return item;
      });
      setAvailableDivers(newList)
  }


  function handleReadyDivers(key) {
      const newList = availableDivers.map((item, index) => {
          if (item.key === key){
              const updatedItem = {...item, ready: !item.ready, };
              return updatedItem;
          }
          return item;
      });
      setAvailableDivers(newList)
  }


  async function handleRemoveDiverInDict(diverKey){
    const eventDict = {
      "content": {"tankNumber": "", "ready": false, "eventGroup": groupForEdit}
      }
    try {
        await removeDiver(diverKey, eventDict);
        await removeDiverEventReg(diverKey, eventId); 
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
    removeDiverFromDictLocal(diverKey);
  }

    function removeDiverFromDictLocal(key){
        const newList = availableDivers.map((item, index) => {
        if (item.key === key && item.selected === true) {
            const updatedItem = {...item, selected: !item.selected, };
            return updatedItem;
        }
            return item;
        });
        //setRegisteredDiversPutDict(newList);
        setAvailableDivers(newList);
    }



    async function navigateButton(next_or_previous){
        var newpage = currentPage;
        if (next_or_previous === "next") {
            if (currentPage === 0){
                if (eventId === "new"){
                    await handleCreateEvent();    
                }
            }
            if (currentPage === 2) {
                await handleCreateEvent();
               setEventReady(checkReady(availableDivers))
               updateEventRegs()
            }
            if (currentPage === 0 && previousPage === 3){
                newpage = 3;
            } else {
                newpage += 1;
            }
        } else if (next_or_previous === "previous") {
            if (currentPage === 3){
                history.goBack();
            } else {
                newpage -= 1;
            }
        } else {
            newpage = next_or_previous;
        }

        setCurrentHeader(getMenuHeader(newpage))

        var tmp_visited = visited_pages
        if (tmp_visited.includes(currentPage) === false) {
            tmp_visited.push(currentPage)
        }
        setVisitedPages(tmp_visited)
        setPreviousPage(currentPage);
        setCurrentPage(newpage);
    }

    function validateForm(){
        return false;
    }


    function handleEventGo() {
          setIsLoading(true)
        var newButtonMessage = ""
        var mystring = ""
        const not_ready_divers = availableDivers.filter(item => (item.selected === true)).filter(item => (item.ready === false))
        if (not_ready_divers.length > 0) {
            not_ready_divers.forEach(item => {
                mystring += `${item.label} `
            })
            mystring += "are not ready yet."
            newButtonMessage = mystring
        } else {
            newButtonMessage = " "
            handleCreateEvent(true)
            history.push("/shop/events")
        }
        setButtonMessage(newButtonMessage)
        setIsLoading(false)
    }


    function handleEditGroup(groupId){
        setGroupForEdit(groupId);
        navigateButton(1);
    }

    function handleSetEventDateTime(datetime){
        setEventDateTime(datetime)
    }

    function handlePrintEvent(){
        console.log(eventDateTime.toISOString())
        const filename = `DiveList_${eventName}_${dateTimeLabel(eventDateTime)}.pdf`
        createPDFLocal("DiveList", filename)
    }

function GroupView({groupId, diverlist, ...props}){
    return(

        <Container fluid className="pl-0">
            
            <Row className="pl-3">
                <Col sm={2}/>
                <Col sm={8}>
                    <p className="fs18 text-center">Group {groupId+1}</p>
                </Col>
                { props.noedit ? 
                    <></>:
                    <Col sm={2} className="text-right pr-3">
                    { (state === "active" ) ? <>
                        <EditButton
                            onClick={(e) => handleEditGroup(groupId)}
                            disabled={validateForm()}
                        />
                    </>:<></>}
                    </Col>
                }
                
            </Row>
            <ExpandableDiversList
                mylist={diverlist.filter(item => (item.selected === true && item.eventGroup === groupId))}
                expandhandler={handleExpandDivers}
                tankhandler={handleTanksDivers}
                readyhandler={handleReadyDivers}
                deletehandler={handleRemoveDiverInDict}
                active={(state === "active" ) ? true : false}
            />
            <ColoredLine className="scbline"/>
        </Container>
    )
}

return(
    <div className="ShopNewEvent">

        <LoadingOverlay
            active={isPageLoading}
            spinner
            text='Loading...'
        >

    <NavHeader prev={getHeaderPreviousTrueFalse(currentPage)}
        next={getHeaderNextTrueFalse(currentPage)}
        label={currentHeader}
        handler={navigateButton}/>
    { (currentPage === -1) ? <>

    </>:<></> }

    { (currentPage === 0) ? <>
        <Form.Group className="mb-3" controlId="formEventName">
            <Form.Label>Event Name</Form.Label>
            <Form.Control value={eventName}
                          type="string"
                          placeholder="Event Name"
                          onChange={(e) => setValidateString(setEventName, e.target.value, "eventName", "Event Name")}
                          isInvalid={ !!errors.eventName }/>
            <Form.Control.Feedback type='invalid'>
                { errors.eventName }
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDiveSpot">
            <Form.Label>Dive Spot</Form.Label>
            <Form.Control value={diveSpot}
                          type="string"
                          placeholder="Dive Spot"
                          onChange={(e) => setValidateString(setDiveSpot, e.target.value, "diveSpot", "Dive Spot")}
                          isInvalid={ !!errors.diveSpot }/>
            <Form.Control.Feedback type='invalid'>
                { errors.diveSpot }
            </Form.Control.Feedback>
        </Form.Group>
        <SCDateTimePicker
            value={eventDateTime}
            onChange={value => handleSetEventDateTime(value)}
            label="Date and time of the event"
            formatStyle="large"
            locale="US"
            hour24
        />
    </>:<></> }


    { (currentPage === 1) ? <>
        <SelectableList mylist={filterBusy(availableDivers, shopEvents, eventDateTime, eventId).filter(item => item.is_staff === true).filter(item => (item.selected === false || item.eventGroup === groupForEdit)).sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)} 
        changehandler={handleSelectDiver}/>
    </>:<></> }

    { (currentPage === 2) ? <>
        <SelectableList
            mylist={filterBusy(availableDivers, shopEvents, eventDateTime, eventId).filter(item => item.is_staff === false).filter(item => (item.selected === false || item.eventGroup === groupForEdit)).sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)}
            changehandler={handleSelectDiver}
        />
    </>:<></> }


{ (currentPage === 3) ? <>

   <Container fluid>
        <Row>
            <Col >
               <Row className="pl-3">
                    <b>{eventName}</b>
                </Row>
                <Row className="pl-3">
                    {dateTimeView(eventDateTime, true)}
               </Row>
            </Col>
            <Col className="text-right">
                <EditButton
                   onClick={(e) => navigateButton(0)}
                   disabled={(state === "active" ) ? false : true}
                   variant="primary"
                />
                {/*<PopConfirmText
                    show={cancelShow}
                    setShow={setCancelShow}
                    textInput={{placeholder: "Sorry, we had to cancel due to...",
                        value: cancelMessage,
                        setTextInput: setCancelMessage
                     }}
                    title="Confirm Cacellation"
                    handleConfirm={handleCancel}
                />
                <CancelIconButton
                    onClick={(e) => setCancelShow(true)}
                    />*/}
                    
                <SCSquareSymbolButton className="ml-3 pl-3 squaresymbol"
                    onClick={(e)=> handlePrintEvent()}>
                    <RiPrinterLine/>
                </SCSquareSymbolButton>
                
            </Col>
        </Row>
   </Container>


    <ColoredLine className="scbline"/>
    {eventGroups.map((groupId) => (
        <GroupView groupId={groupId} diverlist={availableDivers.filter(item => item.selected === true)} />)
    )}

    <Row className="d-none">
        <div id="DiveList" style={{width: 900}}>
            <Row>
                <Col className="fs22 text-center">
                    Dive List
                </Col>
            </Row>
            <Row>
                <Col >
                <Row className="pl-3">
                        <b>{eventName}</b>
                    </Row>
                    <Row className="pl-3">
                        {dateTimeView(eventDateTime, true)}
                </Row>
                </Col>
            </Row>    
        
        <ColoredLine className="scbline"/>
        {eventGroups.map((groupId) => (
            <GroupView groupId={groupId} diverlist={availableDivers.filter(item => item.selected === true)} noedit/>)
        )}
        </div>
    </Row>    

  </>:<></>
  }
  <Footer spacerClassName="spac160">
  { (currentPage === 0) ? <>
        <SCButton
              block
              onClick={(e) => navigateButton("next")}
              size="lg"
              variant="primary"
              value="next"
              isLoading={false}
              disabled={validateForm()}
            >
            <FiArrowRight size={22}/>
        </SCButton>
        </>:<>
        { (currentPage === 1) ? <>
            <SCButton
                block
              onClick={(e) => navigateButton("next")}
              size="lg"
              variant="primary"
              disabled={validateForm()}
            >
            { (availableDivers.filter(item => item.is_staff === true).filter(item => item.eventGroup === groupForEdit).filter(item => item.selected === true).length > 0) ? <>
                    Add to event
                </>:<>Skip</>
                }
        </SCButton>
        </>:<></> }
        { (currentPage === 2) ? <>
            <SCButton
                  onClick={(e) => navigateButton("next")}
                  size="lg"
                  block
                  variant="primary"
                  className = "addSelect"
                  disabled={validateForm()}
                >
                { (availableDivers.filter(item => item.is_staff === false).filter(item => item.eventGroup === groupForEdit).filter(item => item.selected === true).length > 0) ? <>
                    Add to event
                </>:<>Skip</>
                }
            </SCButton>
        </>:<></> }
        { (currentPage === 3) ? <>



                { (state === "active" ) ? <>
                    <LoaderButton
                        block
                        size="lg"
                        variant="primary"
                        onClick={(e) => handleAddGroup()}
                        isLoading={isLoading}
                        className = "scb"
                        disabled={validateForm()}
                    >
                        Add Group
                    </LoaderButton>
                    <div style={{color: "red"}}>{buttonMessage}</div>
                        <LoaderButton
                              block
                              size="lg"
                              variant="primary"
                              className={eventReady? "ready pt-2 mt-3" : "scb pt-2 mt-3" }
                              value="eventReadyToGo"
                              onClick={(e) => handleEventGo()}
                              isLoading={isLoading}
                              disabled={!eventReady}
                            >
                            Let's Go
                        </LoaderButton>
                </>:<></> }

            <LoaderButton
                block
                size="lg"
                variant="primary"
                value="eventDiverList"
                onClick={(e) => history.push("/shop/events")}
                isLoading={isLoading}
                className = "scb"
                disabled={validateForm()}
            >
                Back to Events
            </LoaderButton>

        </>:<></> }
     </> }
  </Footer>




        </LoadingOverlay>


    </div>
)

}