import { jsPDF } from "jspdf";
import { generate_presigned_upload_url_for_user } from "../lib/awsLib";
import { basicUpload } from "../lib/awsLib";

async function uploadPDFcallback(doc, uploadspec) {
  const pdf_buffer = doc.output('arraybuffer');
  var urldict = await generate_presigned_upload_url_for_user(uploadspec);
  const url = urldict["url"];
  await basicUpload(url, pdf_buffer, progressEvent => console.log(progressEvent));
}

export function createPDF(divId, uploadspec){
  var mypromise = new Promise(
       function(resolve, reject) {
         var doc = new jsPDF('p', 'pt', 'a4');
            doc.html(document.getElementById(divId), {
                'width': 170,
                callback: async function (doc) {
                    await uploadPDFcallback(doc, uploadspec);
                    resolve();
                },
                x: 50,
                y: 30,
                html2canvas: { scale: 0.5 }
       })
    }
  );
  return mypromise;
}


export function createPDFLocal(divId, filename="output.pdf"){
  var mypromise = new Promise(
       function(resolve, reject) {
         var doc = new jsPDF('p', 'pt', 'a4');
         doc.html(document.getElementById(divId), {
            callback: function () {
              doc.save(filename); 
            },
            'width': 170,
            x: 50,
            y: 50,
            html2canvas: { scale: 0.55 }
         });
      }
  );
  return mypromise;
}
