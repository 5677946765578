import { Storage } from "aws-amplify";
import { API } from "aws-amplify";
import axios from 'axios';


export async function getS3VaultFileName(fileName) {
  const url = await Storage.vault.get(fileName)
  return url;
}

export async function s3UploadToUserBucket(file_source, filename_to, progressCallback) {
  const stored = await Storage.vault.put(filename_to, file_source, {
    contentType: file_source.type,
    progressCallback: progressCallback,
  });

  return stored.key;
}

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  return stored.key;
}

export async function s3Delete(key) {
    await Storage.vault.remove(key)
}

export function generate_presigned_download_url(download_spec)
{
  /*
    sample upload_spec:
    filename :"filename",
    shadow_userId: "shadow_userId",
    visitId: "visitId",
    centerId: "centerId",
  */
  return API.post("scubchi", "/presigneddownloadurl", {
    body: {
       content : download_spec
    }
  });
}

export function generate_presigned_upload_url_for_user(upload_spec)
{
  /*
    sample upload_spec:
    filename :"filename",
    shadow_userId: "shadow_userId",
    visitId: "visitId",
    centerId: "centerId",
  */
  return API.post("scubchi", "/presigneduploadurl", {
    body: {
       content : upload_spec
    }
  });
}

export async function basicUpload(url, to_upload, progressCallback) {
    const config = {
        onUploadProgress: progressEvent => progressCallback(progressEvent),
        headers: {
            'Content-Type': 'application/octet-stream',
        }
    }
    await axios.put(url, to_upload, config)
}