
function clone(obj){
    var copy;
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export default clone;


export function dateTimeView(datestring, includeTime=true){
    const options={ weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
    const locale = "en-EN"
    const date = new Date(datestring).toLocaleDateString(locale, options)
    if (includeTime){
        const time = new Date(datestring).toTimeString().slice(0,8)
        return `${date}, ${time}`
    } else {
        return `${date}`
    }

    /*const this_date = new Date(datestring);
    const year = this_date.getFullYear();
    const month = this_date.getMonth();
    const date = this_date.getDate();
    const day = dayNameDict[this_date.getDate()];
    return `${day}, ${date}.${month}.${year}  `*/

}

export function dateTimeLabel(datestring, includeTime=true){
    
    const date = new Date(datestring).toISOString().slice(0,10) 
    if (includeTime){
        const time = new Date(datestring).toTimeString().slice(0,8)
        return `${date}_${time}`
    } else {
        return `${date}`
    }

    /*const this_date = new Date(datestring);
    const year = this_date.getFullYear();
    const month = this_date.getMonth();
    const date = this_date.getDate();
    const day = dayNameDict[this_date.getDate()];
    return `${day}, ${date}.${month}.${year}  `*/

}


export function dateView(datestring){


}