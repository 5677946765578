import React, { useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { generate_presigned_download_url } from "../lib/awsLib";
import "./PSURLDownloadButton.css"
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import "./LoaderButton.css"
import {FiDownload} from "react-icons/fi"

/*function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}*/

export default function PSURLDownloadButton({ download_spec,
  className = "",
  additionalInfoDict,
  ...props
}) {
  const my_download_spec = download_spec;
  const [isLoading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    if (additionalInfoDict.hasOwnProperty("preHandler")){
        await additionalInfoDict["preHandler"]()
    }
    var urldict = await generate_presigned_download_url(my_download_spec);
    const url = urldict["url"];
    setLoading(false);
    window.location.assign(url);
  }

  return (
    <Button
      disabled={isLoading || props.disabled}
      onClick={!isLoading ? handleClick : null}
      className={`${className}`}
      {...props}
    >
      {isLoading ? <BsArrowRepeat className="spinning" /> : props.children}
    </Button>
  );
}


export function PSURLDLButtonHint({download_spec,
    //onlyHint,
    className = "",
    hintText,
    ...props
}){
    return(
        <>
            {props.disabled ? <>
                <OverlayTrigger {...props} overlay={<Tooltip id="no-file">{hintText}</Tooltip>}>
                    <Button
                            disabled={props.disabled}
                            className={`${className}`}
                            {...props}
                            //className="dldisabled"
                        >
                            {props.children ? props.children : <FiDownload/> }
                    </Button>
                </OverlayTrigger>
            </>:<>
            {/*}<OverlayTrigger {...props} overlay={props.disabled ? <Tooltip id="no-file">{hintText}</Tooltip>:<></>} >
                <span className="d-inline-block">*/}
                <PSURLDownloadButton
                    download_spec={download_spec}
                    className={`${className}`}
                    additionalInfoDict={{}}
                    {...props}
                    >
                    {props.children ? props.children : <FiDownload/> }
                </PSURLDownloadButton>
                {/*}</span></OverlayTrigger>*/}
            </>}
        </>
    )
}

export function PSURLEmailButton({ download_spec,
        className = "",
        additionalInfoDict,
        ...props
    }) {
    const my_download_spec = download_spec;
    const [isLoading, setLoading] = useState(false);


    function getMailtoUrl(to, subject, body) {
        var args = [];
        if (typeof subject !== 'undefined') {
            args.push('subject=' + encodeURIComponent(subject));
        }
        if (typeof body !== 'undefined') {
            args.push('body=' + encodeURIComponent(body))
        }

        var url = 'mailto:' + encodeURIComponent(to);
        if (args.length > 0) {
            url += '?' + args.join('&');
        }
        return url;
    }


    function getMailHrefString(url){
        var mailString = "mailto:"
        if (additionalInfoDict.hasOwnProperty("mailData")){
            const mailData = additionalInfoDict["mailData"];
            const email = mailData["email"] ||"";
            const DCName = mailData["DCName"] ||"";
            const firstName = mailData["firstName"] ||"";
            const lastName = mailData["lastName"] ||"";
            const subject= `Scuba Check-In Data from ${firstName} ${lastName}`
            const body = `Dear ${DCName}, \n\n Please click on the link below to download my check-in documents(pdf) from Scubchi. The download link is valid for 5 days. \n\n Regards, \n ${firstName} ${lastName} \n\n ${url} \n\n Visit us!\nwww.scubchi.com\napp.scubchi.com`
            mailString = getMailtoUrl(email, subject, body)
        }
        return mailString
    }

    async function handleClick(){
        setLoading(true);
        if (additionalInfoDict.hasOwnProperty("preHandler")){
            await additionalInfoDict["preHandler"]()
        }
        var urldict = await generate_presigned_download_url(my_download_spec);
        const url = urldict["url"];
        setLoading(false);
        window.location.href = getMailHrefString(url) //"mailto:mail@example.org?body="+url+"&subject=test"
    }

    return(
        /*<OverlayTrigger
            trigger='click'
            overlay={showCopiedHint ? <Tooltip id="no-file">Copied to Clipboard</Tooltip> : <></>}>*/
            <Button
                disabled={isLoading || props.disabled}
                onClick={!isLoading ? handleClick : null}
                className={`${className}`}
                {...props}
            >
                {isLoading ? <BsArrowRepeat className="spinning" /> : props.children}
            </Button>
    /*</OverlayTrigger>*/
    )

}