import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from "react-bootstrap/Form";
import { ColoredLine } from "../components/Lists";

import {
     questionnaireIntro_1,
    questionnaireIntro_2,
    questionnaireSubIntro,
    questionnaireNoteWomen,
    questionnaireOutro,
     questionnaireStatement,
     questionnaireSignature,
     questionnairePostSignatureNote
} from "../diverlib/dummylists"
export default function pass(){
}
const today = new Date()

export function YesNoList({mylist}){
    return(
        <div>
            <Container fluid>
                {mylist.map((item, index) => (

                    <Row className="pb-3">
                        <Col sm={8}>
                            {item.label}
                        </Col>
                        <Col sm={2} className="text-right"> {(item.checked === true) ? <>yes</>:<></>} </Col>
                        <Col sm={2} className="text-right"> {(item.checked === true) ? <></>:<>no</>} </Col>
                    </Row>
                ))}
            </Container>
        </div>
    )
}

export function HQ1ForPDF({...props}){
    return(
        <div>
            <Form.Group className="mb-3 pl-3 pr-3" controlId="healthQ1">
                <Form.Label><b>General health questionnaire for Recreational Diving</b></Form.Label>
                <Container fluid>
                    <Row>{questionnaireIntro_1}</Row>
                    <Row>{questionnaireIntro_2}</Row>
                    <Row><Col className="text-center">
                      <b>Instructions</b>
                    </Col></Row>
                    <Row>{questionnaireSubIntro}</Row>
                    <Row>{questionnaireNoteWomen}</Row>
                    <ColoredLine/>
                        <YesNoList mylist={props.mylist}/>
                    <ColoredLine/>
                </Container>
            </Form.Group>
            <Container fluid className="pl-3 pr-3">
                <Row><Col className="text-center">
                      <b>Signed by the participant</b>
                    </Col></Row>
                 <Row>{questionnaireOutro}</Row><br/>
                 <Row>{questionnaireStatement}</Row><br/>
                 <Row>{questionnaireSignature}</Row><br/>
                <Row><b>Date: </b>  {today.toISOString().slice(0,10)}</Row>
                <Row className="pb-3">
                    <Col><b>Name of Participant: </b>{props.myName}</Col>
                    <Col><b>Date of birth: </b>{props.dateOfBirth}</Col>
                </Row>
                <Row className="pb-3 pt-3">
                    <Col><b>Name of Instructor: </b></Col>
                    <Col><b>Name of Divecenter: </b>{props.shopName}</Col>
                </Row>
                <Row className="pt-3"> {questionnairePostSignatureNote} </Row>
            </Container>
        </div>
    )
}

