import { useHistory } from "react-router-dom";
import { ColoredLine } from "../components/Lists";
import { getSpecialties, getMainBrevets, getBrevetLabelFromId } from "../diverlib/brevets"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {EditButton} from "../components/LoaderButton";
import {PSURLDLButtonHint} from "../components/PSURLDownloadButton";
import { dateTimeView} from "../components/Helpers"

export default function DiverDataView({diverDict, visitId, includeName=true, isDiver = false}) {
  const brevetDict = getMainBrevets();
  const specialtiesDict = getSpecialties();
  const history = useHistory();

  function getBrevetDownloadInfoDict(brevet) {
    var brevetDownloadInfoDict = {
       "filename" : `${brevet}.jpg`,
       "target_center_folder": false
    };
    if (!(visitId==="new" || visitId === "generic")){
        if (isDiver === false){
            brevetDownloadInfoDict["visitId"]=visitId;
        }
    }
    console.log(brevetDownloadInfoDict)
    return brevetDownloadInfoDict;
  }
  const frontback = ["front", "back"]
  return (
    <div>
        <Container fluid>

          { includeName ? <>
              <Row className="pb-3">
                <Col><b>First name:</b></Col>
                <Col>
                    <Row className="pb-0">
                        <Col>{diverDict.firstName}</Col>
                        <Col sm={2} className="text-right">
                           {(visitId === "new") ? <></>:
                                <EditButton
                                   onClick={(e) => history.push(`/diver/profile/${visitId}`)}
                                   disabled={visitId === "new"}
                                   variant="primary"
                                /> /*endif visitId === new*/}
                        </Col>
                     </Row>
                </Col>
              </Row>

            <Row className="pb-3"><Col><b>Last name:</b></Col><Col>{diverDict.lastName}</Col></Row>

          </>:<></> /*endif includeName*/}
          <Row className="pb-3"><Col><b>Phone:</b></Col><Col>{diverDict.personalDetails.phoneNumber}</Col></Row>
          <Row className="pb-3"><Col><b>Email:</b></Col><Col>{diverDict.personalDetails.email}</Col></Row>
          <Row><Col><b>Address:</b></Col><Col>
            <Row className="pl-3 pb-1">{diverDict.personalDetails.address.addressLine}</Row>
            <Row className="pl-3 pb-1">{diverDict.personalDetails.address.ZIPCode + " " + diverDict.personalDetails.address.city}</Row>
            <Row className="pl-3 pb-1">{diverDict.personalDetails.address.country}</Row>
          </Col></Row>

        </Container>

        <ColoredLine className="scbline"/>
        {diverDict.personalDetails.contactPerson.provideEmergencyContact ?<>
            <Row className="pb-3 pl-3"><Col><b>Emergency Contact</b></Col><Col></Col></Row>
            <Row className="pb-3 pl-3"><Col><b>First name:</b></Col><Col>{diverDict.personalDetails.contactPerson.firstName}</Col></Row>
            <Row className="pb-3 pl-3"><Col><b>Last name:</b></Col><Col>{diverDict.personalDetails.contactPerson.lastName}</Col></Row>
            <Row className="pb-3 pl-3"><Col><b>Phone:</b></Col><Col>{diverDict.personalDetails.contactPerson.phoneNumber}</Col></Row>
            <ColoredLine className="scbline"/>
        </>:<></>}
        { diverDict.scubaDetails.isCertified ? <>
            <Container fluid>
                <Row  className="pb-1">
                    <Col><b>C-level:</b></Col>
                    <Col>
                        {frontback.map( item => (
                        <Row>
                            <Col><Row className="pb-4 pl-3">{getBrevetLabelFromId(diverDict.exposedBrevet, brevetDict) + " " + item}</Row></Col>
                            <Col className="text-right">
                                <PSURLDLButtonHint
                                    disabled={!diverDict.scubaDetails.mainBrevets[diverDict.exposedBrevet]["fileProvided"][item]}
                                    hintText="No file uploaded"
                                    className="dl"
                                    download_spec={getBrevetDownloadInfoDict(`${diverDict.exposedBrevet}_${item}`)}
                                    />
                            </Col>
                        </Row>
                        ))}
                    </Col>
                </Row>
                <Row className="pb-3"><Col><b>Certificate number:</b></Col>
                    <Col>{diverDict.exposedBrevetId}</Col>
                </Row>
                <Row><Col><b>Specialties:</b></Col><Col>
                    {Object.keys(diverDict.scubaDetails.specialtyBrevets).map((key, index) => (
                        frontback.map( item => (
                        <Row className="pb-1" key={key}>
                            <Col><Row className="pb-4 pl-3">{getBrevetLabelFromId(key, specialtiesDict)+ " "+ item }</Row></Col>
                            <Col className="text-right">
                                <PSURLDLButtonHint
                                    disabled={!diverDict.scubaDetails.specialtyBrevets[key]["fileProvided"][item]}
                                    hintText="No file uploaded"
                                    className="dl"
                                    download_spec={getBrevetDownloadInfoDict(`${key}_${item}`)}
                                />
                            </Col>
                        </Row>
                        ))
                    ))
                    }
                </Col></Row>
                <Row className="pb-4"><Col><b>Number of dives:</b></Col><Col>{diverDict.scubaDetails.diveRecord.numberOfDives}</Col></Row>
                <Row className="pb-4"><Col><b>Date of last dive:</b></Col><Col>
                   {dateTimeView(diverDict.scubaDetails.diveRecord.dateOfLastDive, false)}
                </Col></Row>

                <Row  className="pb-1">
                    <Col><b>Insurance</b></Col>
                    <Col>
                        <Row>
                        <Col><Row className="pb-4 pl-3">{ diverDict.scubaDetails.insuranceData.hasInsurance ? <>{diverDict.scubaDetails.insuranceData.insuranceProvider}</>:<>No</>   }</Row></Col>

                        <Col className="text-right">
                             <PSURLDLButtonHint
                                    hintText="No file uploaded"
                                    disabled={!diverDict.scubaDetails.insuranceData["fileProvided"] || !diverDict.scubaDetails.insuranceData["hasInsurance"]}
                                    className="dl"
                                    download_spec={ getBrevetDownloadInfoDict("insurance")}
                                    />
                        </Col>
                        </Row>
                        { diverDict.scubaDetails.insuranceData.hasInsurance ? <>
                            <Row className="pb-4 pl-3"> {diverDict.scubaDetails.insuranceData.insuranceId}</Row>
                            <Row className="pb-4 pl-3"> Expires {dateTimeView(diverDict.scubaDetails.insuranceData.insuranceExpirationDate, false)}</Row>
                            </>:<></>}
                    </Col>
                </Row>

                <Row  className="pb-3">
                    <Col><b>Medical</b></Col>
                    <Col>
                        <Row>
                        <Col>
                            { diverDict.scubaDetails.medicalData.hasMedical ?
                                <> {diverDict.scubaDetails.medicalData.medicalExpirationDate ? "Expires " + dateTimeView(diverDict.scubaDetails.medicalData.medicalExpirationDate, false) : ""}
                                </>
                                :<>None</>   }
                        </Col>
                        <Col className="text-right">
                              <PSURLDLButtonHint
                                    //onlyHint={!diverProfile.scubaDetails.medicalData["fileProvided"]}
                                    hintText="No file uploaded"
                                    disabled={!diverDict.scubaDetails.medicalData["fileProvided"] || !diverDict.scubaDetails.medicalData["hasMedical"]}
                                    className="dl"
                                    download_spec={getBrevetDownloadInfoDict("medical")}
                                    />
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>:<></> /*endif is certified*/}
    </div>


  );
}
