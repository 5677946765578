import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ColoredLine } from "../components/Lists";
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import { useShopContext } from "../lib/contextLib";

import SCDatePicker from '../components/SCDatePicker'
import Form from "react-bootstrap/Form";
import NavHeader from "../components/Header"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoaderButton, {EditButton} from "../components/LoaderButton";
import { dateTimeView} from "../components/Helpers"
import {FiArrowRight} from "react-icons/fi"
import { PopNotice } from "../components/Popups"
import DiverDataView from "./DiverDataView"
import LoadingOverlay from 'react-loading-overlay';
import Footer from "../components/Footer"


  /* DUPLICATED FROM DIVER SINGLE VISIT */
function updateRegistration(registrationinfo, this_diverUuid, this_visitId, this_shop) {
    return API.put("visits", `/divecenters/${this_shop["centerId"]}/visits/${this_visitId}/${this_diverUuid}` , {
      body: registrationinfo
    });
}

  /* DUPLICATED FROM DIVER SINGLE VISIT */
function createRegistration(registrationinfo, this_diverUuid, this_shop) {
      return API.post("visits", `/divecenter/${this_shop["centerId"]}/newvisit/${this_diverUuid}` , {
      body: registrationinfo
    });
}


async function createUpdateVisit(this_visitDict, visitId, myShop){
    var registration_return = {};
    const this_diverUuid = this_visitDict["userId"]
    delete this_visitDict["userId"]
    const registration_dict = {
          "content": this_visitDict
    }
    //setIsLoading(true);

    try {
        if (visitId === "new") {
          registration_return = await createRegistration(registration_dict, this_diverUuid, myShop);
        } else {

            registration_return = await updateRegistration(registration_dict, this_diverUuid, visitId, myShop);

        }
    } catch (e) {
      onError(e);
      //setIsLoading(false);
    }
    //setIsLoading(false);
    return registration_return["visitId"]
}


function getUpdatedVisitsList(shopVisits,this_visitDict, this_visitId){

        if (this_visitId==="new"){
            console.log("Could not add visit to context, as not all data is available")
        } else {
            var newShopVisits = []
            for (let i = 0; i<shopVisits.length; i++) {
                if (!(shopVisits[i].visitId === this_visitId)){
                    newShopVisits.push(shopVisits[i])
                }
            }
            newShopVisits.push({...this_visitDict, "visitId": this_visitId})
            return newShopVisits;
        }
}


export default function ShopShadowVisit(event_info){

    const { visitId } = useParams();
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [visitedPagesForInitErrors, setVisitedPages] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [confirmShow, setConfirmShow] = useState(false);
    const {myShop} = useShopContext();
    const shopContextRef = useRef(useShopContext());
    // visit related quantities
    const checkinDataConfirmedByShop = false;
    const rejectedByShop = false;
    const rejectMessage= "None";
    const pM="Shop Checkin";
    const checkedOut = false;

    const [dateOfArrival, setDateOfArrival] = useState(new Date() );
    const [dateOfDeparture, setDateOfDeparture] = useState(new Date() );

    const [diverDict, setDiverDict] = useState({});
    const [diverUuid, setDiverUuid] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [exposedBrevet, setExposedBrevet] = useState(-1);
    const [optionalCLevelName, setOptionalCLevelName] = useState("None");
    //const [chosenShopID, setChosenShopID] = useState(-1);
    const [questionnairesDone, setQuestDone] = useState(false);
    const [questionnairesOpen, setQuestOpen] = useState(false);
    // page related variables
    const pageHeaders = {
        0: "Dates",
        1: "Review"
        }
    const [currentHeader, setHeader] = useState(pageHeaders[0])

    function loadDiverInfoById(uuid){
        function loadDiverInfo(uuid) {
          return API.get("divers", `/divecenters/diver/${uuid}`);
        }

        async function onLoadDiver(uuid) {

          try {
            const loaded_diver_dict = await loadDiverInfo(uuid);
            if ("status" in loaded_diver_dict) {
                if (loaded_diver_dict["status"] === "notfound") {
                    return {};
                }
            }

            return loaded_diver_dict;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadDiver(uuid);
    }

    useEffect(() => {
        console.log("(re)loading")

        function getQOpen(this_dateOfArrival){
            var diffDate = (new Date(this_dateOfArrival).setHours(0,0,0)  - new Date().setHours(0,0,1)) / (1000*60*60)
            console.log(diffDate)
            if (diffDate < 48){
                return true
            }
            return false
        }

        function loadVisitInfo(this_visit_id) {
            return API.get("visits","/diver/visits/"+this_visit_id, {
            });
        }
        async function onLoad(){
            const visit_dict = await onLoadDiverVisit();
            if (!(visitId === "new")){
                await createUpdateVisit(visit_dict, visitId, shopContextRef.current.myShop);
                console.log("updateShopVisits")
                const newShopVisits = getUpdatedVisitsList(shopContextRef.current.shopVisits,visit_dict, visitId)
                shopContextRef.current.setShopVisits(newShopVisits)
            }
        }

        function getVisitDictFromLoad(loaded_visit, diver_dict){
            var loaded_dateOfArrival = new Date(loaded_visit["dateOfArrival"]+"T10:00:00Z");
            var loaded_dateOfDeparture = new Date(loaded_visit["dateOfDeparture"]+"T10:00:00Z");
            const registration_dict = {
                  "userId": loaded_visit["userId"],
                  "firstName": diver_dict["firstName"],
                  "lastName": diver_dict["lastName"],
                  "exposedBrevet": diver_dict["exposedBrevet"],
                  "exposedOtherBrevetName": diver_dict["exposedOtherBrevetName"],
                  "dateOfArrival": loaded_dateOfArrival.toISOString().slice(0,10),
                  "dateOfDeparture": loaded_dateOfDeparture.toISOString().slice(0,10),
                  "PM": "Shop Checkin",
                  "acceptedByShop": true,
                  "useScubchiCenter": true,
                  "checkinDataConfirmedByShop": false,
                  "isShadow": true,
                  "active": false,
                  "checkedOut": false,
                  "rejectedByShop": false,
                  "rejectMessage": "None",
                  "questionnairesDone": loaded_visit["questionnairesDone"]
              }
              return registration_dict
        }

        async function onLoadDiverVisit() {
          var visit_dict = {};
          try {

            if (visitId === "new") {
                setErrors({
                  })
            } else {
                // load visit and corresponding diver
                const loaded_visit = await loadVisitInfo(visitId);
                var loaded_dateOfArrival = new Date(loaded_visit["dateOfArrival"]+"T10:00:00Z");
                setDateOfArrival(loaded_dateOfArrival);
                var loaded_dateOfDeparture = new Date(loaded_visit["dateOfDeparture"]+"T10:00:00Z");
                setDateOfDeparture(loaded_dateOfDeparture);
                setQuestDone(loaded_visit["questionnairesDone"])
                setQuestOpen(getQOpen(loaded_dateOfArrival));
                const this_diverUuid = loaded_visit["userId"];
                setDiverUuid(this_diverUuid);
                const diver_dict = await loadDiverInfoById(loaded_visit["visitId"]);
                setDiverDict(diver_dict)
                await setFirstName(diver_dict["firstName"])
                setLastName(diver_dict["lastName"])
                setExposedBrevet(diver_dict["exposedBrevet"])
                setOptionalCLevelName(diver_dict["exposedOtherBrevetName"])
                setCurrentPage(1)
                setHeader("Review")

                visit_dict = getVisitDictFromLoad(loaded_visit, diver_dict)
            }
          } catch (e) {
            onError(e);
          }
          setIsPageLoading(false);
          return visit_dict;
        }
    onLoad();
    }, [visitId]);







  function createShadowDiver(diverinfo) {
    return API.post("divers", `/divecenters/shadowdiver/${myShop["centerId"]}`, {
      body: diverinfo
    });
  }

  async function handleCreateShadowDiverVisitRedirect(){
    setIsLoading(true)
    const new_diver_dict = { "content": {
            "firstName": "",
            "lastName": "",
            "exposedBrevet": 0,
            "exposedOtherBrevetName": "",
            "acceptedTermsVersion": -1,
             "personalDetails": {
                 "address": {
                     "addressLine": "",
                     "city": "",
                     "ZIPCode":  1000,
                     "country":  ""
                 },
                 "dateOfBirth": new Date().toISOString().slice(0, 10),
                 "email": "",
                 "phoneNumber": "",
                 "contactPerson": {
                      "provideEmergencyContact": true,
                      "firstName": "",
                      "lastName": "",
                      "phoneNumber": ""
                 }
             },
             "scubaDetails": {
                 "isCertified": false,
                 "mainBrevets": {},
                 "specialtyBrevets": {},
                 "diveRecord": {
                    "numberOfDives": 0,
                    "dateOfLastDive": new Date().toISOString().slice(0, 10)
                 },
                 "insuranceData": {
                    "hasInsurance": false,
                    "fileProvided": false,
                    "insuranceProvider": "",
                    "insuranceId": "",
                    "insuranceExpirationDate": new Date().toISOString().slice(0, 10)
                    },
                 "medicalData": {
                    "hasMedical": false,
                    "dateOfIssue": new Date().toISOString().slice(0, 10),
                    "fileProvided": false
                    }
             }
        }
    }
    const this_diverUuid = await createShadowDiver(new_diver_dict)

    setDiverUuid(this_diverUuid)
    //create visit, get visitId
    const this_visitDict = getVisitDictFromLocalVariables(this_diverUuid)
    const this_visitId = await createUpdateVisit(this_visitDict, visitId, myShop);
    //history.push onto the same page so history.back after DiverInfo Update will go back to this visit

    setIsLoading(false)
    history.push( `/shop/visit/${this_visitId}` );
    history.push(`/diver/profile/${this_visitId}` );
  }

  function getVisitDictFromLocalVariables(this_diverUuid){
    const local_visitDict =  {
                  "userId": this_diverUuid,
                  "firstName": firstName,
                  "lastName": lastName,
                  "exposedBrevet": exposedBrevet,
                  "exposedOtherBrevetName": optionalCLevelName,
                  "dateOfArrival": dateOfArrival.toISOString().slice(0,10),
                  "dateOfDeparture": dateOfDeparture.toISOString().slice(0,10),
                  "PM": pM,
                  "acceptedByShop": true,
                  "useScubchiCenter": true,
                  "checkinDataConfirmedByShop": checkinDataConfirmedByShop,
                  "isShadow": true,
                  "active": false,
                  "checkedOut": checkedOut,
                  "rejectedByShop": rejectedByShop,
                  "rejectMessage": rejectMessage,
                  "questionnairesDone": questionnairesDone
              }
    return local_visitDict;
    }





  async function handleCreateRedirect(whereto){
        if (whereto === "questionnaires"){
            //history.replace(`/diver/visits/${tmp_visitId}`)
            history.push(`/diver/questionnaire/${diverUuid}/${visitId}`)
        } else if (whereto==="home"){
            history.push(`/shop/main`)
        }
  }

  function handleCreateRedirectHome(){
    setConfirmShow(false)
    handleCreateRedirect("home")
  }

  async function handleNextPreviousClick(next_or_previous) {
        var tmp_visitedPages = visitedPagesForInitErrors;
        var newPage = currentPage;
        if (next_or_previous === "next"){
            newPage += 1
            if (currentPage === 1) {
                createUpdateVisit(getVisitDictFromLocalVariables(diverUuid), visitId, myShop)
            }

        } else if (next_or_previous === "next_or_previous") {
            newPage -= 1
        }
        if (newPage < 0) {
            newPage = 0
        }

        if (!(visitedPagesForInitErrors.includes(newPage))){
            tmp_visitedPages.push(newPage)
            setVisitedPages(tmp_visitedPages)
            setErrors({"init": "init_error"})
        }
        setHeader(pageHeaders[newPage])
        setCurrentPage(newPage)
    }


    function handleDate(setter, date){
        date.setHours(11, 0, 0);
        setter(date);
    }


  function validateForm() {
   /* Example: https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2 */
    return (Object.keys(errors).length === 0);
  }

  function get_nextdisabled(){
        return !validateForm()
  }

    return(

         <LoadingOverlay
              active={isPageLoading}
              spinner
              text='Loading Visit...'
              >
      <div>
      <Form>

      <NavHeader prev={currentPage > 0 ? true : false}
            next={true}
            disabled={get_nextdisabled()}
            label={currentHeader}
            handler={handleNextPreviousClick}/>


      { (currentPage ===  0 ) ? <>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formDates">
            <SCDatePicker
                value={dateOfArrival}
                onChange={value => handleDate(setDateOfArrival, value)}
                label="Arrival"
                formatStyle="large"
                locale="en-EN"
            />
            <SCDatePicker
                value={dateOfDeparture}
                onChange={value => handleDate(setDateOfDeparture, value)}
                label="Departure"
                formatStyle="large"
                locale="en-EN"
            />
        </Form.Group>

        <Footer>

            <LoaderButton
                  block
                  onClick={(e) => { (visitId === "new") ? handleCreateShadowDiverVisitRedirect() : handleNextPreviousClick("next")}}
                  size="lg"
                  value="next"
                  variant="primary"
                  isLoading={isLoading}
                  disabled={!validateForm()}
            >
                  <FiArrowRight size={22}/>
            </LoaderButton>
        </Footer>
        </>:<></>}
      { (currentPage ===  1 ) ? <>
        <Container fluid>
            <Row className="pl-0 pb-3">
                <Col><b>Date of Arrival:</b></Col>
                <Col>
                    <Row>
                               <Col> {dateTimeView(dateOfArrival, false)} </Col>
                           <Col sm={2} className="text-right">
                               <EditButton
                                   onClick={(e) => setCurrentPage(0)}
                                   disabled={false}
                                   variant="primary"
                                />
                            </Col>
                    </Row>
                </Col>

            </Row>

            <Row className="pl-0 pb-3">
                <Col><b>{"Date of Departure: "}</b></Col>
                <Col>{dateTimeView(dateOfDeparture, false)}</Col>

            </Row>

        </Container>

        <ColoredLine className="scbline"/>
                <DiverDataView
            diverDict={diverDict}
            visitId={visitId}
        />


        <Footer>

        { (questionnairesDone===false && questionnairesOpen === true) ? <>
            <LoaderButton
                  block
                  onClick={(e) => handleCreateRedirect("questionnaires")}
                  size="lg"
                  value="next"
                  variant="primary"
                  isLoading={isLoading}
            >
                  Continue to Questionnaires
            </LoaderButton>
            </>:<>
                <ColoredLine className="scbline"/>
        </> /*endif questionnairesDone*/}


        { (questionnairesOpen === false && questionnairesDone === false) ? <>
                <i> Your registration data will be submitted to dive center. Within 48h before arrival, please fill required questionnairs </i>
                <ColoredLine className="scbline"/>

                <PopNotice
                    show={confirmShow}
                    setShow={setConfirmShow}
                    text="Your registration data will be saved. Within 48h before arrival, please fill required questionnairs "
                    title="Notice"
                    handleConfirm={handleCreateRedirectHome}
                    confirmText="OK"
                />
                <LoaderButton
                  block
                  onClick={(e) => setConfirmShow(true)}
                  size="lg"
                  value="next"
                  variant="primary"
                  isLoading={isLoading}
                  //disabled={(questionnairesOpen === false)}
                >
                   Save
              </LoaderButton>
        </>:<></> /*endif visitId=== new but we cannot fill the things yet*/}

       </Footer>
      </>:<></> /*endif page 1*/ }

      </Form>

    </div>
    </LoadingOverlay>
    )
}