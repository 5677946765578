import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import Button from "react-bootstrap/Button";
import {StaticScubchiText} from "../components/Header"
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Signup.css";
import { Auth } from "aws-amplify";
import {PopNotice} from "../components/Popups"
export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptDataPolicy, setAcceptDataPolicy] = useState(false);
  const [reenterConfirmation, setReenterConfirmation] = useState(false);
  const [showConfirmationCodeSent, setShowConfirmationCodeSent] = useState(false);
  const [validated, setValidated] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      acceptDataPolicy &&
      acceptTerms
    );
  }



  function validateReenterConfirmationCode(){
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.confirmationCode.length > 0
    );
  }

  function validateConfirmationForm() {
    return (fields.confirmationCode.length > 0
    );
  }

  async function handleSubmit(event) {
    console.log("in handle submit")
    console.log(fields.password)
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false){
        event.stopPropagation();
        setValidated(true);
    } else{
        setIsLoading(true);
        try {
          const newUser = await Auth.signUp({
            username: fields.email,
            password: fields.password,
          });
          setShowConfirmationCodeSent(true)
          setIsLoading(false);
          setNewUser(newUser);
          setValidated(false);
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
    }
  }

  async function handleResendConfirmationCode() {
    try {
        await Auth.resendSignUp(fields.email);
        setShowConfirmationCodeSent(true)
        console.log(`code resent successfully to ${fields.email}`);
    } catch (err) {
        console.log('error resending code: ', err);
        alert(err)
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false){
        event.stopPropagation();
        setValidated(true);
    } else{
        try {
          await Auth.confirmSignUp(fields.email, fields.confirmationCode);
          await Auth.signIn(fields.email, fields.password);
          userHasAuthenticated(true);
          setValidated(false);
          history.push("/");
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
    }
  }

  function handleSetAcceptTerms(){
    setAcceptTerms(!acceptTerms)
  }

  function handleSetAcceptDataPolicy(){
    setAcceptDataPolicy(!acceptDataPolicy)
  }

  function renderConfirmationForm() {
    return (
      <Form noValidate validated={validated} onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            pattern="^[0-9]+$"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Control.Feedback type='invalid'>
             Code pattern is invalid: only digits and no spaces!
          </Form.Control.Feedback>
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
        <LoaderButton
          block
          size="lg"
          variant="primary"
          onClick = {() => handleResendConfirmationCode()}
          isLoading={isLoading}
          disabled={false}
        >
            Request new validation code
        </LoaderButton>
      </Form>
    );
  }

  function renderForm() {
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?/()§=])([a-zA-Z0-9-+_!@#$%^/()§=&*.,?]+){8,}$"
            onChange={handleFieldChange}
          />
          <Form.Control.Feedback type='invalid'>
             Incorrect password pattern: 8 characters, must contain at least 1 capital and 1 small case letter, a digit & 1 special character (-+_!@#$%^&*.,?/()§=). No "ä, ö, ü" ;)
          </Form.Control.Feedback>
          <Form.Text muted>Min. 8 characters, 1 capital and 1 small case letter, a digit & 1 special character.</Form.Text>
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            isInvalid={!(fields.confirmPassword === fields.password)}
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
          <Form.Control.Feedback type='invalid'>
             Passwords do not match
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="accTerms" size="lg">
            <Form.Check
                type = {"checkbox"}
                checked = {acceptTerms}
                onChange = {handleSetAcceptTerms}
                label = {<>In signing up, I agree to the <a href="http://www.scubchi.com/termsandconditions" target="_blank" rel="noreferrer">Terms and Conditions</a> of Scubchi </>}
                isInvalid = {false}
            />
        </Form.Group>
        <Form.Group controlId="accDataPolicy" size="lg">
        <Form.Check
                type = {"checkbox"}
                checked = {acceptDataPolicy}
                onChange = {handleSetAcceptDataPolicy}
                label = {<>In signing up, I agree to the <a href="http://www.scubchi.com/datapolicy" target="_blank" rel="noreferrer">Privacy and Data Policy</a> of Scubchi </>}
                isInvalid = {false}
            />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
        <p className="pt-3">Already signed up and ready to enter your validation code?</p>
        <Button
          block
          bsSize="small"
          variant="link"
          onClick={() => setReenterConfirmation(true)}
          isLoading={isLoading}
          disabled={false}
        >
          Enter Validation Code
        </Button>
      </Form>
    );
  }

  function renderReenterConfirmationCode(){
    return(
     <Form noValidate validated={validated} onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            pattern="^[0-9]+$"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Control.Feedback type='invalid'>
             Code pattern is invalid: only digits and no spaces!
          </Form.Control.Feedback>
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateReenterConfirmationCode()}
        >
            Verify
        </LoaderButton>
        <LoaderButton
          block
          size="lg"
          variant="primary"
          onClick = {() => handleResendConfirmationCode()}
          isLoading={isLoading}
          disabled={false}
        >
            Request new validation code
        </LoaderButton>
     </Form>
   );
  }

  return (
    <div className="Signup">
        <PopNotice
                show={showConfirmationCodeSent}
                setShow={setShowConfirmationCodeSent}
                text={`A confirmation code was sent to ${fields.email}`}
                title="Confirmation Code"
                handleConfirm={() => setShowConfirmationCodeSent(false)}
                confirmText="Ok"
        />
      <StaticScubchiText>
        Welcome to
      </StaticScubchiText>
      { (newUser === null && reenterConfirmation === false) ?
         renderForm()
      : <></>}
      { (newUser === null && reenterConfirmation === true) ?
            renderReenterConfirmationCode()
       : <></>}

      {!(newUser === null) ?

      renderConfirmationForm()
       : <></>}
    </div>
  );
}
