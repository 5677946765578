import React, { useState, useEffect } from "react";
import LoaderButton, { SCButton} from "../components/LoaderButton";
import { useDiverContext, useShopContext, useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import "./DiverInfo.css";
import "./spacings.css";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import PopConfirm , {PopNotice} from "../components/Popups"
import LoadingOverlay from 'react-loading-overlay';
export default function DiverMain() {
  const history = useHistory();

  //const [isLoading, setIsLoading] = useState(false);
  const {setExpanded} = useAppContext();
  const isLoading = false;
  const [confirmShow, setConfirmShow] = useState(false);
  const [reviewShow, setReviewShow] = useState(false);
  const [medicalShow, setMedicalShow] = useState(false);
  //const [diver_dict, setDiverDict] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  const {diverProfile, setDiverProfile, shopList, setShopList} = useDiverContext();
  const {setMyShop, setShopVisits,setShopDivers} = useShopContext();
  const [visitForQ, setVisitForQ] = useState({});
  const [shopName, setShopNameForQ] = useState("");
  const [visitForQReview, setVisitForQReview] = useState({});
  const [shopNameForReview, setShopNameForQReview] = useState("");
  const [visitForMedicalReminder, setVisitMedicalReminder] = useState({});


  function handleRedirectVisit(visitId){
      history.push(`/diver/visits/${visitId}/generic`)
  }

  function updateMedicalStatus(mS, shopId, visitId){
        return API.put("visits", `/divecenters/${shopId}/visits/${visitId}/medical_status`, {
            body: {"medicalStatus": mS}
        });
  }


    useEffect(() => {
    var diver_dict = {}
    var shop_list = []
    const today_0 = new Date().setHours(0,0,0);

    function getActiveFutureVisits(visitslist) {
        const tmp_visits = visitslist.filter(item => (
            new Date(item.dateOfDeparture).setHours(23, 59, 0) >= today_0
            && !(item.checkedOut)))
        return tmp_visits;
    }

    // get visits that have open questionnaires that need to be filled
    function getVisitIdForQ(visits) {
        for (var i=0; i<visits.length; i++){
            const item=visits[i]
            const dOA = new Date(item.dateOfArrival).setHours(0,0,0)
            if (((dOA - today_0)/(1000*60*60) < 48) && (item.questionnairesDone === false)) {
                return item
            }
        }
        return "None"
    }

    // get visits that need to be reviewed, if all data is still correct
    function getVisitIdForQReview(visits) {
          for (var i=0; i<visits.length; i++){
              const item=visits[i]
              var this_reviewed = true;
              if (item.hasOwnProperty("questionnairesReviewed")){
                  this_reviewed = item.questionnairesReviewed
              }
              const dOA = new Date(item.dateOfArrival).setHours(0,0,0)
              if (((dOA - today_0)/(1000*60*60) < 48) && (this_reviewed === false)) {
                  return item
              }
          }
          return "None"
    }

    // get all vsisits that need a medical and a (valid) medical is not provided
    function getVisitIdForMedicalReminder(visits){
        for (var i=0; i<visits.length; i++){
            const item=visits[i]
            const this_medicalStatus = item["medicalStatus"] || 0; // skip if medicalstatus not in visit -> old visits
            const dOA = new Date(item.dateOfArrival).setHours(10,0,0)
            const dOD = new Date(item.dateOfDeparture).setHours(10,0,0)
            if (((dOA - today_0)/(1000*60*60) < 14*24) && this_medicalStatus === 1) { //within 14 days of arrival and medical strictly required
                if (diverProfile.scubaDetails.medicalData["hasMedical"]){
                    if (diverProfile.scubaDetails.medicalData.hasOwnProperty("medicalExpirationDate")) {
                        const dOME = new Date(diverProfile.scubaDetails.medicalData["medicalExpirationDate"] + "T10:00:00Z").setHours(10, 0, 0);
                        if (dOME <= dOD){
                            return item
                        }
                    }
                } else {
                    return item
                };
            }
        }
        console.log("will return None")
        return "None"
    }

    function loadVisitsInfo() {
            return API.get("visits", "/diver/visits");
        }

    function loadDiverInfoOnLoad(){
        function loadDiverInfo() {
          return API.get("divers", "/divers");
        }
        async function onLoadDiver() {
          try {
            const loaded_diver_dict = await loadDiverInfo();
            if ("status" in loaded_diver_dict) {
                if (loaded_diver_dict["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_diver_dict;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadDiver();
    }

    async function setAllDivers(loadedVisits, shop_dict){
        
          async function loadAllDiversByVisitId(visitIds){
            function loadDivers(visitIds){
                return API.put("divers", "/divecenters/divers", {
                    body: {"visitIds": visitIds}
                });
            }
            async function onLoadDivers(visitIds){
                try {
                    const loaded_divers = await loadDivers(visitIds);
                    if ("status" in loaded_divers) {
                        if (loaded_divers["status"] === "notfound") {
                            return {};
                        }
                    }
                    return loaded_divers;

                } catch (e) {
                    onError(e);
                }
            }
            return onLoadDivers(visitIds);
          }
          var visitsForDiverLoad = []
          for (let i=0; i< loadedVisits.length; i++){
                const this_item = loadedVisits[i]
                if (new Date(this_item.dateOfDeparture).setHours(23,59,59) >= today_0
                    && !(this_item.checkedOut)
                ){
                    visitsForDiverLoad.push(this_item.visitId)
                 }
          }
          var diverList = await loadAllDiversByVisitId(visitsForDiverLoad)
          diverList.forEach( this_item => {
            if (shop_dict.DCStaff.includes(this_item.userId))  {
                this_item["is_staff"] = true
             } else {
                this_item["is_staff"] = false
            }
          })
          return(diverList)
    }

    function loadShopsOnLoad(){
        function loadShops(){
            return API.get("divecenters", "/divecenters/all", {
            });
        }
        async function onLoadShops() {
          try {
            const loaded_shops = await loadShops();
            if ("status" in loaded_shops) {
                if (loaded_shops["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_shops;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadShops();
    }


    async function loadData(){
        function loadMyCenters() {
            return API.get("divecenters", "/divecenters", {
                });
        }
        function loadVisitsInfo(centerId) {
            return API.get("visits", `/divecenters/${centerId}/visits`);
        }
        var this_diver_dict = {}

        this_diver_dict = await loadDiverInfoOnLoad();

        console.log("diver_dict after onLoad")
        console.log(diver_dict)
        if (Object.keys(this_diver_dict).length === 0){
                    console.log("will redirect")
                    history.push("/diver/profile/generic")
        } else {
            setDiverProfile(this_diver_dict)
        }

        if (this_diver_dict.hasOwnProperty("dive_center_ui_required")) {
           if (this_diver_dict["dive_center_ui_required"])
           {
            // here we need to include possibility to have several shops and chose from one.
            const my_divecenters = await loadMyCenters();
            if (my_divecenters.length > 0) {
                setMyShop(my_divecenters[0])
                try {
                    const loaded_visits = await loadVisitsInfo(my_divecenters[0]["centerId"]);
                    if ("status" in loaded_visits) {
                        if (loaded_visits["status"] === "notfound") {
                            return {};
                        }
                    }
                    // NOT NEEDED activate and deactivate visits based on dates
                    //const activatedVisits = await activateDeactivateVisits(loaded_visits, shop_dict["centerId"])
                    setShopVisits(loaded_visits);
                    const diverList = await setAllDivers(loaded_visits, my_divecenters[0]);
                    setShopDivers(diverList)
                } catch (e) {
                    onError(e);
                }
            }
          }
        }
        // ToDo: place someplace else
        shop_list = await loadShopsOnLoad();
        setShopList(shop_list);
        return this_diver_dict;
    }

    async function onLoad() {
        setExpanded(false);
        setIsPageLoading(true)
        if (Object.keys(diverProfile).length === 0){
            await loadData()
        }
        var ref_shopList = shopList;
        if (ref_shopList.length === 0){
            ref_shopList = await loadShopsOnLoad();
            setShopList(ref_shopList);
        }
        try{
            const loaded_visits = await loadVisitsInfo();
            const futurevisits = getActiveFutureVisits(loaded_visits);
            const tmp_visit = getVisitIdForQ(futurevisits);
            const tmp_visit_review = getVisitIdForQReview(futurevisits);
            const tmp_visit_medical = getVisitIdForMedicalReminder(futurevisits);
            if (!(tmp_visit === "None") && tmp_visit["useScubchiCenter"] === true){
                setVisitForQ(tmp_visit)
                setConfirmShow(true)
                setShopNameForQ( ref_shopList.filter(item => item.centerId === tmp_visit["centerId"])[0]["DCName"] )
            }

            if (!(tmp_visit_review === "None") && tmp_visit_review["useScubchiCenter"] === true && tmp_visit_review["questionnairesDone"] === true) {
                setVisitForQReview(tmp_visit_review)
                setReviewShow(true)
                setShopNameForQReview(ref_shopList.filter(item => item.centerId === tmp_visit_review["centerId"])[0]["DCName"])
            }
            if (!(tmp_visit_medical === "None")){
                setVisitMedicalReminder(tmp_visit_medical)
                setMedicalShow(true)
            }
        } catch (e) {
            onError(e);
        }
        setIsPageLoading(false)
    }
    onLoad();

  }, [shopList, diverProfile, setDiverProfile, setMyShop, setShopList, setShopDivers, setShopVisits, history, setExpanded]);


  async function handleRedirectButton(target){
        history.push(target)
  }

  function handleNoticeMedicalReminder(visit){
      updateMedicalStatus(2, visit.centerId, visit.visitId)
      setMedicalShow(false);
  }

  function handleRedirectQuestionnaire(){
    //setConfirmShow(false)
    history.push(`/diver/questionnaire/generic/${visitForQ.visitId}`)
  }

  return(
         <LoadingOverlay
              active={isPageLoading}
              spinner
              text='Loading Scubchi...'
              >
        <div className="pt-3">

        <PopConfirm
                show={confirmShow}
                setShow={setConfirmShow}
                text={`Your questionnaire for your check-in with ${shopName} on ${visitForQ.dateOfArrival} needs to be filled out`}
                title="Questionnaires"
                handleConfirm={handleRedirectQuestionnaire}
                confirmText="Go to questionnaire"
                cancelText="Ignore"
        />
        <PopConfirm
            show={reviewShow}
            setShow={setReviewShow}
            text={`Your questionnaire for your check-in with ${shopNameForReview} on ${visitForQReview.dateOfArrival} needs to be reviewed`}
            title="Questionnaires"
            handleConfirm={(e) => handleRedirectVisit(visitForQReview.visitId)}
            confirmText="Go to Check-In"
            cancelText="Ignore"
        />
        <PopNotice
            show={medicalShow}
            setShow={setMedicalShow}
            text={`Keep in mind that you need a medical for your check-in at ${visitForMedicalReminder.DCName} from ${visitForMedicalReminder.dateOfArrival} to ${visitForMedicalReminder.dateOfDeparture}. If you have a medical, check the expiration date.`}
            title="Medical required"
            handleConfirm={(e) => handleNoticeMedicalReminder(visitForMedicalReminder)}
            confirmText="Ok"
        />

        <SCButton
                  onClick={(e) => handleRedirectButton("/diver/visits")}
                  size="lg"
                  block
                  variant="primary"
                  value="eventDiverList"
                  className = "addSelect" >
                Check-in
        </SCButton>

        <SCButton
                  onClick={(e) => handleRedirectButton("/diver/events")}
                  size="lg"
                  block
                  variant="primary"
                  value="eventDiverList"
                  className = "addSelect" >
                Logbook
        </SCButton>

        <LoaderButton
              block
              onClick={(e) => handleRedirectButton("/diver/profile/generic")}
              size="lg"
              variant="primary"
              value="previous"
              isLoading={isLoading}
              disabled={false}
            >
            Profile
        </LoaderButton>

        </div>
        </LoadingOverlay>
    )
}