import React, { useEffect } from "react";
import "./DiverInfo.css";
import "./spacings.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useAppContext } from "../lib/contextLib";

export default function About() {
    const {setExpanded} = useAppContext();
  useEffect(() => {
    setExpanded(false)
  }, [setExpanded]);




  return(
        <div className="pt-3">
            <Container className="pl-3 pr-3">
                <Row className="pl-3 pr-3">
                    You can find further information on our <a className="pl-1 pr-1" href="http://www.scubchi.com" target="_blank" rel="noreferrer"> webpage </a> along with our <a className="pl-1 pr-1" href="http://www.scubchi.com/terms-conditions" target="_blank" rel="noreferrer"> Terms and Conditions </a> and
                     our <a href="http://www.scubchi.com/privacy-policy" target="_blank" rel="noreferrer">Privacy and Data Policy</a>.</Row>
                <Row className="pl-3 pr-3 pt-3">Please get in touch with us for questions, comments or suggestions:</Row>
                <Row className="pl-3 pr-3">
                    <Col>info@scubchi.com</Col>
                </Row>
                <Row className="pl-3 pr-3">
                    <Col>www.scubchi.com</Col>
                </Row>
            </Container>

        </div>
    )
}