import React from "react";
import { DateTimePicker, DatePicker } from 'react-rainbow-components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
export default function SCDatePicker({...props}) {
    return(
        <div className='mb-3'>
            <DatePicker {...props}/>
        </div>
    );
}

export function SCDateTimePicker({...props}) {
    return(
        <div className='mb-3'>
        <DateTimePicker {...props}/>
        </div>
    );
}

export function SCDateRangePicker({...props}){
    return(
        <DateRangePicker

        initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}/>
    )

}