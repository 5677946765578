import React, { useState, useEffect } from "react";
import { SCButton} from "../components/LoaderButton";
import { useShopContext } from "../lib/contextLib";
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavHeader from "../components/Header"
import { ColoredLine } from "../components/Lists";
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"


export default function DivecenterInfo() {
  const history = useHistory();
  const {myShop
        } = useShopContext();
  const invitationLink = `http://www.app.scubchi.com/diver/visits/new/${myShop.centerId}`
  const [showCopiedHint, setShowCopiedHint] = useState(false);
  useEffect(() => {

  }, []);

  function handleBack(){
    history.goBack();
  }

  function handleCopy(){
    setShowCopiedHint(true)
    navigator.clipboard.writeText(invitationLink)
    setTimeout( () => {setShowCopiedHint(false)}, 1000);
  }

  return(

        <div className="pt-0">
        <NavHeader prev={true}
            next={false}
            label={"Your Divecenter"}
            disabled={false}
            handler={handleBack}/>

        <Container fluid>
            <Row>
                <Col className="text-center fs22">
                    {myShop["DCName"]}
                </Col>
            </Row>
        </Container>

        <ColoredLine className="scbline"/>


        <Container>
          <Row className="pb-3"><Col><b>Phone:</b></Col><Col>{myShop.contactData.phoneNumber}</Col></Row>
          <Row className="pb-3"><Col><b>Email:</b></Col><Col>{myShop.contactData.email}</Col></Row>
          <Row><Col><b>Address:</b></Col><Col>
            <Row className="pl-3 pb-1">{myShop.contactData.address.addressLine}</Row>
            <Row className="pl-3 pb-1">{myShop.contactData.address.ZIPCode + " " + myShop.contactData.address.city}</Row>
            <Row className="pl-3 pb-1">{myShop.contactData.address.country}</Row>
          </Col></Row>

        </Container>

        <ColoredLine className="scbline"/>
        <Container>
        <Row><Col className="text-center">
            <OverlayTrigger
                trigger='click'
                overlay={showCopiedHint ? <Tooltip id="no-file">Copied to Clipboard</Tooltip> : <></>}>
                <SCButton
                        onClick = {() => {handleCopy()}}
                      size="lg"
                      variant="primary"
                      value="eventDiverList"
                      className = "addSelect"
                   >
                    Copy Invitation Link
                </SCButton>
            </OverlayTrigger>
            </Col></Row>
            {/*<Row>
                <Col className="text-center fs22">
                    Scubchi dive center link:
                </Col>
            </Row>*/}
            <Row><Col className="text-center pt-3">
                {invitationLink}
            </Col></Row>
            <Row className="pt-3"><Col className="text-center"><i>Send this link to divers to invite them to register with your dive center.</i></Col></Row>

        </Container>



        </div>
    )
}