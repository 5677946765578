import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import {ButtonList, ColoredLine} from "../components/Lists";
import 'react-phone-number-input/style.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useShopContext } from "../lib/contextLib";
import LoadingOverlay from 'react-loading-overlay';

import { dateTimeView} from "../components/Helpers"

import NavHeader, {MenueHeader} from "../components/Header"


export default function ShopEvents() {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const headers_template = [
    {key: "todaysevents", label: "Today's Events", checked: true },
    {key: "futureevents", label: "Upcoming Events", checked: false },
    {key: "pastevents", label: "Past Events", checked: false }

  ]

  const today_0 = new Date().setHours(0,0,0);
  const today_24 = new Date().setHours(23,59,59);

  const history = useHistory();


  const {myShop, setShopEvents
        } = useShopContext();

  const [eventsList, setEventsList] = useState([]);

  const [headers, setHeaders] = useState(headers_template)

  const [menueId, setMenueId] = useState(0);

  useEffect(() => {

    function loadEventsInfo(centerId) {
      return API.get("events", `/divecenters/${centerId}/events`);
    }

    async function onLoad() {

      try {


        const loaded_events = await loadEventsInfo(myShop["centerId"]);
        setShopEvents(loaded_events);
        if ("status" in loaded_events) {
            if (loaded_events["status"] === "notfound") {
                return {};
            }
        }
        setEventsList(getEventsForList(loaded_events));

      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false);
    }
    onLoad();

  }, [myShop, setShopEvents]);




  function getEventsForList(eventslist) {
    const this_list = [];
    eventslist.forEach( (item, index) => {
        this_list.push( {...item,
            list_id: index,
            key: item.eventId,
            //date: String(item.eventDate).slice(0,10),
            //time:  String(item.eventDate).slice(11,16),
            label: `${item.eventName} at ${item.eventDiveSite}`,
            sublabel: dateTimeView(item.eventDate, true),
            marked: false} )

    }
    )
    return this_list;
  }

  function getTodaysEvents(eventslist){
    return eventslist.filter(item =>
        new Date(item.eventDate).setHours(23,59,59) >= today_0
        && new Date(item.eventDate).setHours(0) <= today_24)
     .sort((a, b) =>
        (new Date(a.eventDate) > new Date(b.eventDate)) ? 1 : -1 )
  }
  function getFutureEvents(eventslist){
    return eventslist.filter(item => new Date(item.eventDate).setHours(23,59,0)> today_24)
         .sort((a, b) =>
        (new Date(a.eventDate) > new Date(b.eventDate)) ? 1 : -1 )
  }
  function getPastEvents(eventslist){
    return eventslist.filter(item => new Date(item.eventDate).setHours(23,59,59) < today_0)
         .sort((a, b) =>
        (new Date(a.eventDate) > new Date(b.eventDate)) ? -1 : 1 )
  }

  async function navigateButton(next_or_previous) {

    if (next_or_previous === "previous") {
        handleRedirectButton("/shop/main")
    }

  }



  async function handleRedirectButton(target){
        history.push(target)
  }



  function handleRedirectToEventViewActive(key){
     history.push("/shop/events/edit/"+key+"/active")
  }

  function handleRedirectToEventViewInactive(key){
     history.push("/shop/events/edit/"+key+"/inactive")
  }


  function handleHeader(id){
    const newList = headers.map((item, index) => {
        if (index === id) {
            const updatedItem = {...item, checked: true, };
            return updatedItem;
        } else {
            const updatedItem = {...item, checked: false, };
            return updatedItem;
        }
    })
    setHeaders(newList)
    setMenueId(id)
  }


  return (
    <div className="DiverEvents">

        <LoadingOverlay
            active={isPageLoading}
            spinner
            text='Loading Events...'
        >

      <NavHeader prev={true}
        next={false}
        label="Events"
        handler={navigateButton}/>

        <LoaderButton
            className=" mt-3 mb-3"
            onClick={(e) => handleRedirectButton("/shop/events/edit/new/active")}
            block
        >New dive</LoaderButton>

        <ColoredLine className="scbline"/>
        <Container fluid>
            <Row>
                <Col className="text-center fs20 pb-3">
                    Dives and excursions
                </Col>
            </Row>
        </Container>

      <MenueHeader
        list={headers}
        changehandler={handleHeader}
      />




        { (menueId === 0) ? <>
          { (getTodaysEvents(eventsList).length === 0) ? <>
            <p className="text-center">You have no events today</p>
          </>:<>
              <ButtonList mylist={getTodaysEvents(eventsList)} changehandler={handleRedirectToEventViewActive}/>
           </>}
        </>:<></>}
        { (menueId === 1) ? <>
          { (getFutureEvents(eventsList).length === 0) ? <>
            <p className="text-center">You have no upcoming events</p>
          </>:<>
            <ButtonList mylist={getFutureEvents(eventsList)} changehandler={handleRedirectToEventViewActive}/>
          </>}
        </>:<></>}
        { (menueId === 2) ? <>
          { (getPastEvents(eventsList).length === 0) ? <>
            <p className="text-center">You have no past events yet</p>
          </>:<>
            <ButtonList mylist={getPastEvents(eventsList)} changehandler={handleRedirectToEventViewInactive}/>
          </>}
        </>:<></>}

     </LoadingOverlay>
    </div>
  );
}
