import React, { useState, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import { API } from "aws-amplify";
import {ButtonList, ColoredLine} from "../components/Lists";
import 'react-phone-number-input/style.css';
import { useDiverContext, useAppContext } from "../lib/contextLib";
import LoaderButton from "../components/LoaderButton"
import NavHeader, {MenueHeader} from "../components/Header"
import { dateTimeView} from "../components/Helpers"
import LoadingOverlay from 'react-loading-overlay';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function DiverVisits() {

  const history = useHistory();
  const {setExpanded} = useAppContext();
  const [currentPage, setPage] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const {shopList} = useDiverContext();


  const [visitsList, setVisitsList] = useState([]);

  const headers_template = [
    {label: "Upcoming", key: "futurevisits", checked: true },
    {label: "Past", key: "pastvisits", checked: false }
  ]
  const [headers, setHeaders] = useState(headers_template)
  const [menueId, setMenueId] = useState(0);

  const today_0 = new Date().setHours(0,0,0);

  useEffect(() => {
    function getVisitsForList(visitslist) {
        const this_list = [];
        const today_0 = new Date().setHours(0,0,0);
        visitslist.forEach( (item, index) => {
            var shopName = "";
            if (item.hasOwnProperty("DCName")){
                shopName = item.DCName;
            } else {
                shopName = shopList.filter(subitem => subitem.centerId === item["centerId"])[0]["DCName"];
            }
            var this_label = shopName;
            if ( ((new Date(item.dateOfArrival).setHours(0,0,0) - today_0) / (1000*60*60) < 48  ) && item.questionnairesDone === false ) {
                this_label += " - Please answer questionnaires!";
            }
            this_list.push( {...item,
                list_id: index,
                key: item.visitId,
                shopId: item.centerId,
                shopName: shopName,
                dateOfArrival: String(item.dateOfArrival).slice(0,10),
                dateOfDeparture: String(item.dateOfDeparture).slice(0,10),
                label: this_label,
                sublabel: `${dateTimeView(item.dateOfArrival, false)} -- ${dateTimeView(item.dateOfDeparture, false)}`
            } )
        }
        )
        return this_list;
    }

    function loadVisitsInfo() {
      return API.get("visits", "/diver/visits");
    }
    async function onLoad() {
      setExpanded(false)
      setIsPageLoading(true)
      try {
        const loaded_visits = await loadVisitsInfo();
        if ("status" in loaded_visits) {
            if (loaded_visits["status"] === "notfound") {
                return {};
            }
        }
        setVisitsList(getVisitsForList(loaded_visits));

      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false)
    }
    onLoad();
  }, [shopList, setExpanded]);



  function getActiveFutureVisits(visitslist){
    const tmp_visits = visitslist.filter(item => (
        new Date(item.dateOfDeparture).setHours(23,59,0) >= today_0
        && !(item.checkedOut)))

    const visits_accepted_or_not = tmp_visits.map((item, index) => {
        if (item.useScubchiCenter === false){
            return( {...item, divesublabel: String("Check-in via email")} )
        } else {
            if (item.acceptedByShop === false) {
                if (item.rejectedByShop === true) {
                    return( {...item, divesublabel: String(`Rejected: ${item.rejectMessage}`)} )
                } else {
                    return( {...item, divesublabel: String("Request pending")} )
                }
            } else {
                if (item.acceptedByShop === true && item.checkedOut === false && item.rejectedByShop === false){
                    return( {...item, divesublabel: String("Visit confirmed by Dive Center")} )
                } else if (item.checkedOut === true && item.rejectedByShop === false ){
                    return( {...item, divesublabel: String("Checked out by Dive Center")} )
                } else {
                    return item
                }
            }
        }

    })
    return visits_accepted_or_not.sort((a, b) => (new Date(a.dateOfArrival) > new Date(b.dateOfArrival)) ? 1 : -1 )
  }

  function getPastVisits(visitslist){
    const tmp_visits = visitslist.filter(item => ( new Date(item.dateOfDeparture).setHours(23,59,0) < today_0
        ||  (item.checkedOut)))
    .map((item, index) => {
        if (item.checkedOut === true) {
            return( {...item, divesublabel: String("Checked out by Dive Center")} )
        } else {
            return item
        }
    })

    return tmp_visits.sort((a, b) => (new Date(a.dateOfArrival) > new Date(b.dateOfArrival)) ? -1 : 1 )
  }


  async function navigateButton(next_or_previous) {
    var newpage = currentPage;
    if (next_or_previous === "next") {
        newpage += 1;
    }
    else if (next_or_previous === "previous") {
        if (currentPage === 0){
            history.push("/diver/main")
        } else {
            newpage -= 1;
        }

    }
    setPage(newpage)
  }


  function handleRedirectToVisitView(key){
     history.push(`/diver/visits/${key}/generic`)
  }

  function handleHeader(id){
    const newList = headers.map((item, index) => {
        if (index === id) {
            const updatedItem = {...item, checked: true, };
            return updatedItem;
        } else {
            const updatedItem = {...item, checked: false, };
            return updatedItem;
        }
    })
    setHeaders(newList)
    setMenueId(id)
  }


  return (
   <LoadingOverlay
      active={isPageLoading}
      spinner
      text='Loading Visits...'
      >
    <div className="DiverVisits">

      <NavHeader prev={true}
        next={false}
        label="Check-in"
        handler={navigateButton}/>

        <LoaderButton
            className=" mt-3 mb-3"
            onClick={(e) => history.push("/diver/visits/new/generic")}
            block
        >Check in with a dive center</LoaderButton>

        <ColoredLine className="scbline"/>
        <Container fluid>
            <Row>
                <Col className="text-center fs20 pb-3">
                    Your check-ins
                </Col>
            </Row>
        </Container>

      <MenueHeader
        list={headers}
        changehandler={handleHeader}
      />


      {/*active / future visits*/}
      { (menueId === 0) ? <>
        { (getActiveFutureVisits(visitsList).length === 0) ? <>

        </>:<>
          <ButtonList mylist={getActiveFutureVisits(visitsList)} changehandler={handleRedirectToVisitView}/>
         </>} {/*END if visits list === 0*/}
      </>:<></> } {/* END active / future visits*/}


      {/*past visits*/}
      { (menueId === 1) ? <>
        { (getPastVisits(visitsList).length === 0) ? <>
          <p className="text-center">No check-ins</p>
        </>:<>
          <ButtonList mylist={getPastVisits(visitsList)} changehandler={handleRedirectToVisitView}/>
         </>} {/*END if visits list === 0*/}

      </>:<></> } {/* END past visits*/}
    </div>
   </LoadingOverlay>
  );
}
