import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import { onError } from "../lib/errorLib";
import config from "../config";
import "./FormUpload.css";
import "./LoaderButton.css";
import {SCDeleteButton} from "./LoaderButton"
import FileUploader, {FotoUploader} from "./FileUploader";
import { API } from "aws-amplify";
import { generate_presigned_upload_url_for_user } from "../lib/awsLib";
import { basicUpload } from "../lib/awsLib";
import {PSURLDLButtonHint} from "../components/PSURLDownloadButton";
import { FiUpload} from "react-icons/fi"
import {FiCamera} from "react-icons/fi"
import {isMobile} from 'react-device-detect';

export default function FormUpDownload({
    apiEndpoint,
    filename,
    additionalInfoDict,
    dlDisabled,
    dlHintText,
    ...props}) {
  const file = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [myProgress, setMyProgress]= useState(0);
  const [dlCurrentlyDisabled, setDlCurrentlyDisabled] = useState(dlDisabled)
  const {workingCallback, ...otherProps} = props;
  const initializedWorkingCallback = workingCallback || (param => { return; });

  function progressCallback(progress) {
    const actual_progress = progress.loaded/progress.total*100;
    setMyProgress(actual_progress);
    console.log(actual_progress);
  };
  async function handleFileChange(event) {
    initializedWorkingCallback(true, false);
    file.current = event.target.files[0];
    var this_uploaddict={filename: `${filename}.upload`}
    if (additionalInfoDict.hasOwnProperty("visitId")){
        this_uploaddict["visitId"] = additionalInfoDict["visitId"]
    }
    var urldict = await generate_presigned_upload_url_for_user(this_uploaddict);
    const url = urldict["url"];
    console.log(url);
    await handleUpload(url);
    setDlCurrentlyDisabled(false);
    initializedWorkingCallback(false, false);
   }

  function getDlSpec(filename){
    var this_dldict={filename: `${filename}.jpg`}
    if (additionalInfoDict.hasOwnProperty("visitId")){
        this_dldict["visitId"] = additionalInfoDict["visitId"]
    }
    return this_dldict;
  }
  async function handleDelete(){
      initializedWorkingCallback(true, true);
      if (props.deleteCallback){
          props.deleteCallback()
      }
      additionalInfoDict["fileProvided"] = false; // will do a delete in brevetupdate
      await createUploadRef(additionalInfoDict);
      initializedWorkingCallback(false, true);
      setDlCurrentlyDisabled(true)
  }

  async function handleUpload(url) {
    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      if (file.current)
      {
        //attachment = await s3UploadToUserBucket(file.current, filename, progressCallback);
        await basicUpload(url, file.current, progressCallback);
      }
      await createUploadRef(additionalInfoDict);
      setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createUploadRef(additional_info_dict) {
    return API.post(`${apiEndpoint}`, `/${apiEndpoint}`, {
      body: additional_info_dict
    });
  }

  return (
        <Form.Group {...otherProps}  >
        <Container fluid>
          <Row>
            <Col className="pl-0 pr-0"> {props.children} </Col>
            <Col>
                <Row>
                    { isMobile ?
                        <Col className="pl-0 pr-0 text-right">
                            <FotoUploader variant="secondary" handleChange={handleFileChange}>
                                <FiCamera/>
                            </FotoUploader>
                        </Col>
                    : <></>}
                    <Col className="pl-0 pr-0 text-right">
                        <FileUploader variant="secondary" handleChange={handleFileChange}>
                            <FiUpload/>
                        </FileUploader>
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col className="text-right pl-0 pr-0 text-right">
                        <PSURLDLButtonHint
                            disabled={dlCurrentlyDisabled}
                            hintText={dlHintText}
                            className="dl"
                            variant="secondary"
                            download_spec={ getDlSpec(filename) }
                        />
                    </Col>
                    <Col className="text-right pl-0 pr-0 text-right">
                        <SCDeleteButton
                            disabled={dlCurrentlyDisabled}
                            className="dl"
                            onClick={() => handleDelete()}
                            variant="secondary"
                        />
                    </Col>
                </Row>
            </Col>
          </Row>
          <Row>{ (isLoading ) ?<Col fluid="sm" className="pt-3"><ProgressBar variant={"SCUBCHI"} now={myProgress} /></Col>: <></>}</Row>
        </Container>
        </Form.Group>
  );
}
