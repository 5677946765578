import React, { useState, useEffect } from "react";
import CountrySelector from "../components/CountrySelector";
import countryList from 'react-select-country-list'
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import {FiArrowRight} from "react-icons/fi"
import { onError } from "../lib/errorLib";
//import config from "../config";
import "./DiverInfo.css";
import "./spacings.css"
import { API } from "aws-amplify";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import SCDatePicker from '../components/SCDatePicker'
import { SelectableList, ColoredLine } from "../components/Lists";
import { getSpecialties, getMainBrevets, getBrevetsForSelect, getNewSpecialties,  getBrevetLabelFromId} from "../diverlib/brevets"
import Select from 'react-select';
import FormUpDownload from "../components/FormUpDownload";
import ToggleBasic from "../components/Toggles"
import { useDiverContext } from "../lib/contextLib";
import NavHeader from "../components/Header"
import Footer from "../components/Footer"
import Container from 'react-bootstrap/Container'
import LoadingOverlay from 'react-loading-overlay';
import { useAppContext } from "../lib/contextLib";



function getSpecialtiesCompactForList(){
    const this_list = [];
    const data = getNewSpecialties();
    data.map((item, index) => (
            this_list.push({...item, "list_id": index, selected: false, sublabel: item.sublabel})
        )
    )
    return(this_list)
}

export default function DiverInfo() {

  const { visitId } = useParams(); // should be "generic" or uuid in case of shadowdivers
  const {setExpanded} = useAppContext();
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [currentPage, setPage] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [exposedBrevet, setExposedBrevet] = useState(0);
  const [exposedBrevetId, setExposedBrevetId] = useState("");
  const [optionalCLevelName, setOptionalCLevelName] = useState("None");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [ZIPCode, setZIPCode] = useState("");
  const [country, setCountry] = useState("");
  const [birthdate, setBirthdate] = useState( new Date() );
  const [ownPhoneNumber, setOwnPhoneNumber] = useState();
  const [email, setEmail] = useState("");
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencyLastName, setEmergencyLastName] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const [provideEmergencyContact, setProvideEmergencyContact] = useState(true);
  const [certified, setCertified] = useState(false);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insuranceId, setInsuranceId] = useState("");
  const [insuranceExpirationDate, setInsuranceExpirationDate] = useState(new Date());
  const [providedInsurance, setProvidedInsurance] = useState(false);
  const [hasMedical, setHasMedical] = useState(false);
  const [dateOfMedical, setDateOfMedical] = useState( new Date() );
  const [providedMedical, setProvidedMedical] = useState(false);
  const [dateOfLastDive, setDateOfLastDive] = useState( new Date() );
  const [numberOfDives, setNumberOfDives] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [diveCenterUiRequired, setDiveCenterUiRequired] = useState(false);
  const [diverSpecialtyDict, setDiverSpecialtyDict] = useState({});
  const [diverMainBrevetDict, setDiverMainBrevetDict] = useState({});

  const [acceptedTermsVersion, setAcceptedTermsVersion] = useState(-1);

  const specialtiesDict = getSpecialties();
  const mainBrevetDict = getMainBrevets();

  const headerLabels = {
    0: "Personal Information",
    1: "Diver Information",
    2: "Certificate Upload"

  }
  const [currentHeaderLabel, setLabel] = useState(headerLabels[0]);
  const {diverProfile, setDiverProfile} = useDiverContext();

  const brevets4Select = getBrevetsForSelect();

  const [uploadsWorking, setUploadsWorking] = useState({});

  function handleSetUploadWorking(uploadkey, isWorking, isDeleted = false){
    setIsLoading(isWorking);
      setUploadsWorking(prev => ({
       ...prev,
            [uploadkey]: isWorking
      })
    )
    if (isWorking === false && visitId === "generic"){ // works only for read divers, not shadows. ALso not necessary for shadows
        if (isDeleted === false){
            handleSetFileProvided(uploadkey, true)
        } else {
            handleSetFileProvided(uploadkey, false)
        }

    }
  }

  function handleSetFileProvided(key, fileProvided = true){
    var tmp_diverProfile = diverProfile;
    if (key === "Medical"){
        tmp_diverProfile["scubaDetails"]["medicalData"]["fileProvided"] = fileProvided
    }
    if (key === "Insurance"){
        tmp_diverProfile["scubaDetails"]["insuranceData"]["fileProvided"] = fileProvided
    } else {
        const keysplit = key.split("_")
        const brevet = Number(keysplit[0])
        if (Object.values(specialtiesDict).includes(brevet) === true ){
            tmp_diverProfile["scubaDetails"]["specialtyBrevets"][brevet]["fileProvided"][keysplit[1]] = fileProvided
        } else if (Object.values(mainBrevetDict).includes(brevet) === true){
            tmp_diverProfile["scubaDetails"]["mainBrevets"][brevet]["fileProvided"][keysplit[1]] = fileProvided
        }
    }
    setDiverProfile(tmp_diverProfile)
  }


  function validateUploadsFinished(){
    return (Object.values(uploadsWorking).filter(item => item === true).length === 0)
  }

  const [specialties, setSpecialties] = useState(getSpecialtiesCompactForList());


  function handleSelectSpecialty(key) {
    handleSelectItem(key, specialties, setSpecialties)
  }

  function handleSelectItem(key, this_list, set_fctn){
    const newList = this_list.map((item, index) => {
      if (item.key === key) {
        const updatedItem = {...item, selected: !item.selected, };
        return updatedItem;
      }
      return item;
    });
    set_fctn(newList);
  }

  function handleBrevetSelect(brevetvalue) {
    var newerrors = errors
    if (brevetvalue === 0){
        newerrors["mainBrevet"] = "Brevet not set"
    } else {
        delete newerrors["mainBrevet"]
    }
    setErrors(errors)
    setExposedBrevet(brevetvalue)
  }


  function validateForm() {
    return (Object.keys(errors).length === 0);
  }





  useEffect(() => {

    function loadDiverInfoById(){
        function loadDiverInfo() {
            if (visitId === "generic") {
                return API.get("divers", "/divers");
            } else {
                return API.get("divers", `/divecenters/diver/${visitId}`);
            }
        }
        async function onLoadDiver() {
          try {
            const loaded_diver_dict = await loadDiverInfo();
            if ("status" in loaded_diver_dict) {
                if (loaded_diver_dict["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_diver_dict;
          } catch (e) {
            onError(e);
          }
        }
        return onLoadDiver();
    }


     async function onLoad(cognito_from_db=false) {
         setExpanded(false)
      console.log("in on load of diver info")
      try {
        var diver_dict = {};
        if (visitId === "generic") {
            if (cognito_from_db){
                diver_dict = await loadDiverInfoById();
            } else {
                diver_dict = diverProfile;
            }
        } else {
            diver_dict = await loadDiverInfoById();
        }

        const uireq = diver_dict["dive_center_ui_required"] || false;
        setDiveCenterUiRequired(uireq)
        const firstName = diver_dict["firstName"] || "";
        setFirstName(firstName);
        const lastName = diver_dict["lastName"] || "";
        setLastName(lastName);
        const exposedBrevet = diver_dict["exposedBrevet"] || -1 ;
        setExposedBrevet(exposedBrevet);
        const this_exposedBrevetId = diver_dict["exposedBrevetId"] || "";
        setExposedBrevetId(this_exposedBrevetId);
        setOptionalCLevelName(diver_dict["exposedOtherBrevetName"])
        const acceptedTV = diver_dict["acceptedTermsVersion"] || -1 ;
        setAcceptedTermsVersion(acceptedTV);
        console.log(acceptedTV)
        if (diver_dict.hasOwnProperty("personalDetails")){
            var loaded_birthdate = new Date(diver_dict["personalDetails"]["dateOfBirth"]+"T10:00:00Z");
            setBirthdate(loaded_birthdate);
            setAddressLine(diver_dict["personalDetails"]["address"]["addressLine"]);
            setCity(diver_dict["personalDetails"]["address"]["city"]);
            setZIPCode(diver_dict["personalDetails"]["address"]["ZIPCode"]);

            var country_label = diver_dict["personalDetails"]["address"]["country"];
            var country_code = countryList().getValue(country_label);
            setCountry({"value": country_code, "label": country_label});

            setEmail(diver_dict["personalDetails"]["email"]);
            setOwnPhoneNumber(diver_dict["personalDetails"]["phoneNumber"]);
            setEmergencyFirstName(diver_dict["personalDetails"]["contactPerson"]["firstName"]);
            setEmergencyLastName(diver_dict["personalDetails"]["contactPerson"]["lastName"]);
            setEmergencyPhoneNumber(diver_dict["personalDetails"]["contactPerson"]["phoneNumber"]);
            setProvideEmergencyContact(diver_dict["personalDetails"]["contactPerson"]["provideEmergencyContact"])
        }
        if (diver_dict.hasOwnProperty("scubaDetails")){
            setCertified(diver_dict["scubaDetails"]["isCertified"]);
            setNumberOfDives(diver_dict["scubaDetails"]["diveRecord"]["numberOfDives"]);

            //setDateOfLastDive(diver_dict["scubaDetails"]["diveRecord"]["dateOfLastDive"]);
            var loaded_dateOfLastDive = new Date(diver_dict["scubaDetails"]["diveRecord"]["dateOfLastDive"]+"T10:00:00Z");
            setDateOfLastDive(loaded_dateOfLastDive);
            setDiverMainBrevetDict(diver_dict["scubaDetails"]["mainBrevets"]);

            var localDiverSpecialtyDict = diver_dict["scubaDetails"]["specialtyBrevets"];
            setDiverSpecialtyDict( localDiverSpecialtyDict);
            //ToDo: I failed to resolve this warning. Tried to pass it as argument in UseEffect(), did not work.
            var localSpecialties = getSpecialtiesCompactForList(getNewSpecialties());
            for (var i = 0; i < localSpecialties.length; i++) {
                if ( localDiverSpecialtyDict.hasOwnProperty(localSpecialties[i].key) )
                    localSpecialties[i].selected = true
            }
            setSpecialties(localSpecialties);

            setHasInsurance(diver_dict["scubaDetails"]["insuranceData"]["hasInsurance"]);
            setHasMedical(diver_dict["scubaDetails"]["medicalData"]["hasMedical"]);
            setProvidedInsurance(diver_dict["scubaDetails"]["insuranceData"]["fileProvided"]);
            setInsuranceProvider(diver_dict["scubaDetails"]["insuranceData"]["insuranceProvider"]);
            setInsuranceId(diver_dict["scubaDetails"]["insuranceData"]["insuranceId"]);
            var loaded_insuranceExpirationDate = new Date(diver_dict["scubaDetails"]["insuranceData"]["insuranceExpirationDate"] + "T10:00:00Z") || new Date();
            setInsuranceExpirationDate(loaded_insuranceExpirationDate);
            setProvidedMedical(diver_dict["scubaDetails"]["medicalData"]["fileProvided"]);
            var loaded_dateOfMedical = new Date(diver_dict["scubaDetails"]["medicalData"]["dateOfIssue"]+"T10:00:00Z");
            if (diver_dict["scubaDetails"]["medicalData"].hasOwnProperty("medicalExpirationDate")){
                loaded_dateOfMedical = new Date(diver_dict["scubaDetails"]["medicalData"]["medicalExpirationDate"]+"T10:00:00Z");
            }
            console.log(loaded_dateOfMedical)
            setDateOfMedical(loaded_dateOfMedical);
        }
      } catch (e) {
        onError(e);
      }
      setIsPageLoading(false)
    }
    onLoad();
  }, [diverProfile, visitId, setExpanded]);

  function getBrevetUploadInfoDict(brevet) {
    var brevetUploadInfoDict = {
       "Brevet" : brevet,
    }
    if (!(visitId==="generic")){
       brevetUploadInfoDict["visitId"]=visitId;
    }
    return brevetUploadInfoDict;
  }


  async function handleNextPreviousClick(next_or_previous) {
    // this can go someplace else, e. g. extra handlers that we just do not need right now
    birthdate.setHours(11, 0, 0);
    dateOfLastDive.setHours(11, 0, 0);
    dateOfMedical.setHours(11, 0, 0);
    console.log(dateOfMedical)
    var localDiverSpecialtyDict = diverSpecialtyDict;
    var localDiverMainBrevetDict = diverMainBrevetDict;
    if (currentPage === 1) {
        if (certified === false && next_or_previous === "next"){
            history.goBack();
        } else {
            var today = new Date()
            //create new dict so only currently set specialties appear -> we can remove a saved specialty
            var newDiverSpecialtyDict = {};
            specialties.filter(item => item.selected === true).map(filteredSpecialties => {
                if (!diverSpecialtyDict.hasOwnProperty(filteredSpecialties.key)) {
                    newDiverSpecialtyDict[filteredSpecialties.key] = {
                    "fileProvided": {"front": false, "back": false},
                    "dateEntered": today.toISOString().slice(0,10)
                     }
                } else {
                    newDiverSpecialtyDict[filteredSpecialties.key] =  diverSpecialtyDict[filteredSpecialties.key]
                }
                //ToDo the following fixes a warning, but I am not sure if this hot it is supposed to be
                return(0)
            }
            )
            // overwrite old dict
            setDiverSpecialtyDict(newDiverSpecialtyDict);
            localDiverSpecialtyDict = newDiverSpecialtyDict;

            var newDiverMainBrevetDict = {};
            if ( !(exposedBrevet===-1)) {
                if (!diverMainBrevetDict.hasOwnProperty(exposedBrevet)) {
                    newDiverMainBrevetDict[exposedBrevet] = {
                        "fileProvided": {"front": false, "back": false},
                        "dateEntered": today.toISOString().slice(0, 10)
                    }
                } else {
                    newDiverMainBrevetDict[exposedBrevet] = diverMainBrevetDict[exposedBrevet];
                }
                setDiverMainBrevetDict(newDiverMainBrevetDict);
                localDiverMainBrevetDict = newDiverMainBrevetDict;
            }
        }
    }

    setIsLoading(true);
    // adjust hours of all dates to not have date switch to one day before


    const updatedDiverDict = {
         "firstName": firstName,
         "lastName": lastName,
         "exposedBrevet": exposedBrevet,
         "exposedOtherBrevetName": optionalCLevelName,
         "exposedBrevetId": exposedBrevetId,
         "dive_center_ui_required": diveCenterUiRequired,
         "acceptedTermsVersion": acceptedTermsVersion,
         "personalDetails": {
             "address": {
                 "addressLine": addressLine,
                 "city": city,
                 "ZIPCode": ZIPCode.toString(),
                 "country": country.label
             },
             "dateOfBirth": birthdate.toISOString().slice(0, 10),
             "email": email,
             "phoneNumber": ownPhoneNumber,
             "contactPerson": {
                  "provideEmergencyContact": provideEmergencyContact,
                  "firstName": emergencyFirstName,
                  "lastName": emergencyLastName,
                  "phoneNumber": emergencyPhoneNumber
             }
         },
         "scubaDetails": {
             "isCertified": certified,
             "mainBrevets": localDiverMainBrevetDict,
             "specialtyBrevets": localDiverSpecialtyDict,
             "diveRecord": {
                "numberOfDives": Number(numberOfDives),
                "dateOfLastDive": dateOfLastDive.toISOString().slice(0, 10)
             },
             "insuranceData": {
                "hasInsurance": hasInsurance,
                "fileProvided": providedInsurance,
                "insuranceProvider": insuranceProvider,
                "insuranceId": insuranceId,
                "insuranceExpirationDate": insuranceExpirationDate.toISOString().slice(0, 10),
                },
             "medicalData": {
                "hasMedical": hasMedical,
                "medicalExpirationDate": dateOfMedical.toISOString().slice(0, 10),
                "fileProvided": providedMedical
                }
         }
      }
    if (!(currentPage===2)){

        if (currentPage === 0) {
            // if we click next or back on page 0, let's check that the profile contains no errors.
            checkForInitErrors()
        }
        if (currentPage === 1){
            checkForBrevetErrors()
        }
        if (Object.keys(errors).length === 0){
            try {
                if (visitId === "generic") {
                    console.log(updatedDiverDict)
                    await createDiver({"content": updatedDiverDict});
                    setDiverProfile(updatedDiverDict)
                } else {
                    await updateShadowDiver({"content": updatedDiverDict})
                }
            } catch (e) {
              onError(e);
              setIsLoading(false);

            }
        }
    }
    setIsLoading(false);
    var newpage = currentPage;
    if (next_or_previous === "previous") {
        if (currentPage === 2){
            console.log("previous")
            //onLoad(true);
        }
        newpage -= 1;
    }
    else
    {
        if (Object.keys(errors).length === 0) {
            newpage += 1;
        }
    }
    if (newpage < 0){
        newpage = 0;
    }

    setPage(newpage)
    setLabel(headerLabels[newpage])
    // redirections
  }

  function handleFinish(){
    history.goBack()
  }

  function createDiver(diverinfo) {
    return API.post("divers", "/divers", {
      body: diverinfo
    });
  }

  function updateShadowDiver(diverinfo) {
    return API.put("divers", `/divecenters/shadowdiver/${visitId}`, {
      body: diverinfo
    });
  }

  // verifyer coming below
  const verifyNameString = (value, errorkey, label) => {
   const newerrors= errors
    const length_thresh=25
    if ( !value || value === '' ) {
        newerrors[errorkey] = label + ' cannot be blank!'
    }
    else if (value.length > length_thresh) {
        newerrors[errorkey] = label + ' cannot be longer than ' + length_thresh + ' characters'
    }
    else if ( ! /^[a-zA-ZöÖäÄüÜß ]+$/.test(value)) {
            newerrors[errorkey] = 'No digits allowed in ' + label
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateNameString (setter, value, errorkey, label) {
    verifyNameString(value, errorkey, label);
    setter(value);
  }

  const verifyDigitString = (value, errorkey, label) => {
    const newerrors= errors
    if ( ! /^[0-9]+$/.test(value)) {
            newerrors[errorkey] = 'Only digits allowed in ' + label
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateDigitString (setter, value, errorkey, label) {
    verifyDigitString(value, errorkey, label);
    setter(value);
  }

  const verifyZIP = (value, errorkey, label) => {
    const newerrors= errors
    if ( ! /^[0-9A-Za-z ]+$/.test(value)) {
            newerrors[errorkey] = 'Only digits and letters allowed in ' + label
    }
    else if ( value < 100){
            newerrors[errorkey] = "Please provide at least a 3-digit " + label
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateZIP(setter, value, errorkey, label){
    verifyZIP(value, errorkey, label);
    setter(value);
  }

  const verifyString = (value, errorkey, label) => {
    const newerrors= errors
    const length_thresh=50
    if ( !value || value === '' ) {
        newerrors[errorkey] = label + ' cannot be blank!'
    }
    else if (value.length > length_thresh) {
        newerrors[errorkey] = label + ' cannot be longer than ' + length_thresh + ' characters'
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateString (setter, value, errorkey, label) {
    verifyString(value, errorkey, label);
    setter(value);
  }

  const verifyDate = (value, errorkey, label) => {
    const newerrors= errors
    const today = new Date();
    const this_year = today.getYear();
    if (value === today) {
        newerrors[errorkey] = label + " cannot be today"
    }
    else if ( this_year - value.getYear() < 6) {
        newerrors[errorkey] = "Aren't you a bit young?"
    }
    else if ( this_year - value.getYear() > 100) {
        newerrors[errorkey] = "Sure you want to go diving with age above 100?"
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateDate (setter, value, errorkey, label) {
    verifyDate(value, errorkey, label);
    setter(value);
  }

  const verifyCountry = (value, errorkey, label) => {
    const newerrors= errors
    if (value === {} || value.key === "" || value.label === "" || value.length === 0) {
        newerrors[errorkey] = "Please chose a valid "+label
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateCountry (setter, value, errorkey, label) {
    verifyCountry(value, errorkey, label);
    setter(value);
  }

  /*const openInNewTab = (url) => {
     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
     if (newWindow) newWindow.opener = null
  }*/

  const verifyEmail = (value, errorkey, label) => {
    const newerrors= errors

    if ( value === "" ) {
        newerrors[errorkey] = "Please enter a valid email address for "+label
    }
    else if ( ! value.includes("@")) {
        newerrors[errorkey] = "Please enter a valid email address for "+label
    }
    else {
      delete newerrors[errorkey];
    }
    setErrors(newerrors)
  }
  function setValidateEmail (setter, value, errorkey, label) {
    verifyEmail(value, errorkey, label);
    setter(value);
  }

  function handleSetCertified(thisCertified){
    const newerrors= errors

    if (thisCertified === true && exposedBrevet === 0 ){
        newerrors["mainBrevet"] = "Brevet not set"
    } else {
        delete newerrors["mainBrevet"]
    }
    setCertified(thisCertified)
    setErrors(newerrors)
  }


  const addstring= ["front", "back"]

  function checkForInitErrors(){
      
        setValidateNameString(setFirstName, firstName, "firstName", "First Name")
        setValidateNameString(setLastName, lastName, "lastName", "Last Name")
        setValidateDate(setBirthdate, birthdate, "birthDate", "Birthdate")
        setValidateString(setAddressLine, addressLine, "addressLine", "Address Line")
        setValidateString(setCity, city, "city", "City")
        setValidateZIP(setZIPCode, ZIPCode, "ZIP", "ZIP")
        setValidateEmail(setEmail, email, "email", "your personal email")
        setValidateCountry(setCountry, country, "country", "country")
        setBirthdate(new Date(birthdate.setHours(12,0,0)))
      
  }

  function checkForBrevetErrors(){
        if (certified){
            if ( exposedBrevet === -1 ||  exposedBrevet === 0 ){
                var newerrors = errors
                newerrors["mainBrevet"] = "Brevet not set"
                setExposedBrevet(-1)
                setExposedBrevet(0)
                setErrors(errors)
            }
        }
  }

  return (
         <LoadingOverlay
              active={isPageLoading}
              spinner
              text='Loading Profile...'
              >


      { (currentPage === 0) ? <>
        <NavHeader prev={false}
            next={true}
            label={currentHeaderLabel}
            disabled={!validateForm()}
            handler={handleNextPreviousClick}/>
        <div >
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formfirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control value={firstName}
                          type="string"
                          placeholder="First Name"
                          onChange={(e) => setValidateNameString(setFirstName, e.target.value, "firstName", "First Name")}
                          isInvalid={ !!errors.firstName }/>
            <Form.Control.Feedback type='invalid'>
                { errors.firstName }
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3  pl-3 pr-3" controlId="formlastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control value ={lastName}
                          type="string"
                          placeholder="Last Name"
                          onChange={(e) => setValidateNameString(setLastName, e.target.value, "lastName", "Last Name")}
                          isInvalid={ !!errors.lastName }/>
            <Form.Control.Feedback type='invalid'>
                { errors.lastName }
            </Form.Control.Feedback>
        </Form.Group>

        {/* start personal details */}
        {/*<Form.Group className="mb-3" controlId="formBirthday" isInvalid={ !!errors.birthDate }>*/}
        <SCDatePicker
            className= "pl-3 pr-3 pb-0 mb-0"
            invalid={errors.hasOwnProperty("birthDate")}
            value={birthdate}
            error={errors["birthDate"]}
            onChange={value => setValidateDate(setBirthdate, value, "birthDate", "Birthdate")}
            label="Date of Birth"
            formatStyle="large"
            locale="US"
        />
        { /* address */}
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formaddressLine">
            <Form.Label>Address Line</Form.Label>
            <Form.Control value={addressLine}
                          type="string"
                          placeholder="Street, Number, etc."
                          onChange={(e) => setValidateString(setAddressLine, e.target.value, "addressLine", "Address Line")}
                          isInvalid={ !!errors.addressLine }/>
            <Form.Control.Feedback type='invalid'>
                { errors.addressLine }
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formcity">
            <Form.Label>City</Form.Label>
            <Form.Control value={city}
                          type="string"
                          placeholder="City"
                          onChange={(e) => setValidateString(setCity, e.target.value, "city", "City")}
                          isInvalid={ !!errors.city }/>
            <Form.Control.Feedback type='invalid'>
                { errors.city }
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formZIPCode">
            <Form.Label>ZIP Code</Form.Label>
            <Form.Control value={ZIPCode}
                          type="string"
                          placeholder="ZIP Code"
                          onChange={(e) => setValidateZIP(setZIPCode, e.target.value, "ZIP", "ZIP")}
                          isInvalid={ !!errors.ZIP }/>
            <Form.Control.Feedback type='invalid'>
                { errors.ZIP }
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formCountry">
            <Form.Label>Country</Form.Label>
            <CountrySelector
                value={country}
                onChange={(value)=>setValidateCountry(setCountry, value, "country", "country")}
                isInvalid={ !!errors.country }
                invalid={!!errors.country}
                />
            <Form.Text id="country selector"  muted>
                <div style={{color: "red"}}>{errors.country ? "Please select a country" : ""} </div>
            </Form.Text>
        </Form.Group>
        {/* end address */}
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formBasicEmail">
            <Form.Label>Contact email address</Form.Label>
            <Form.Control value={email}
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => setValidateEmail(setEmail, e.target.value, "email", "your personal email")}
                isInvalid={ !!errors.email }
                />
            <Form.Control.Feedback type='invalid'>
                { errors.email }
            </Form.Control.Feedback>
            <Form.Text muted>Shops will receive this address to communicate with you. It is independent of the email you registered with.</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="formownPhoneNumber">
          <Form.Label>Phone number</Form.Label>
          <PhoneInput
             placeholder="Enter phone number"
             value={ownPhoneNumber}
             onChange={(e) => setOwnPhoneNumber(e)}
          />
        </Form.Group>
        <ColoredLine className="scbline"/>
        {/*  contact person details */}
        <Form.Group controlId="formProvideEmergencyContact">
            <ToggleBasic label="Do you want to name an emergency contact?"
                checked={provideEmergencyContact}
                setter={setProvideEmergencyContact}
                variable={provideEmergencyContact}/>

        </Form.Group>
        {provideEmergencyContact ? <>

            <Form.Group className="mb-3 pl-3 pr-3" controlId="formemergencyFirstName">
                <Form.Label>First Name (emergency contact)</Form.Label>
                <Form.Control value={emergencyFirstName}
                              type="string"
                              placeholder="First Name of emergency contact"
                              onChange={(e) => setValidateNameString(setEmergencyFirstName, e.target.value, "emergencyFirstName", "First Name of emergency contact")} // TODO: include second event in onchange -> validateString
                              isInvalid={ !!errors.emergencyFirstName }/>
                <Form.Control.Feedback type='invalid'>
                    { errors.emergencyFirstName }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 pl-3 pr-3" controlId="formemergencyLastName">
                <Form.Label>Last Name (emergency contact)</Form.Label>
                <Form.Control value ={emergencyLastName}
                              type="string"
                              placeholder="Last Name of emergency contact"
                              onChange={(e) => setValidateNameString(setEmergencyLastName, e.target.value, "emergencyLastName", "Last Name of emergency contact")} // TODO: include second event in onchange -> validateString
                              isInvalid={ !!errors.emergencyLastName }/>
                <Form.Control.Feedback type='invalid'>
                    { errors.emergencyLastName }
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 pl-3 pr-3" controlId="formemergencyPhoneNumber">
              <Form.Label>Emergency contact phone number</Form.Label>
              <PhoneInput
               placeholder="Enter phone number of emergency contact"
               value={emergencyPhoneNumber}
               onChange={(e) => setEmergencyPhoneNumber(e)}
              />
            </Form.Group>

        </>:<></> /*end if provideEmergencyContact*/}
        {/* end personal details */}
        {/* start scuba details */}
    </div>
    </>: <></> /* currentPage */}

      { (currentPage === 1) ? <>
        <NavHeader prev={true}
            next={true}
            label={currentHeaderLabel}
            disabled={!validateForm()}
            handler={handleNextPreviousClick}/>
        <div>
        <Form.Group controlId="formCertifiedDiver">

        <ToggleBasic label="Are you a certified diver?"
            checked={certified}
            setter={handleSetCertified}
            variable={certified}/>

        </Form.Group>
        {certified ?  <>

        <Form.Group className="pl-3 pr-3">
        <Form.Label>C-level</Form.Label>
        <Select
            options={brevets4Select}
            value={brevets4Select.filter(option => option.value === exposedBrevet)}
            onChange={(e) => handleBrevetSelect(e.value)}
            isInvalid={!! errors.mainBrevet}
            />
        { (exposedBrevet === 100100) ? <>
            <Form.Group className="mb-3 pt-3" controlId="formCLevel">
                <Form.Label>C-level</Form.Label>
                <Form.Control value ={optionalCLevelName}
                              type="string"
                              placeholder="Your C-level"
                              onChange={(e) => setValidateString(setOptionalCLevelName, e.target.value, "optionalCLevelName", "Name of optional C-Level")} // TODO: include second event in onchange -> validateString
                              isInvalid={ !!errors.optionalCLevelName }/>
                <Form.Control.Feedback type='invalid'>
                    { errors.optionalCLevelName }
                </Form.Control.Feedback>
            </Form.Group>
        </>:<></>/*endif exposedBrevet*/}
        <Form.Control.Feedback type='invalid'>
                    { errors.mainBrevet }
        </Form.Control.Feedback>
        { errors.hasOwnProperty("mainBrevet") ? <>
                <div className="pl-3 pr-3" style={{color: "red"}}>Please select a c-Level</div>
        </>:<></>}

        </Form.Group>
        <Form.Group className="pl-3 pr-3" controlId="formCLevel">
            <Form.Label>Certificate Id</Form.Label>
            <Form.Control value ={exposedBrevetId}
                          type="string"
                          placeholder="Number of your certificate / c-card"
                          onChange={(e) => setValidateString(setExposedBrevetId, e.target.value, "exposedBrevetId", "Number of your certification")} // TODO: include second event in onchange -> validateString
                          isInvalid={ !!errors.exposedBrevetId }/>
            <Form.Control.Feedback type='invalid'>
                { errors.exposedBrevetId }
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="pl-3 pr-3">
        <Form.Label><b>Select your specialties</b></Form.Label>
        {/*<MarkableList mylist={specialties} changehandler={handleMarkItem} set_fctn={setSpecialties}/>*/}
        <SelectableList className="pl-0 pr-0"
            mylist={specialties}
            changehandler={handleSelectSpecialty}
        />

        </Form.Group>

        <SCDatePicker
            className="pr-3 pl-3"
            value={dateOfLastDive}
            onChange={value => setDateOfLastDive(value)}
            label="Date of your last dive"
            formatStyle="large"
            locale="US"
        />
        <Form.Group className="mb-3 pl-3 pr-3" controlId="fornumberOfDives">
            <Form.Label>Number of dives</Form.Label>
            <Form.Control value ={numberOfDives}
                          type="string"
                          placeholder="Total number of dives performed to date"
                          onChange={(e) => setValidateDigitString(setNumberOfDives, e.target.value, "numberDives", "number of dives")} // TODO: include second event in onchange -> validateString
                          isInvalid={ !!errors.numberDives }/>
            <Form.Control.Feedback type='invalid'>
                { errors.numberDives }
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formInsurance">
        <ToggleBasic label="Do you have SCUBA insurance?"
            checked={hasInsurance}
            setter={setHasInsurance}
            variable={hasInsurance}/>
        </Form.Group>

        { hasInsurance ? <>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="fornumberOfDives">
            <Form.Label>Insurance Provider</Form.Label>
            <Form.Control value={insuranceProvider}
                          type="string"
                          placeholder="Insurance Provider"
                          onChange={(e) => setValidateString(setInsuranceProvider, e.target.value, "insuranceProvider", "insurance Provider")} // TODO: include second event in onchange -> validateString
                          isInvalid={ !!errors.insuranceProvider }/>
            <Form.Control.Feedback type='invalid'>
                { errors.insuranceProvider }
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 pl-3 pr-3" controlId="fornumberOfDives">
            <Form.Label>Policy or membership ID</Form.Label>
            <Form.Control value={insuranceId}
                          type="string"
                          placeholder="Insurance or policy number"
                          onChange={(e) => setValidateString(setInsuranceId, e.target.value, "insuranceId", "insurance number")} // TODO: include second event in onchange -> validateString
                          isInvalid={ !!errors.insuranceId }/>
            <Form.Control.Feedback type='invalid'>
                { errors.insuranceId }
            </Form.Control.Feedback>
        </Form.Group>
        <SCDatePicker
            className="pr-3 pl-3"
            value={insuranceExpirationDate}
            onChange={value => setInsuranceExpirationDate(value)}
            label="Insurance - Date of expiration"
            formatStyle="large"
            locale="US"
        />
        </>:<></> }

        <Form.Group className="mb-3" controlId="formMedical">
        <ToggleBasic label="Do you have a valid medical certificate?"
            checked={hasMedical}
            setter={setHasMedical}
            variable={hasMedical}/>
        {/*<Form.Check type="checkbox"
                    label="Do you have a valid medical certificate?"
                    value={hasMedical}
                    checked={hasMedical}
                    onChange={(e) => setHasMedical(e.target.checked)}/>*/}
        </Form.Group>

        {hasMedical ? <>
            <SCDatePicker
                className="pr-3 pl-3"
                value={dateOfMedical}
                onChange={value => setDateOfMedical(value)}
                label="Medical - Date of expiration"
                formatStyle="large"
                locale="US"
            />
        </> : <></>}

        </> : <></>}
        </div>
        </> : <></>}

      { (currentPage === 2) ? <>
         <NavHeader prev={true}
            next={false}
            label={currentHeaderLabel}
            disabled={!validateForm()}
            handler={handleNextPreviousClick}/>
        <div >
        <Form.Group >
            { exposedBrevet !== 0 ? <>
            <Container className="pl-3 pr-3">
             {addstring.map( item => (
                <FormUpDownload
                    apiEndpoint="brevets"
                    filename={`${exposedBrevet}_${item}`}
                    additionalInfoDict={getBrevetUploadInfoDict(`${exposedBrevet}_${item}`)}
                    dlDisabled={!diverMainBrevetDict[exposedBrevet]["fileProvided"][item]}
                    dlHintText="No file uploaded"
                    //deleteCallback={() => handleSetFileProvided(`${exposedBrevet}_${item}`, false)}
                    workingCallback={(isWorking, isDeleted) => handleSetUploadWorking(`${exposedBrevet}_${item}`, isWorking, isDeleted)}
                    >
                    C-Card <>{item}</>
                </FormUpDownload>
                ))}
            </Container>
            <ColoredLine className="scbline ml-0 mr-0 float-center"/>
                </>:<></>

            }

            {specialties.filter(item => item.selected === true).map(filteredSpecialties => (
                <>
                <Container className="pl-3 pr-3">
                {addstring.map( item => (

                    <FormUpDownload
                        apiEndpoint="brevets"
                        filename={`${filteredSpecialties.key}_${item}`}
                        additionalInfoDict={getBrevetUploadInfoDict(`${filteredSpecialties.key}_${item}`)}
                        dlDisabled={!diverSpecialtyDict[filteredSpecialties.key]["fileProvided"][item]}
                        dlHintText="No file uploaded"
                        workingCallback={isWorking => handleSetUploadWorking(`${filteredSpecialties.key}_${item}`, isWorking)}
                        >
                            {getBrevetLabelFromId(filteredSpecialties.key, specialtiesDict) + " " + item}
                    </FormUpDownload>
                ))}
                </Container>
                <ColoredLine className="scbline ml-0 mr-0 pl-0 pr-0 float-center"/>
                </>

            ))}

            {hasInsurance ? <>
                <Container className="pl-3 pr-3">
                    <FormUpDownload
                        apiEndpoint="brevets"
                        filename="insurance"
                        additionalInfoDict={getBrevetUploadInfoDict("Insurance")}
                        dlDisabled={!providedInsurance}
                        dlHintText="No file uploaded"
                        workingCallback={isWorking => handleSetUploadWorking("Insurance", isWorking)}>
                        Insurance certificate
                    </FormUpDownload>
                </Container>
                {hasMedical ? <>
                    <ColoredLine className="scbline ml-0 mr-0 float-center"/>
                </>:<></>}
               </>:<></>
            }
            {hasMedical ? <>
                <Container className="pl-3 pr-3">
                    <FormUpDownload
                        apiEndpoint="brevets"
                        filename="medical"
                        additionalInfoDict={getBrevetUploadInfoDict("Medical")}
                        dlDisabled={!providedMedical}
                        dlHintText="No file uploaded"
                        workingCallback={isWorking => handleSetUploadWorking("Medical", isWorking)}
                        >
                        Medical certificate
                    </FormUpDownload>
                </Container>

                </>:<></>
            }
        </Form.Group>
        </div>
      </> : <></>}

    <Footer>
      { (currentPage === 0 || (currentPage === 1 && certified)) ? <>
        <LoaderButton
          block
          onClick={(e) => handleNextPreviousClick("next")}
          size="lg"
          value="next"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          <FiArrowRight size={22}/>
        </LoaderButton>
       </>:<>
        <LoaderButton
          block
          onClick={(e) => handleFinish()}
          size="lg"
          value="finish"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateUploadsFinished()}
        >
          Finish
        </LoaderButton>
      </>}
      </Footer>

    </LoadingOverlay>
  );
}

