import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Notes from "./containers/Notes";
import DiverInfo from "./containers/DiverInfo";
import DivecenterInfo from "./containers/DivecenterInfo";
import ShopMain from "./containers/ShopMain";
import ShopEditEvent from "./containers/ShopEditEvent";
import ShopEvents from "./containers/ShopEvents";
import ShopDivers from "./containers/ShopDivers";
import ShopShadowVisit from "./containers/ShopShadowVisit";
import DiverSingleVisit from "./containers/DiverSingleVisit";
import Questionnaires from "./containers/Questionnaires";
import DiverVisits from "./containers/DiverVisits";
import DiverEvents from "./containers/DiverEvents";
import DiverMain from "./containers/DiverMain";
import About from "./containers/About";
import FileUpload from "./containers/FileUpload";
import Dev from "./containers/TobiDebug"
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword"

export default function Routes() {
  return (
    <Switch>
        <UnauthenticatedRoute exact path="/">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/login/reset">
          <ResetPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/main">
          <DiverMain />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/profile/:visitId">
          <DiverInfo />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/visits/:visitId/:predefinedCenterId">
          <DiverSingleVisit />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/visits">
          <DiverVisits />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/questionnaire/:diverId/:visitId"> {/*diverid: generic or uuid in case of shadow-diver*/}
          <Questionnaires />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/diver/events">
          <DiverEvents />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/main">
          <ShopMain />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/events/edit/:eventIdLoad/:state">
          <ShopEditEvent />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/events">
          <ShopEvents />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/divecenter">
          <DivecenterInfo />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/divers/:divers_or_staff">
          <ShopDivers />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/shop/visit/:visitId">
          <ShopShadowVisit />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/fileupload/new">
          <FileUpload />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/about">
          <About />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/notes/:id">
          <Notes />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/dev">
          <Dev />
        </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
