import React, { useState , useEffect} from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import "./components/Header.css"
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext, DiverContext, ShopContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "./lib/errorLib";
import ErrorBoundary from "./components/ErrorBoundary";
import { API } from "aws-amplify";
import Container from "react-bootstrap/Container"
//import loadData from "./containers/LoadData"

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [diverProfile, setDiverProfile] = useState({});
  const [shopList, setShopList] = useState([]);
  const [shopVisits, setShopVisits] = useState([]);
  const [shopDivers, setShopDivers] = useState([]);
    const [shopEvents, setShopEvents] = useState([]);
  const [staff, setStaff] = useState([]);
  const [myShop, setMyShop] = useState({})
  const [expanded, setExpanded] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();


  function loadDiverInfoOnLoad(){
    function loadDiverInfo() {
      return API.get("divers", "/divers");
    }

    async function onLoadDiver() {

      try {
        const loaded_diver_dict = await loadDiverInfo();
        if ("status" in loaded_diver_dict) {
            if (loaded_diver_dict["status"] === "notfound") {
                return {};
            }
        }
        return loaded_diver_dict;
      } catch (e) {
        onError(e);
      }
    }
    return onLoadDiver();
  }

  function loadShopsOnLoad(){
    function loadShops(){
        return API.get("divecenters", "/divecenters/all", {
        });
    }
    async function onLoadShops() {
      try {
        const loaded_shops = await loadShops();
        if ("status" in loaded_shops) {
            if (loaded_shops["status"] === "notfound") {
                return {};
            }
        }
        return loaded_shops;
      } catch (e) {
        onError(e);
      }
    }
    return onLoadShops();
  }

  useEffect(() => {
      /*Set list of shop divers*/

    var tmp_isAuth;
    const today_0 = (new Date()).setHours(0,0,0);
    async function setAllDivers(loadedVisits, shop_dict){
          var visitsForDiverLoad = []
          for (let i=0; i< loadedVisits.length; i++){
                const this_item = loadedVisits[i]
                if (new Date(this_item.dateOfDeparture).setHours(23,59,59) >= today_0
                    && !(this_item.checkedOut)
                ){
                    visitsForDiverLoad.push(this_item.visitId)
                 }
          }
          var diverList = await loadAllDiversByVisitId(visitsForDiverLoad)
          diverList.forEach( this_item => {
            if (shop_dict.DCStaff.includes(this_item.userId))  {
                this_item["is_staff"] = true
             } else {
                this_item["is_staff"] = false
            }
          })
          return(diverList)
    }

    async function loadData(){
        var diver_dict = {}
        if (tmp_isAuth === true) {
            diver_dict = await loadDiverInfoOnLoad();
            if (diver_dict.hasOwnProperty("dive_center_ui_required")) {
               if (diver_dict["dive_center_ui_required"])
               {
                // here we need to include possibility to have several shops and chose from one.
                const my_divecenters = await loadMyCenters();
                if (my_divecenters.length > 0) {
                    setMyShop(my_divecenters[0])
                    try {
                        const loaded_visits = await loadVisitsInfo(my_divecenters[0]["centerId"]);
                        if ("status" in loaded_visits) {
                            if (loaded_visits["status"] === "notfound") {
                                return {};
                            }
                        }
                        // NOT NEEDED activate and deactivate visits based on dates
                        //const activatedVisits = await activateDeactivateVisits(loaded_visits, shop_dict["centerId"])
                        setShopVisits(loaded_visits);
                        const diverList = await setAllDivers(loaded_visits, my_divecenters[0]);
                        setShopDivers(diverList)
                    } catch (e) {
                        onError(e);
                    }
                }
              }
            }
            // ToDo: place someplace else
            const shop_list = await loadShopsOnLoad();
            setDiverProfile(diver_dict);
            setShopList(shop_list);
        }
    }

    async function onLoad() {
        try {
          await Auth.currentSession();
          userHasAuthenticated(true);
          tmp_isAuth = true;
        }
        catch(e) {
          if (e !== 'No current user') {
            onError(e);
          }
        }
        await loadData();
        setIsAuthenticating(false);
    }
    onLoad();

  }, []);


  function loadMyCenters() {
      return API.get("divecenters", "/divecenters", {
        });
  }
  function loadVisitsInfo(centerId) {
      return API.get("visits", `/divecenters/${centerId}/visits`);
    }

  /*Load all divers that are visiting this center*/
  async function loadAllDiversByVisitId(visitIds){
    function loadDivers(visitIds){
        return API.put("divers", "/divecenters/divers", {
            body: {"visitIds": visitIds}
        });
    }
    async function onLoadDivers(visitIds){
        try {
            const loaded_divers = await loadDivers(visitIds);
            if ("status" in loaded_divers) {
                if (loaded_divers["status"] === "notfound") {
                    return {};
                }
            }
            return loaded_divers;

        } catch (e) {
            onError(e);
        }
    }
    return onLoadDivers(visitIds);
  }


  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setDiverProfile({});
    setShopList([]);
    setShopVisits([]);
    setShopDivers([]);
    setStaff([]);
    setMyShop({})
    history.push("/login");
  }

  return (
    !isAuthenticating && (

    <div className="App container py-3">
      <Navbar sticky="top" collapseOnSelect variant="dark" bg="light" expand="md" className="scubchiheader" expanded={expanded}>
      <Container>
        <LinkContainer to="/diver/main">
          <Navbar.Brand className="font-weight-bold text-muted scubchiheader_cw">
            Scubchi
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}/>
        <Navbar.Collapse className="justify-content-end scubchiheader">
          <Nav activeKey={window.location.pathname} className="scubchiheader">
            {isAuthenticated ? (
            <>
              <LinkContainer to="/diver/visits">
                  <Nav.Link className="scubchiheader">Check-in</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/diver/events">
                  <Nav.Link className="scubchiheader">Logbook</Nav.Link>
              </LinkContainer>
              { diverProfile["dive_center_ui_required"] ? <>
                  <LinkContainer to="/shop/main">
                      <Nav.Link className="scubchiheader">Dive Center</Nav.Link>
                  </LinkContainer>
              </>:<></>}
              <LinkContainer to="/diver/profile/generic">
                  <Nav.Link className="scubchiheader">Profile</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                  <Nav.Link className="scubchiheader">About</Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={handleLogout} className="scubchiheader">Logout</Nav.Link>
            </>
            ) : (
              <>
                <LinkContainer to="/signup">
                  <Nav.Link >Signup</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      <ErrorBoundary>

        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, expanded, setExpanded }}>
          <DiverContext.Provider value={{diverProfile, setDiverProfile, shopList, setShopList}}>
              <ShopContext.Provider value={{myShop, setMyShop, shopVisits, setShopVisits, shopDivers, setShopDivers, shopEvents, setShopEvents, staff, setStaff}}>
                <Routes />
              </ShopContext.Provider>
          </DiverContext.Provider>
        </AppContext.Provider>
      </ErrorBoundary>

    </div>
    )
  );
}

export default App;
