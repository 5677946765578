import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import "./LoaderButton.css";
import "./Header.css"
import {    BsPlusLg } from "react-icons/bs"
import {RiEdit2Line, RiDeleteBin6Line} from "react-icons/ri"
import {FiMoreVertical} from "react-icons/fi"
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai"
import {MdAttachFile, MdOutlineCancel} from "react-icons/md"
import {FaArrowRight, FaArrowLeft} from "react-icons/fa"
//<BsCaretLeft/>
//import {IoMdArrowRoundForward, IoMdArrowRoundBack} from "react-icons/md"


export function NextButton({
    className= "",
    ...props
}) {
    return(
      <Button
       className="scubchiheader_cw ml-0"
       {...props}
      >
      <FaArrowRight size={22}/>
      </Button>
    );
}

export function PreviousButton({
    className= "",
    ...props
}) {
    return(
      <Button
       className="scubchiheader_cw mr-0"
       {...props}
      >
      <FaArrowLeft size={22}/>
      </Button>
    );
}

export function AddButton({
    className="",
    ...props
}) {
    return(
      <Button
       className="mb-3 add"
       {...props}
      >
      <BsPlusLg/>
      </Button>
    )
}

export function OptionsButton({
   className="",
   ...props
}) {
 return(
    <Button {...props} className="options">

      <FiMoreVertical/>
    </Button>
 )
}

export function SCDeleteButton({
   className="",
   ...props
}) {
 return(
    <Button {...props} className="squaresymbol">
      <RiDeleteBin6Line/>
    </Button>
 )
}

export function SCHeaderButton({
   className="",
   ...props
}){
 return(
    <Button {...props} className="headerbutton">
      {props.children}
    </Button>
 )
}

export function SCListButton({
   className="",
   ...props
}) {
 return(
    <Button {...props} className="listbutton">
      {props.children}
    </Button>
 )
}

export function SCListCheckButton({
   ...props
}) {
 return(
    <Button {...props}>
      {props.children}
    </Button>
 )
}

export function SCButton({
   className="",
   ...props
}) {
 return(
    <Button {...props} className="scb">
      {props.children}
    </Button>
 )
}



export function EditButton({
    className= "",
    ...props
}) {
    return(
      <Button
       className="edit"
       {...props}
      >
      <RiEdit2Line/>
      </Button>
    );
}

export function SCSquareSymbolButton({
    className="squaresymbol",
    ...props
}) {
    return(
      <Button
       className={`${className}`}
       {...props}
      >
      {props.children ? props.children : <RiEdit2Line/>}

      </Button>
    );
}




export function DLButton({
    className= "",
    ...props
}) {
    return(
      <Button
      className="squaresymbol"
       {...props}
      >
      <MdAttachFile/>
      </Button>
    );
}

export function CancelIconButton({
    className= "",
    ...props
}) {
    return(
      <Button
      className="cancelIcon ml-3"
       {...props}
      >
      <MdOutlineCancel size={30}/>
      </Button>
    );
}


export function EmailButton({
    className= "",
    email="",
    ...props
}) {
    return(
      <Button
      href={`mailto:${email}`}
      className="squaresymbol ml-3"
       {...props}
      >
      <AiOutlineMail/>
      </Button>
    );
}

export function PhoneButton({
    className= "",
    phone="",
    ...props
}) {
    return(
      <Button
          href={`tel:${phone}`}
          className={`${className}`}
       {...props}

      >
      <AiOutlinePhone/>
      </Button>
    );
}



export function ConfirmButton({
    className= "",
    ...props
}){
    return(
      <Button
       className="previous"
       {...props}
      >
        Confirm
      </Button>
    );
}


export function CancelButton({
    className= "",
    ...props
}){
    return(
      <Button
       className="cancel"
       {...props}
      >
        {props.children ? props.children : "Cancel"}
      </Button>
    );
}

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      disabled={disabled || isLoading}
      className={`LoaderButton ${className}`}
      {...props}
    >
      {isLoading && <BsArrowRepeat className="spinning" />}
      {props.children}
    </Button>
  );
}