import { PreviousButton, NextButton} from "../components/LoaderButton";
import { ColoredLine } from "../components/Lists";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./Header.css"
import "../containers/spacings.css"
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";



export default function NavHeader({prev=true, next=true, handler=null, label, ...props} ){
    return(
    <>
     <Navbar sticky="top" collapseOnSelect bg="light" expand="md" className="mb-3 pl-0 pr-0 scubchiheader_cw">
        {/*<div style={phantomStyle} />
        <div className="sticky-top spac80" style={headerStyle}>*/}
            <Container className="pl-0 pr-0">
                <Container fluid className="pl-0 pr-0">
                    <Row className="fullwidth ml-3 mr-3">
                        <Col xs={2} className="text-left ml-0 mr-0 pr-0 pl-0">
                            { prev ? <>
                                <PreviousButton onClick={(e) => handler("previous")} {...props}></PreviousButton>
                                </>:<>
                                </>
                            }

                        </Col>
                        <Col  xs={8} className="fs20 text-center pl-0 pr-0 ml-0 mr-0">
                            {label}
                        </Col>
                        <Col  xs={2} className="text-right ml-0 mr-0 pl-0 pr-0">

                            { next ? <>
                                <NextButton onClick={(e) => handler("next")} {...props}></NextButton>
                               </>:<>
                                </>
                            }

                        </Col>
                     </Row>
                </Container>
        </Container>
        <ColoredLine className="scbline"/>
       {/*</div>*/}
     </Navbar>
    </>
    )
}

export function MenueHeader(props) {
    return(
    <ButtonGroup justified className="d-flex">
        {props.list.map((item, idx) => (
          <Button
            key={idx}
            id={`{idx}`}
            //variant="outline-light"
            className="SCToggleButton"
            value={0}
            active={item.checked}
            onClick={(e) => props.changehandler(idx)}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>

    )
}

export function StaticScubchiText({...props}){
    return(
        <div className="pt-3">
            <Container fluid>
                {props.children ?
                    <Row>
                        <Col  className="fs18slim text-center pl-0 pr-0 ml-0 mr-0">
                            {props.children}
                        </Col>
                    </Row>
                :<></>}
                <Row>
                    <Col  className="fs22 text-center pl-0 pr-0 ml-0 mr-0">
                                Scubchi - The Scuba App
                    </Col>
                </Row>
                <Row>
                    <Col className="fs18slim text-center pl-3 pr-3 ml-3 mr-3">
                        www.app.scubchi.com
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col className="fs18slim  text-center pl-3 pr-3 ml-3 mr-3">
                        www.scubchi.com
                    </Col>
                </Row>
            </Container>
        </div>
    )
}