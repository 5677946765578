import React from "react";
import Switch from "react-switch";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function ToggleBasic(props){
    return(
    <Container>
        <Row>
            <Col sm={9}>
                <span>{props.label}</span>
            </Col>
            <Col sm={3}>
            <Switch
            className="float-right"
            handleDiameter={23}
            onColor={'#2562a3'}
            height={25}
            onChange={()=>props.setter(!props.checked)}
            checked={props.checked} />
            </Col>
        </Row>
     </Container>
     )
}


function ToggleBasicForList(props){
    return(
          <Row className="mb-3 pl-3 pr-3">
            <Col sm={10}>
                {props.label}
            </Col>
            <Col sm={2} className="text-right">
            <Switch
                className="float-right"
                handleDiameter={22}
                onColor={'#2562a3'}
                height={25}
                onChange={()=>props.changehandler(props.list_id, props.list, props.setter)}
                checked={props.checked} />
            </Col>
          </Row>
    )
}

export const ToggleList = ({mylist, changehandler, setter}) => {
    return(
            <div>
            <Container>
            {mylist.map((item, index) => (
                <ToggleBasicForList
                    key={item.key}
                    setter={setter}
                    list_id={index}
                    label={item.label}
                    checked={item.checked}
                    changehandler={changehandler}
                    list={mylist}
                />
             ))
            }
            </Container>
            </div>
    )
}


export default ToggleBasic;