import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import {SCButton, CancelButton} from "../components/LoaderButton";

export default PopConfirm;

function PopConfirm(props){
    return(
    <Modal
        size="md"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.text}</Modal.Body>
        <Modal.Footer>
            <Container fluid>
                <Row>
                <Col className="text-center">
                    <CancelButton
                        onClick={() => {props.handleCancel ? props.handleCancel() : props.setShow(false)}}
                    > {props.cancelText ? props.cancelText : "Cancel"}
                    </CancelButton>
                </Col>
                <Col className="text-center">
                    <SCButton
                        onClick={() => props.handleConfirm()}
                    >
                    {props.confirmText}
                    </SCButton>
                </Col>

                </Row>
            </Container>
         </Modal.Footer>
      </Modal>
    )
}

export function PopNotice(props){
    return(
    <Modal
        size="md"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.text}</Modal.Body>
        <Modal.Footer>
            <Container fluid>
                <Row>
                <Col className="text-center">
                    <SCButton
                        onClick={() => {props.handleConfirm ? props.handleConfirm() : props.setShow(false)}}
                    >{props.confirmText}</SCButton>
                </Col>
                </Row>
            </Container>
         </Modal.Footer>
      </Modal>
    )
}


export function PopConfirmText(props){
    return(
    <Modal
        size="md"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.text ? props.text : ""}
            <Form.Control
                        value={props.textInput.value}
                              type="string"
                              as="textarea"
                              rows="4"
                              placeholder={props.textInput.placeholder}
                              onChange={(e) => props.textInput.setTextInput(e.target.value)}
            />
        </Modal.Body>
        <Modal.Footer>
            <Container fluid>
                <Row>
                <Col className="text-center">
                    <CancelButton
                        onClick={() => props.setShow(false)}
                    />
                </Col>
                <Col className="text-center">
                    <SCButton
                        onClick={() => props.handleConfirm(props.text.value)}
                    > {props.confirmText ? props.confirmText : "Confirm"}
                    </SCButton>

                </Col>

                </Row>
            </Container>
         </Modal.Footer>
      </Modal>

    )
}
