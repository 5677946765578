


export function getMainBrevets() {
    /*$.getJSON("brevets.json", function(json) {
    console.log(json); // this will show the info it in firebug console
});*/
    var json = require('./brevets.json');
    return json["mainBrevets"]
}


export function getSpecialties() {
    /*$.getJSON("brevets.json", function(json) {
    console.log(json); // this will show the info it in firebug console
});*/
    var json = require('./brevets.json');
    return json["Specialties"]
}


export function swap_dict(dict) {
    const this_dict = {}
    // dangerous code!
    for (const [key, value] of Object.entries(dict)){
        this_dict[value] = key
    }
    return(this_dict)
}

export function getNewSpecialties() {
    const Specialties = getSpecialties()
    const this_specialties = []
    for (const [key, value] of Object.entries(Specialties)){
            this_specialties.push({key: value, sublabel: key})
        }
    return(this_specialties)
}


export function getBrevetsForSelect( ){
    const mainBrevets = getMainBrevets()
    const this_dict = [{label: "--", value: 0}];
    for (const [key, value] of Object.entries(mainBrevets)) {
        this_dict.push({label: key, value: value})
    }
    return this_dict
}

export function getBrevetLabelFromId(brevetId, brevetDict){
    // brevetDict usually has been loaded, and we can use this for both specialties and mainBrevets
    const swapedDict = swap_dict(brevetDict)
    if (swapedDict.hasOwnProperty(brevetId)){
        return swapedDict[brevetId]
    } else{
        return "Not set"
    }
}



