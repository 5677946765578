import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import  {SCButton} from "../components/LoaderButton";
import { useShopContext, useAppContext } from "../lib/contextLib";
import NavHeader from "../components/Header"
//import { dummydivers } from "../diverlib/dummylists"
import LoadingOverlay from 'react-loading-overlay';

export default function ShopMain(){
    const history = useHistory();
    const {setExpanded} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const {myShop
        } = useShopContext();

    async function handleRedirectButton(target){
            history.push(target)
    }


  useEffect(() => {
        setIsLoading(false)
        setExpanded(false)
   }, [setExpanded]);


    return(
     <LoadingOverlay
              active={isLoading}
              spinner
              text='Loading Scubchi Dive Center...'
              >

        <div className="ShopMain" style={{height: "100%"}}>

        <NavHeader prev={false}
            next={false}
            label={myShop["DCName"]}
            handler={handleRedirectButton}/>

        <SCButton
                  onClick={(e) => handleRedirectButton("/shop/events")}
                  size="lg"
                  block
                  variant="primary"
                  value="eventList"
                  >
                Events
        </SCButton>

        <SCButton
                  onClick={(e) => handleRedirectButton("/shop/divers/divers")}
                  size="lg"
                  block
                  variant="primary"
                  value="registeredDivers"
                   >
                Divers
        </SCButton>

        <SCButton
                  onClick={(e) => handleRedirectButton("/shop/visit/new")}
                  size="lg"
                  block
                  variant="primary"
                  value="registeredDivers"
                   >
                Check in new diver
        </SCButton>


        <SCButton
                  onClick={(e) => handleRedirectButton("/shop/divers/staff")}
                  size="lg"
                  block
                  variant="primary"
                  value="registeredDivers"
                   >
                Staff
        </SCButton>



        <SCButton
                  onClick={(e) => handleRedirectButton("/shop/divecenter")}
                  size="lg"
                  block
                  variant="primary"
                  value="eventDiverList"
                   >
                Divecenter
        </SCButton>
        </div>
    </LoadingOverlay>
    )
}