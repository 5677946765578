import React from "react";
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar";
import "../containers/spacings.css"
/*const footerStyle = {
  backgroundColor: "white",
  display: "block",
  textAlign: "center",
  padding: "20px",
  position: "fixed-bottom",
  left: "0",
  bottom: "0",
  height: "80px",
  width: "100%"
};*/

const footerStyleDummy = {
  backgroundColor: "white",
  display: "block",
  textAlign: "center",
  padding: "20px",
  position: "fixed-bottom",
  left: "0",
  bottom: "0",
  height: "2px",
  width: "10%"
};

const phantomStyle = {
  display: "block",
  padding: "20px",
  height: "80px",
  width: "100%"
};

/*export default function Footer({ children }) {
  return (
    <div>
      <div style={phantomStyle} />
      <div className="fixed-bottom" style={footerStyle}>
        <Container>
            {children}
        </Container>
      </div>
    </div>
  );
}*/
export default function Footer({spacerClassName = "spac80", ...props}){
return(
<>
    <div className={`${spacerClassName}`}/>
    <Navbar fixed="bottom" collapseOnSelect bg="light" expand="md" style={{display: "block"}} >
        <Container  className="pl-0 pr-0">
            <Container fluid className="pl-0 pr-0">
                {props.children}
            </Container>
        </Container>
    </Navbar>
</>)

}


export function DummyFooter({ children }) {
  return (
    <div>
      <div style={phantomStyle} />
      <div className="fixed-bottom" style={footerStyleDummy}>{children}</div>
    </div>
  );
}
/*export default function Footer({ children }) {
  return (
    <Navbar fixed="bottom">
        <Navbar.Toggle />

        <Navbar.Collapse className="justify-content-end">
          <Nav>
          {children}
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}*/